import { Box } from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import { omit, pick } from "lodash";
import React, { useMemo } from "react";
import { FormContext } from "./context";
import FormItem from "./FormItem";

const formProps = [
  // 'layout',
];

export function useForm(params) {
  const formik = useFormik(omit(params, formProps));
  return {
    ...formik,
    ...pick(params, formProps),
  };
}

function Form({ form, children, layout, labelStyle, fieldStyle, labelSx, fieldSx }) {
  const v = useMemo(
    () => ({
      layout,
      labelStyle,
      fieldStyle,
      labelSx,
      fieldSx,
    }),
    [fieldStyle, fieldSx, labelStyle, labelSx, layout]
  );
  return (
    <FormContext.Provider value={v}>
      <FormikProvider value={form}>
        <Box>{children}</Box>
      </FormikProvider>
    </FormContext.Provider>
  );
}

Form.Item = FormItem;

export { FormItem, Form };
export default Form;
