import { useForm, Form, FormItem } from "components/Form";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { useEffect, useState, createRef } from "react";
import { Message } from "utils/message";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useFlatInject, useInject } from "store";
import Loading from "components/Loading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getToken } from "utils/auth";
import { useMaterialUIController } from "utils/hooks";

function MainResendMail() {
  const [user] = useInject("user");
  const [{ user: info }] = useFlatInject("user");
  const navigate = useNavigate();
  const userLocal = localStorage.getItem("_data/user");
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:480px)");
  const token = getToken();

  const [windowResize, setWindowResize] = useState(window.innerWidth);
  useEffect(() => {
    function handleMiniSidenav() {
      setWindowResize(window.innerWidth);
    }
    window.addEventListener("resize", handleMiniSidenav);
    // A function that sets the mini state of the sidenav.
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [location]);

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  const handleResendLink = async () => {
    try {
      const res = await user?.actions?.resendLink();
      localStorage.setItem("request-id", res.requestId);
      Message.success("Resend link successfully. Please check your email");
    } catch (error) {
      localStorage.clear();
      Message.error(`${error?.message}`);
      setTimeout(() => {
        navigate("/sign-in");
      }, 1500);
    }
  };

  const clickGoToLogin = () => {
    localStorage.clear();
  };

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pb={8}
      sx={{
        ...(windowResize < 1600 &&
          windowResize > 1200 &&
          token &&
          !miniSidenav && { marginLeft: "250px" }),
      }}
    >
      <MDBox
        p={5}
        pb={10}
        sx={{
          background: "#fff",
          gap: "40px",
          borderRadius: "20px",
          ...(isMobile && { height: "100%" }),
        }}
        display="flex"
        flexDirection="column"
      >
        <MDTypography sx={{ fontSize: "24px", fontWeight: "500", color: "#282828" }}>
          Verify your email
        </MDTypography>
        <MDTypography
          fontWeight="normal"
          sx={{ fontSize: "14px", color: "#282828", fontWeight: "400" }}
        >
          Verify your email {info?.email ?? userLocal?.email} by clicking on the link we’ve sent to
          your inbox. This will allow you to proceed with the registration.
        </MDTypography>
        <Loading />
        <MDBox display="flex" flexDirection="column" gap="15px">
          <MDTypography
            fontWeight="normal"
            sx={{ fontSize: "14px", color: "#282828", fontWeight: "400" }}
          >
            Haven’t received verification email? Click “Resend”.
          </MDTypography>
          <MDButton
            variant="contained"
            color="green"
            size="large"
            sx={{ width: "150px", marginTop: "10px", fontSize: "14px" }}
            onClick={handleResendLink}
          >
            Resend
          </MDButton>
          <Link to="/sign-in" style={{ display: "flex" }} onClick={clickGoToLogin}>
            <MDTypography
              component="span"
              fontWeight="medium"
              sx={{
                textDecoration: "underline !important",
                fontSize: "14px",
                color: "#0E361C",
              }}
            >
              Click here to go back to Login
            </MDTypography>
          </Link>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default MainResendMail;
