/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Admin PRO React base styles
import { formLabelClasses } from "@mui/material";
import colors from "assets/theme/base/colors";

const { text } = colors;

export default {
  styleOverrides: {
    root: {
      color: text.main,
      lineHeight: "1.1 !important",
      [`&,.${formLabelClasses.focused}`]: {
        color: "#7b809a!important",
      },
    },
    filled: {
      transform: "translate(0, -9px) scale(0.75)!important",
    },
  },
};
