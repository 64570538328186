import { forwardRef } from "react";
import PropTypes from "prop-types";
import ViewPassword from "assets/images/small-logos/view-password.svg";
import MDBox from "components/MDBox";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MDInputSignInRoot from "./MDInputSignInRoot";

const MDInputSignIn = forwardRef(
  ({ error, success, disabled, showPassword, onShowPassword, eyeDisplay, ...rest }, ref) => (
    <MDBox position="relative">
      <MDInputSignInRoot
        InputProps={{ disableUnderline: true }}
        {...rest}
        ref={ref}
        ownerState={{ error, success, disabled }}
      />
      {showPassword && (
        <MDBox
          onClick={onShowPassword}
          sx={{ cursor: "pointer", position: "absolute", top: "16px", right: "20px" }}
        >
          {eyeDisplay ? (
            <VisibilityOutlinedIcon width="24px" height="24px" color="disabled" />
          ) : (
            <VisibilityOffOutlinedIcon width="24px" height="24px" color="disabled" />
          )}
        </MDBox>
      )}
    </MDBox>
  )
);

// Setting default values for the props of MDInput
MDInputSignIn.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  showPassword: false,
  onShowPassword: () => {},
};

// Typechecking props for the MDInput
MDInputSignIn.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  showPassword: PropTypes.bool,
  onShowPassword: PropTypes.func,
};

export default MDInputSignIn;
