import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

function DescriptionCompSignUp({ isMobile, handleClickLogin }) {
  return (
    <MDBox display="flex" gap="6px" flexDirection="row" alignItems="center">
      <MDTypography
        sx={{
          color: isMobile ? "#fff" : "#757575",
          fontWeight: "regular",
          fontSize: "14px",
          margin: "0px",
        }}
      >
        Already have an account?{" "}
      </MDTypography>

      {/* <Link to="/sign-in" onClick={() => handleClickLogin?.()}>
        <MDTypography
          component="span"
          fontWeight="medium"
          sx={{
            textDecoration: "underline !important",
            fontSize: "14px",
            color: isMobile ? "#fff" : "#0E361C",
          }}
        >
          Login
        </MDTypography>
        
      </Link> */}
      <MDBox
        sx={{ cursor: "pointer" }}
        onClick={() => handleClickLogin?.()}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <MDTypography
          component="span"
          fontWeight="medium"
          sx={{
            textDecoration: "underline !important",
            fontSize: "14px",
            color: isMobile ? "#fff" : "#0E361C",
          }}
        >
          Login
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default DescriptionCompSignUp;
