import React from "react";
import MDTypography from "components/MDTypography";

function TitleCompSignUp({ isMobile }) {
  return (
    <MDTypography
      fontSize="32px"
      fontWeight="regular"
      sx={{
        color: isMobile ? "#FFF" : "#282828",
      }}
    >
      Get started
    </MDTypography>
  );
}

export default TitleCompSignUp;
