import { useForm, Form, FormItem } from "components/Form";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// import { Modal } from "utils/modal";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { useEffect, useState, createRef } from "react";
import { Message } from "utils/message";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Grid, useMediaQuery, Stack } from "@mui/material";
import { useFlatInject, useInject } from "store";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Loading from "components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "utils/auth";
import { useMaterialUIController } from "utils/hooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "800px",
  minWidth: "500px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

function MainResetPassword() {
  const [email, setEmail] = useState("");
  const [open, setOpenModal] = useState(false);
  const [user] = useFlatInject("user");
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:480px)");
  const token = getToken();
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      email,
    },
    onSubmit: async (formData, { validateForm, resetForm }) => {
      try {
        await validateForm();
        const res = await user.resetPassword(formData);
        if (res.code !== 200) {
          Message.error(res?.msg ?? "Error when send mail!");
          return;
        }
        setOpenModal(true);
      } catch (error) {
        // Message.error(error?.message ?? "Error when send mail!");
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email is required").email("Please enter a valid email address"),
    }),
  });

  const [windowResize, setWindowResize] = useState(window.innerWidth);
  useEffect(() => {
    function handleMiniSidenav() {
      setWindowResize(window.innerWidth);
    }
    window.addEventListener("resize", handleMiniSidenav);
    // A function that sets the mini state of the sidenav.
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [location]);

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  const redirectSignin = () => {
    navigate("/sign-in");
  };

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pb={8}
      sx={{
        ...(windowResize < 1600 &&
          windowResize > 1200 &&
          token &&
          !miniSidenav && { marginLeft: "250px" }),
      }}
    >
      <MDBox
        p={5}
        pb={10}
        sx={{
          background: "#fff",
          gap: "40px",
          borderRadius: "20px",
          minWidth: "60%",
          ...(isMobile && { height: "100%" }),
        }}
        display="flex"
        flexDirection="column"
      >
        <MDTypography sx={{ fontSize: "24px", fontWeight: "500", color: "#282828" }}>
          Reset your password
        </MDTypography>
        <MDTypography
          fontWeight="normal"
          sx={{ fontSize: "14px", color: "#282828", fontWeight: "400" }}
        >
          Please enter your email address to reset your password.
          {/* <MDTypography
            fontWeight="normal"
            sx={{ fontSize: "14px", color: "#282828", fontWeight: "400" }}
          >
            If you have any questions, please complete the form below and a member of our team will
            get back to you soon.
          </MDTypography> */}
        </MDTypography>
        <MDBox form={form} component={Form}>
          <MDBox display="flex" flexDirection="column" sx={{ gap: "40px" }}>
            <MDBox
              display="flex"
              flexDirection="row"
              sx={{
                gap: "20px",
                width: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={isMobile ? 10 : 6}>
                  <FormItem
                    as={MDInput}
                    type="email"
                    name="email"
                    variant="standard"
                    fullWidth
                    placeholder="Enter email address"
                    InputLabelProps={{ shrink: true }}
                    label="Email"
                    labelStyle={{ display: "none" }}
                    fieldSx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          color: "#282828 !important",
                        },
                        input: {
                          "&::placeholder": {
                            color: "#757575 !important",
                          },
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "#282828 !important",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox display="flex" flexDirection="column">
              {form?.errors?.token && !!Number(form.submitCount) ? (
                <Box color="error.main" fontSize={pxToRem(14)} component="span">
                  Please enter all field
                </Box>
              ) : null}
              <MDButton
                variant="contained"
                color="green"
                sx={{ width: "200px", marginTop: "10px", fontSize: "14px", padding: "15px 0" }}
                loading={form.isSubmitting}
                onClick={form.submitForm}
              >
                Reset password
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {}}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              minWidth: isMobile ? "400px" : "500px",
              width: isMobile ? "80%" : "100%",
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="flex-start">
              <Stack sx={{ width: "100%" }}>
                <MDTypography
                  id="transition-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ fontWeight: "500" }}
                >
                  Your password has been reset
                </MDTypography>
                <MDBox mt={2}>
                  <MDTypography id="transition-modal-description">
                    We&apos;ve sent you a new password.
                  </MDTypography>
                  <MDTypography id="transition-modal-description">
                    Check your email and follow the instructions to login.
                  </MDTypography>
                </MDBox>
                <Stack mt={10} spacing={2} direction="row" justifyContent="flex-start">
                  <MDButton
                    color="green"
                    onClick={() => {
                      redirectSignin();
                      setOpenModal(false);
                    }}
                    sx={{ fontSize: "14px" }}
                  >
                    Ok
                  </MDButton>
                  <div />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </MDBox>
  );
}

export default MainResetPassword;
