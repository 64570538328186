import { isEmpty, isObject } from "lodash";

export const isBrowser = typeof window !== "undefined";

/**
 * 遍历对象或者数组
 * @param json
 * @param handler
 */
export function traverseObject(json, handler) {
  const loopFn = ({ target, parent, key, path = [] }) => {
    handler(target, { key, path, parent });
    if (Array.isArray(target)) {
      target.forEach((i, index) =>
        loopFn({
          target: i,
          parent: target,
          key: index,
          path: [...path, index],
        })
      );
    } else if (isObject(target) && !isEmpty(target)) {
      Object.keys(target).forEach((k) => {
        loopFn({
          target: target[k],
          parent: target,
          key: k,
          path: [...path, k],
        });
      });
    }
  };
  loopFn({
    target: json,
    parent: undefined,
    key: undefined,
    path: [],
  });
}

export const sleep = (time = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });
