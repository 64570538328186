import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";

const DealStrBox = styled(MDBox)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #fff;
  padding: 32px;
  flex: 1;
`;

export default DealStrBox;
