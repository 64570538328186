import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MDAccordion from "components/MDAccordion";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";

function BodySettingMobileLayout({ data }) {
  const [isExpanded, setExpanded] = useState("deal-review");
  const handleChange = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : false);
  };

  return (
    <MDBox
      flexDirection="column"
      display="flex"
      mx="-12px"
      mt="20px"
      sx={{
        gap: "20px",
        minHeight: "calc(100vh - 250px)",
      }}
    >
      {data?.map((item, index) => (
        <MDAccordion
          onChange={handleChange(item?.id)}
          defaultExpanded={isExpanded === item?.id}
          TransitionProps={{ timeout: { appear: 1, enter: 4, exit: 1 }, unmountOnExit: true }}
          square="false"
          key={`${item?.id}`}
          eventKey={`${item?.id}`}
          sx={{ margin: "0px !important" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ height: "24px", width: "24px", color: "#282828" }} />
            }
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            // className="no-print"
          >
            <MDTypography fontWeight="normal" fontSize={20} sx={{ color: "#282828" }}>
              {item?.title}
            </MDTypography>
          </AccordionSummary>
          <AccordionDetails>{item?.component}</AccordionDetails>
        </MDAccordion>
      ))}
    </MDBox>
  );
}

export default BodySettingMobileLayout;
