import { Box, useMediaQuery } from "@mui/material";
import { useField, ErrorMessage, useFormikContext } from "formik";
import { useCallback, useContext, useEffect, useId, useMemo, useRef, useState } from "react";
import MDTypography from "components/MDTypography";
import { FormItemBox } from "./style";
import { FormContext } from "../context";
import pxToRem from "../../../assets/theme/functions/pxToRem";

const dispalyMap = {
  vertical: "block",
  horizontal: "flex",
  inline: "inline-block",
};
const fieldDisplayMap = {
  vertical: "block",
  horizontal: "inline-block",
  inline: "inline-block",
};
export default function FormItem({
  label,
  as: Comp,
  children,
  name,
  validate,
  labelStyle,
  fieldStyle,
  labelSx,
  fieldSx,
  formItemSx,
  required = false,
  ...restProps
}) {
  // const isMobile = useMediaQuery("(max-width:480px)");
  const [field, helper] = useField({ name, validate });
  const { setFieldValue } = useFormikContext();
  const errorMsg = helper.touched ? helper.error : "";
  const fieldRef = useRef();
  const [fieldHeight, setFieldHeight] = useState(40);
  useEffect(() => {
    if (fieldRef.current) {
      const { height } = fieldRef.current.getBoundingClientRect();
      if (height !== fieldHeight) {
        setFieldHeight(height);
      }
    }
  }, [fieldHeight]);

  const id = useId();
  const ctx = useContext(FormContext);
  const { layout = "horizontal" } = ctx;

  const onChange = useCallback(
    (event) => {
      console.log("event", event?.target?.value);
      setFieldValue(name, event?.target?.value ?? event);
      // restProps?.onChange?.(event);
    },
    [restProps?.onChange, setFieldValue]
  );

  const compUI = useMemo(
    () => (
      <Comp
        {...restProps}
        onChange={onChange}
        {...field}
        label={label}
        id={id}
        error={!!errorMsg}
        ref={fieldRef}
        required={required}
      >
        {children}
      </Comp>
    ),
    [restProps, field, label, id, errorMsg, fieldRef, onChange]
  );

  const finalFormItemSx = useMemo(
    () => ({
      ...(ctx.formItemSx || {}),
      ...(formItemSx || {}),
    }),
    [ctx.formItemSx, formItemSx]
  );
  return (
    <FormItemBox display={dispalyMap[layout]} sx={finalFormItemSx}>
      {label && (
        <Box
          className="label-box"
          component="label"
          htmlFor={id}
          display={fieldDisplayMap[layout]}
          style={{
            ...ctx.labelStyle,
            ...labelStyle,
          }}
          sx={{
            ...(labelSx || ctx.labelSx),
          }}
        >
          {required && (
            <Box color="error.main" component="span">
              *{" "}
            </Box>
          )}
          {typeof label === "string" ? (
            <MDTypography sx={{ color: "#282828 !important", fontSize: "12px" }}>
              {label}
            </MDTypography>
          ) : (
            label
          )}
          :
        </Box>
      )}
      <Box
        display={fieldDisplayMap[layout]}
        sx={fieldSx || ctx.fieldSx}
        style={{
          verticalAlign: "top",
          ...ctx.fieldStyle,
          ...fieldStyle,
        }}
      >
        {compUI}
        {errorMsg && (
          <Box color="error.main" fontSize={pxToRem(14)} component="span">
            <ErrorMessage name={name} />
          </Box>
        )}
      </Box>
    </FormItemBox>
  );
}
