/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import { useFlatInject } from "store";

function BasicInfo({ isMobile }) {
  const [{ user }] = useFlatInject("user");
  return (
    <Card id="basic-info" sx={{ overflow: "visible", boxShadow: "none" }}>
      {!isMobile ? (
        <MDBox p={3}>
          <MDTypography fontWeight="normal" fontSize={24} sx={{ color: "#282828" }}>
            Basic Info
          </MDTypography>
        </MDBox>
      ) : null}
      <MDBox component="form" pb={!isMobile ? 3 : "20px"} px={!isMobile ? 3 : "8px"}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Name"
              value={user.name}
              placeholder="Alec"
              inputStyle={{ color: "#282828", fontSize: "16px", fontWeight: "400" }}
              labelStyle={{
                color: "#282828 !important",
                fontSize: "12px !important",
                fontWeight: "400",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              placeholder="example@email.com"
              value={user.email}
              inputProps={{ type: "email" }}
              inputStyle={{ color: "#757575", fontSize: "16px", fontWeight: "400" }}
              labelStyle={{
                color: "#282828 !important",
                fontSize: "12px !important",
                fontWeight: "400",
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
