import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "examples/Footer";
import Header from "pages/terms-page/components/Header";
import MDBox from "components/MDBox";
import MainResendMail from "./components/Main";

function ResendEmail() {
  return (
    <PageLayout background="#F5F7FA">
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <MDBox ml="-28px">
          <Header isNoRedirect />
        </MDBox>
        <MDBox display="flex" justifyContent="center" flexDirection="row" sx={{ width: "100%" }}>
          <MainResendMail />
        </MDBox>
        <Footer />
      </MDBox>
    </PageLayout>
  );
}

export default ResendEmail;
