/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
} from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components

// Data
import BrandingLogoYellow from "assets/images/small-logos/branding-logo-yellow.svg";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDRadio from "components/MDRadio";
import { MoneyInput } from "components/MoneyInput";
import { useEffect, useId } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFlatInject } from "store";
import { submitAutoSave } from "utils/common";
import { useAutosave } from "utils/hooks";
import { Message } from "utils/message";
import * as Yup from "yup";
import { Form, FormItem, useForm } from "../../../../../components/Form";

export function SelectWithLabel({ ...props }) {
  const id = useId();
  return (
    <FormControl fullWidth>
      <InputLabel id={id}>{props.label}</InputLabel>
      <Select labelId={id} label="Age" {...props} />
    </FormControl>
  );
}

function GoingConcernTrigger({ isMobile, ...props }) {
  const id = useId();
  return (
    <MDBox mt={0.5}>
      <FormControl
        sx={{ ...(isMobile && { gap: "7px", marginTop: "-13px", marginBottom: "0px" }) }}
      >
        <FormLabel id={id} style={{ fontSize: 12 }}>
          Going Concern?
        </FormLabel>
        {!isMobile && <MDBox py={0.8} />}
        <RadioGroup
          row
          {...props}
          sx={{
            ...(isMobile
              ? {
                  display: "flex",
                  flexWrap: "nowrap",
                }
              : {}),
          }}
        >
          <FormControlLabel
            value="Yes"
            sx={{ ...(isMobile && { display: "flex" }) }}
            control={<MDRadio />}
            label={<MDTypography variant="subtitle2">Yes</MDTypography>}
          />
          <FormControlLabel
            value="No"
            sx={{ ...(isMobile && { display: "flex" }) }}
            control={<MDRadio />}
            label={<MDTypography variant="subtitle2">No</MDTypography>}
          />
        </RadioGroup>
      </FormControl>
    </MDBox>
  );
}

function DealOverview({ setBusinessName, setTextAutoSave, isMobile }) {
  const [{ dealReview, saveDealReview, nextStep, setCardOpen }] =
    useFlatInject("dealPlanOperation");
  const location = useLocation();

  const form = useForm({
    initialValues: dealReview,
    validationSchema: Yup.object().shape({
      businessName: Yup.string().trim().required("Business name is required"),
    }),
    onSubmit: async (formData) => {
      try {
        console.log("formData", formData);
        await saveDealReview(formData);
        window.location.href = `${location.pathname + location.search}#deal-analysis`;
        Message.success("Changes saved");
        // setTimeout(() => setCardOpen("deal-analysis"), 2000);
      } catch (error) {
        Message.error(error.message);
      }
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(form);
    form.setValues(dealReview);
  }, [dealReview]);

  useAutosave(async () => {
    if (form.dirty && form.values.businessName) {
      submitAutoSave(() => saveDealReview(form.values), setTextAutoSave);
    }
  });

  useEffect(() => {
    const avgMonthlyProfit = form.values.avgMonthlyTurnOver - form.values.avgMonthlyExpenses;
    form.setFieldValue("AverageMonthlyProfit", avgMonthlyProfit);
    // if (
    //   form.values.avgMonthlyTurnOver === form.initialValues.avgMonthlyTurnOver &&
    //   form.values.avgMonthlyExpenses === form.initialValues.avgMonthlyExpenses
    // ) {
    //   form.setFieldValue("AverageMonthlyProfit", form.initialValues.AverageMonthlyProfit);
    // }
  }, [form.values.avgMonthlyTurnOver, form.values.avgMonthlyExpenses]);

  useEffect(() => {
    setBusinessName(form.values.businessName);
  }, [form.values]);

  useEffect(() => {
    const yearsInBusinessRemoveZeroLeading = parseInt(form.values.yearsInBusiness, 10);
    form.setFieldValue(
      "yearsInBusiness",
      yearsInBusinessRemoveZeroLeading ? yearsInBusinessRemoveZeroLeading.toString() : ""
    );

    const numberOfTeamRemoveZeroLeading = parseInt((form.values.numberOfTeam ?? 0).toString(), 10);
    form.setFieldValue(
      "numberOfTeam",
      numberOfTeamRemoveZeroLeading > 0 ? numberOfTeamRemoveZeroLeading : ""
    );
  }, [form.values.yearsInBusiness, form.values.numberOfTeam]);

  return (
    <Card id="deal-review" sx={{ overflow: "visible", boxShadow: isMobile ? "none" : null }}>
      <MDBox
        id="logo"
        component="img"
        src={BrandingLogoYellow}
        sx={{ width: "384px", height: "100px", marginLeft: "24px", display: "none" }}
      />
      {!isMobile && (
        <MDBox p={3}>
          <MDTypography variant="h4">Deal Overview</MDTypography>
        </MDBox>
      )}
      <MDBox component="form" pb={3} px={isMobile ? 0 : 3}>
        <Form form={form} fieldStyle={{ flex: 1 }} labelSx={{ display: "none" }}>
          <MDBox pb={4}>
            <MDTypography variant="body2">* Required information</MDTypography>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                multiline
                type="text"
                name="businessName"
                variant="standard"
                fullWidth
                label="Business name"
                placeholder="Enter Business name"
                required
              />
            </Grid>
            <MDBox px={3} py={3}>
              <MDTypography variant="h6">Pre meet</MDTypography>
            </MDBox>
            <Grid container px={3} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid item xs={isMobile ? 12 : 6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="productAndServiceOverview"
                  variant="standard"
                  fullWidth
                  multiline
                  label="Product / service overview	"
                  placeholder="Enter Product / service overview"
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="industryInfo"
                  variant="standard"
                  fullWidth
                  multiline
                  label="Industry info"
                  placeholder="Enter Industry info"
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <FormItem
                  as={SelectWithLabel}
                  type="date"
                  name="timing"
                  variant="standard"
                  fullWidth
                  label="Timing"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter Timing"
                >
                  <MenuItem value="Not Good">Not Good</MenuItem>
                  <MenuItem value="Good">Good</MenuItem>
                  <MenuItem value="Great">Great</MenuItem>
                </FormItem>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="industryMultiplie"
                  variant="standard"
                  fullWidth
                  label="Industry multiple"
                  placeholder="Enter Industry multiple"
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="connectionInIndustry"
                  variant="standard"
                  fullWidth
                  label="Do you have connections in the industry? Who are they?"
                  placeholder="Do you have connections in the industry? Who are they?"
                  multiline
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <FormItem
                  as={MDInput}
                  type="text"
                  name="impactToEmpire"
                  variant="standard"
                  fullWidth
                  label="Impact to Empire	"
                  placeholder="Enter Impact to Empire"
                  multiline
                />
              </Grid>
            </Grid>
            <MDBox px={3} pt={3}>
              <MDTypography variant="h6">First meet criticals</MDTypography>
            </MDBox>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                multiline
                type="text"
                name="motivationToSell"
                variant="standard"
                fullWidth
                label="Motivation to sell"
                placeholder="Enter Motivation to sell"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                multiline
                type="text"
                name="theirPersonalStory"
                variant="standard"
                fullWidth
                label="Their personal story"
                placeholder="Enter Their personal story"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                multiline
                type="text"
                name="theBusinessStory"
                variant="standard"
                fullWidth
                label="The business story	"
                placeholder="Enter The business story"
              />
            </Grid>
            <Grid item xs={isMobile ? 6 : 4}>
              <FormItem
                as={MDInput}
                type="text"
                name="yearsInBusiness"
                variant="standard"
                fullWidth
                label="Years in business"
                placeholder="0"
              />
            </Grid>
            <Grid item xs={isMobile ? 6 : 4}>
              <FormItem
                as={SelectWithLabel}
                type="text"
                name="ownerIsA"
                variant="standard"
                fullWidth
                label="Owner is a"
                placeholder="select option"
              >
                <MenuItem value="Job">1 - Job</MenuItem>
                <MenuItem value="Business">2 - Business</MenuItem>
                <MenuItem value="Empire">3 - Empire</MenuItem>
              </FormItem>
            </Grid>
            <Grid item xs={isMobile ? 6 : 4}>
              <FormItem
                as={MDInput}
                type="number"
                name="numberOfTeam"
                variant="standard"
                fullWidth
                label="Number of team"
                placeholder="0"
                sx={{
                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={isMobile ? 6 : 12} display="flex">
              <FormItem
                as={GoingConcernTrigger}
                isMobile={isMobile}
                type="text"
                name="goingConcernTrigger"
                variant="standard"
                fullWidth
                // label={<MDTypography sx={{ color: "#282828 !important", fontSize: "12px" }}>
                //   Going Concern Trigger
                // </MDTypography>}
                // label="Going Concern Trigger"
              />
              {!isMobile && (
                <FormItem
                  as={MDInput}
                  multiline
                  type="text"
                  name="goingConcern"
                  variant="standard"
                  fullWidth
                  label="What's great about the business?"
                  placeholder="Enter What's great about the business"
                  formItemSx={{ flex: 1, marginTop: 2 }}
                />
              )}
            </Grid>
            {isMobile && (
              <Grid item xs={12}>
                <FormItem
                  as={MDInput}
                  multiline
                  type="text"
                  name="goingConcern"
                  variant="standard"
                  fullWidth
                  label="What's great about the business?"
                  placeholder="Enter What's great about the business"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                multiline
                type="text"
                name="businessChallenge"
                variant="standard"
                fullWidth
                label="What's challenging about the business"
                placeholder="Enter What's challenging about the business"
              />
            </Grid>
            <Grid item xs={isMobile ? 6 : 3}>
              <FormItem
                as={MoneyInput}
                type="number"
                name="avgMonthlyTurnOver"
                variant="standard"
                fullWidth
                label="Average monthly turnover"
              />
            </Grid>

            <Grid item xs={isMobile ? 6 : 3}>
              <FormItem
                as={MoneyInput}
                type="number"
                name="avgMonthlyExpenses"
                variant="standard"
                fullWidth
                label="Average monthly expenses"
              />
            </Grid>
            <Grid item xs={isMobile ? 6 : 3}>
              <FormItem
                as={MoneyInput}
                type="number"
                name="AverageMonthlyProfit"
                variant="standard"
                fullWidth
                label="Average monthly profit"
              />
            </Grid>

            <Grid item xs={isMobile ? 6 : 3}>
              <FormItem
                as={MDInput}
                type="text"
                name="timeFramesSell"
                variant="standard"
                fullWidth
                label="Timeframe to sell"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                multiline
                type="text"
                name="anyOtherLeveragePoints"
                variant="standard"
                fullWidth
                label="Any other leverage points?"
                placeholder="Enter Any other leverage points"
                id="text-area-input"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem
                as={MDInput}
                multiline
                type="text"
                name="dealReviewNotes"
                variant="standard"
                label="Other deal notes?"
                fullWidth
                placeholder="Enter Other deal notes"
              />
            </Grid>

            <Grid item xs={12} textAlign="right" display="flex" justifyContent="end">
              <MDButton
                color="green"
                variant="outlined"
                onClick={() => navigate("/deal-planner", { replace: true })}
                sx={{
                  mr: 1,
                  fontSize: "14px",
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                color="green"
                loading={form.isSubmitting}
                disabled={form.isSubmitting}
                onClick={form.submitForm}
                sx={{
                  border: "1px solid #004225",
                  fontSize: "14px",
                }}
              >
                Save and next
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      </MDBox>
    </Card>
  );
}

export default DealOverview;
