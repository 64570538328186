import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CloseMenuIcon from "assets/images/small-logos/white-close.svg";

function HeaderSideNavMobile({ title, closeSidenav }) {
  return (
    <MDBox
      width="100%"
      mt={8}
      mb={4}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between !important",
      }}
      // className="no-print"
    >
      <MDTypography color="white" fontSize="24px">
        {title ?? "Deal Planner"}
      </MDTypography>
      <MDBox
        onClick={closeSidenav}
        component="img"
        src={CloseMenuIcon}
        sx={{
          cursor: "pointer",
        }}
      />
    </MDBox>
  );
}

export default HeaderSideNavMobile;
