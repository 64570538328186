import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "examples/Footer";
import Header from "pages/terms-page/components/Header";
import MDBox from "components/MDBox";
import MainResetPassword from "./components/Main";

function ResetPassword() {
  return (
    <PageLayout background="#F5F7FA">
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        // alignItems="center"
        sx={{ height: "100%" }}
      >
        <MDBox ml="-28px">
          <Header isNoRedirect />
        </MDBox>
        <MainResetPassword />
        <Footer />
      </MDBox>
    </PageLayout>
  );
}

export default ResetPassword;
