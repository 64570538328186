/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import {
  gapFundingStrategies,
  postCloseStrategies,
  preCloseStrategies,
} from "constants/strategies";
import Footer from "examples/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDAccordion from "components/MDAccordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DealStrategiesItem from "./components/DealStrategiesItem";
import DealStrBox from "./components/DealStrategiesBox";

function DealReview() {
  const isMobile = useMediaQuery("(max-width:480px)");
  const compareFn = (a, b) => {
    const nameA = a.value.toUpperCase();
    const nameB = b.value.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={isMobile ? 3 : 10} mb={2}>
        {isMobile ? (
          <MDBox
            display="flex"
            flexDirection="column"
            mx="-12px"
            sx={{ gap: "20px", minHeight: "calc(100vh - 250px)" }}
          >
            <MDAccordion
              TransitionProps={{ timeout: { appear: 4, enter: 4, exit: 4 } }}
              square="false"
              sx={{ margin: "0px !important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon style={{ height: "24px", width: "24px", color: "#282828" }} />
                }
                aria-controls={`panel${1}bh-content`}
                id={`panel${1}bh-header`}
              >
                <MDTypography fontWeight="normal" fontSize={20} sx={{ color: "#282828" }}>
                  Pre-close strategies
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                {" "}
                <MDBox>
                  {preCloseStrategies.sort(compareFn).map((i) => (
                    <DealStrategiesItem key={i.value} {...i} fontSize="14px" />
                  ))}
                </MDBox>
              </AccordionDetails>
            </MDAccordion>
            <MDAccordion
              TransitionProps={{ timeout: { appear: 4, enter: 4, exit: 4 } }}
              square="false"
              sx={{ margin: "0px !important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon style={{ height: "24px", width: "24px", color: "#282828" }} />
                }
                aria-controls={`panel${2}bh-content`}
                id={`panel${2}bh-header`}
              >
                <MDTypography fontWeight="normal" fontSize={20} sx={{ color: "#282828" }}>
                  Post-close strategies
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                {" "}
                <MDBox>
                  {postCloseStrategies.sort(compareFn).map((i) => (
                    <DealStrategiesItem key={i.value} {...i} fontSize="14px" />
                  ))}
                </MDBox>
              </AccordionDetails>
            </MDAccordion>
            <MDAccordion
              TransitionProps={{ timeout: { appear: 4, enter: 4, exit: 4 } }}
              square="false"
              sx={{ margin: "0px !important" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon style={{ height: "24px", width: "24px", color: "#282828" }} />
                }
                aria-controls={`panel${3}bh-content`}
                id={`panel${3}bh-header`}
              >
                <MDTypography fontWeight="normal" fontSize={20} sx={{ color: "#282828" }}>
                  Gap funding strategies
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                {" "}
                <MDBox>
                  {gapFundingStrategies.sort(compareFn).map((i) => (
                    <DealStrategiesItem key={i.value} {...i} fontSize="14px" />
                  ))}
                </MDBox>
              </AccordionDetails>
            </MDAccordion>
          </MDBox>
        ) : (
          <MDBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{ background: "none", gap: "25px" }}
          >
            <DealStrBox borderRadius="xl">
              <MDTypography fontSize="24px" fontWeight="normal" sx={{ color: "#282828" }}>
                Pre-close strategies
              </MDTypography>
              <MDBox>
                {preCloseStrategies.sort(compareFn).map((i) => (
                  <DealStrategiesItem key={i.value} {...i} />
                ))}
              </MDBox>
            </DealStrBox>
            <DealStrBox borderRadius="xl">
              <MDTypography fontSize="24px" fontWeight="normal" sx={{ color: "#282828" }}>
                Post-close strategies
              </MDTypography>
              <MDBox>
                {postCloseStrategies.sort(compareFn).map((i) => (
                  <DealStrategiesItem key={i.value} {...i} />
                ))}
              </MDBox>
            </DealStrBox>
            <DealStrBox borderRadius="xl">
              <MDTypography fontSize="24px" fontWeight="normal" sx={{ color: "#282828" }}>
                Gap funding strategies
              </MDTypography>
              <MDBox>
                {gapFundingStrategies.sort(compareFn).map((i) => (
                  <DealStrategiesItem key={i.value} {...i} />
                ))}
              </MDBox>
            </DealStrBox>
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DealReview;
