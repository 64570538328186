import { compareFn } from "utils/common";

const gapFundingValues = [
  "F & F CASH",
  "F & F SMSF",
  "MEZZANINE",
  "PERSONAL LOAN",
  "DRAW DOWN MORTGAGE",
  "OFFSET ACCOUNT",
  "CREDIT CARD",
  "BUSINESS LOAN",
  "PERSONAL FUNDS",
  "PERSONAL ASSETS",
  "BUSINESS ASSETS",
  "OTHER",
];

const gapFundingDesc = [
  "GET FRIENDS AND FAMILY TO GIVE YOU CASH",
  "GET FRIENDS AND FAMILY TO LOAN FROM THEIR SMSF",
  "DEBT AND EQUITY",
  "BANKS",
  "BANKS",
  "BANKS",
  "BANKS",
  "BANKS",
  "USE YOUR OWN SAVINGS",
  "SELL PERSONAL ASSETS TO FUND IT",
  "SELL ASSETS IN YOUR EXISTING BUSINESS TO FUND IT",
  "SELECT THIS OPTION TO ADD YOUR OWN CUSTOM STRATEGY",
];

export const gapFundingStrategies = gapFundingValues
  .map((i, idx) => ({
    value: i,
    description: gapFundingDesc[idx],
    id: i,
    label: i,
    inputText: "",
  }))
  .sort(compareFn);
