/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

function DataTableBodyCell({
  width,
  noBorder,
  align,
  children,
  colSpan,
  rowSpan,
  px = 3,
  vertiAlign,
  ...rest
}) {
  return (
    <MDBox
      component="td"
      textAlign={align}
      pt={1.5}
      pb={1}
      px={px}
      rowSpan={rowSpan}
      colSpan={colSpan}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        width,
        verticalAlign: vertiAlign,
      })}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        {...rest}
        sx={{ verticalAlign: "middle", width: "100%" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
