import qs from "qs";
import { useEffect, useRef, useState } from "react";
import { useLocation as useOriginLocation } from "react-router-dom";
import { Subject } from "rxjs";
import { useFlatInject, useInject } from "../store";
import { isBrowser } from "./index";

/**
 * @param fn
 */
export const useMount = (fn) => {
  const mountedRef = useRef(false);
  useEffect(() => {
    if (mountedRef.current) {
      return;
    }
    mountedRef.current = true;
    fn();
  }, []);
};

export const useBrowserInit = (fn) => {
  const initRef = useRef(false);
  if (!initRef.current && isBrowser) {
    initRef.current = true;
    fn();
  }
};

export const useLocation = () => {
  const $location = useOriginLocation();
  let query = {};
  if ($location.search) {
    query = {
      ...qs.parse($location.search.slice(1)),
    };
  }
  return {
    ...$location,
    query,
  };
};

export { useAsyncFunction as useHttp } from "great-async";

export function useMaterialUIController() {
  const [{ state, actions }] = useInject("mui");
  return [state, actions];
}

export function useLoading(loading) {
  const [{ show, hide }] = useFlatInject("loading", {});
  const showLoadingRef = useRef(0);
  useEffect(() => {
    if (loading && showLoadingRef.current === 0) {
      show();
      showLoadingRef.current++;
    } else if (!loading && showLoadingRef.current > 0) {
      hide();
      showLoadingRef.current--;
    }
  }, [hide, loading, show]);
  useEffect(
    () => () => {
      if (showLoadingRef.current > 0) {
        hide();
      }
    },
    [hide]
  );
}

/**
 *
 * @param {Subject} observer
 * @param {(value: any) => void} listener
 */
export const useObserver = (observer, listener) => {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;
  useEffect(() => {
    const unsubHandler = observer.subscribe((value) => {
      listenerRef.current?.(value);
    });
    return () => unsubHandler.unsubscribe();
  }, []);
};

export const useObserverState = (observer, defaultState) => {
  const [state, setState] = useState(defaultState);
  useEffect(() => {
    const unsubHandler = observer.subscribe(setState);
    return () => unsubHandler.unsubscribe();
  }, []);
  return state;
};

export const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const useAutosave = (callback, delay = 30000, deps = []) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function runCallback() {
      savedCallback.current();
    }
    if (typeof delay === "number") {
      const interval = setInterval(runCallback, delay);
      return () => clearInterval(interval);
    }
  }, [delay, ...deps]);
};
