import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DealStrategiesItem({ value, description, fontSize }) {
  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  const handleRenderDescription = (_value) => {
    if (description === "Sell parts of the IP in the business off") {
      return description;
    }
    return String(_value)?.toLowerCase() === "other" && !description
      ? String("Select this option to add your own custom strategy.").toLowerCase()
      : String(description).slice(-4).toUpperCase() === "SMSF"
      ? `${String(description)
          .toLowerCase()
          .substring(0, description.length - 4)} SMSF`
      : String(description).toLowerCase();
  };
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      sx={{ gap: "20px", ...borderBottom }}
      mb="20px"
      pb="20px"
    >
      <MDTypography
        fontWeight="medium"
        fontSize={fontSize ?? "16px"}
        sx={{ lineHeight: "24px", color: "#282828" }}
      >
        {value}
      </MDTypography>
      <MDTypography
        fontWeight="regular"
        fontSize={fontSize ?? "16px"}
        sx={{
          lineHeight: "24px",
          color: "#282828",
          "&::first-letter": {
            textTransform: "capitalize",
          },
        }}
      >
        {handleRenderDescription(value)}
      </MDTypography>
    </MDBox>
  );
}

export default DealStrategiesItem;
