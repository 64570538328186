import { createStore, createUseInject } from "natur";
import { thunkMiddleware, withImmerAPIInterceptor } from "natur-immer";
import {
  promiseMiddleware,
  shallowEqualMiddleware,
  filterUndefinedMiddleware,
  fillObjectRestDataMiddleware,
} from "natur/dist/middlewares";
import createPersistMiddleware from "natur-persist";
import loading from "./loading";
import message from "./message";
import route from "./route";
import user from "./user";
import mui from "./mui";
import dealPlanner from "./dealPlanner";
import dealPlanOperation from "./dealPlanOperation";

let devTool = () => (next) => (record) => next(record);

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line
  devTool = require("./redux-devtool").default;
}

const { middleware: localStorageMiddleware } = createPersistMiddleware({
  name: "_data",
  time: 300,
  include: ["user"],
  specific: { user: 0 },
  storageType: "localStorage",
});

const modules = {
  loading,
  message,
  route,
  user,
  mui,
  dealPlanner,
  dealPlanOperation,
};
const lazyModules = {};

export const store = createStore(modules, lazyModules, {
  interceptors: [withImmerAPIInterceptor],
  middlewares: [
    thunkMiddleware,
    promiseMiddleware,
    fillObjectRestDataMiddleware,
    shallowEqualMiddleware,
    filterUndefinedMiddleware,
    devTool,
    localStorageMiddleware,
  ],
});

if (process.env.NODE_ENV === "development") {
  window.store = store;
}

export const useInject = createUseInject(() => store);
export const useFlatInject = createUseInject(() => store, { flat: true });
