import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LeftArrow from "assets/images/left-arrow-dark.svg";
import MenuIcon from "assets/images/small-logos/menu.svg";

function HeaderNavbarMobile({ formatRouteName, route, handleClose }) {
  return (
    <MDBox
      ml="-24px"
      mt="-24px"
      mr="-24px"
      sx={{
        background: "#004225",
        height: "120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "80px 24px 40px",
      }}
    >
      <MDTypography
        fontSize={{ xs: "24px", lg: "32px" }}
        sx={{
          color: "#fff",
          textTransform: "capitalize",
        }}
      >
        {formatRouteName(route[route.length - 1])?.toLowerCase()}
      </MDTypography>
      <MDBox
        onClick={handleClose}
        component="img"
        src={MenuIcon}
        sx={{
          cursor: "pointer",
        }}
      />
    </MDBox>
  );
}

export default HeaderNavbarMobile;
