import { Link } from "@mui/material";
import MDBox from "components/MDBox";

export const listDataTerms = [
  {
    title: "1. About the Website ",
    childs: [
      {
        desc: (
          <MDBox sx={{ color: "#282828", fontWeight: "light" }}>
            1.1. Welcome to <a href="/">www.dealdashboard.com.au </a> (“Website”). The Website is
            operated by Empire Intelligence Pty Ltd ABN 46 666 363 579 under the Registered Business
            Name ‘Deal Dashboard’ (“Deal Dashboard”). Access to and use of the Website, or any of
            its associated products or Services, is provided by Deal Dashboard.
          </MDBox>
        ),
      },
      {
        desc: "1.2. Please read these terms and conditions (“Terms”) carefully. Using, browsing, reading and/or adding a Deal Plan signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of The Services, immediately, and not add a Deal Plan.",
      },
      {
        desc: "1.3. Deal Dashboard reserves the right to review and change any of the Terms by updating this page at its sole discretion. When Deal Dashboard updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. ",
      },
      {
        desc: "1.4. You may not use the Services and may not accept the Terms if:",
        collapse: [
          "1.4.1. you are not of legal age to form a binding contract with Deal Dashboard; or",
          "1.4.2. you are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services.",
        ],
      },
    ],
  },
  {
    title: "2. Acceptance of the Terms ",
    childs: [
      {
        desc: "2.1. You accept and agree to be bound by these Terms by clicking to accept or agree to the Terms or by accessing, browsing, remaining on the Website and/or adding a plan. If you do not accept these Terms you must immediately stop using the Service. ",
      },
    ],
  },
  {
    title: "3. About the Service",
    childs: [
      {
        desc: "3.1. Deal Dashboard provides a web-based, business-to-business, subscription-based service that allows individuals to create, view and interact with business plans and content relating to development and negotiation of business-to-business commercial deals (“Service”).",
      },
      {
        desc: "3.2. Deal Dashboard provides a range of templates, guidelines and processes to assist with the efficient recording, assessment and progression of certain types of prospective business transactions.",
      },
      {
        desc: "3.3. Data, documents notes and records relating to a specific, named transaction is called a Deal Plan or Plan (“Deal Plan”) ",
      },
      {
        desc: "3.4. A subset of data provided by Members can be optionally used in an optional, upgraded product level to produce a pro-forma Memorandum of Agreement (“Term Sheet”).",
      },
      {
        desc: "3.5. Deal Dashboard is provided exclusively for reference and efficiency and is not offered as a professional legal, financial or business advisory service.",
      },
      {
        desc: "3.6. Members understand and agree that they must consider their own unique circumstances when using any general information or output from Deal Dashboard.  ",
      },
    ],
  },
  {
    title: "4. Users, Deal Plans and Features ",
    childs: [
      {
        desc: "4.1. Once you have completed the registration process, you will be a registered User of the Website and agree to be bound by the Terms.  ",
      },
      {
        desc: "4.2. There is one type of user of the Deal Dashboard Platform (“Users”). This is:  ",
        collapse: [
          "4.2.1. Member (“Member”): A member is an individual, identified by their unique email address, who has successfully completed the Member account registration process, and in so doing, has a) paid in full the required Fees to access The Service and b) accepted the Terms. ",
        ],
      },
      {
        desc: "4.3. Changes to Account Features: You acknowledge and agree that the accounts offered, as well as the account features, may change from time to time and may be governed by separate terms which apply specifically to the type of account being used. Where special account-specific terms apply, you will be informed, and must accept those terms before you are given such an account. For the avoidance of doubt, these Terms apply unless otherwise agreed or amended by account-specific terms.",
      },
      {
        desc: "4.4. Related agreements: Some accounts may be governed by a separate Software Licensing Agreement with Deal Dashboard.",
      },
    ],
  },
  {
    title: "5. Scope of the Service:",
    childs: [
      {
        desc: "5.1. Deal Dashboard operates exclusively as a platform for Members to create, record and export certain information and data relating to certain business transactions. By accepting these Terms, you acknowledge that: ",
        collapse: [
          "5.1.1. Deal Dashboard is not an accredited legal or financial services provider and does not represent itself as such. ",
          "5.1.2. Deal Dashboard does not provide any legally binding mechanism to propose or offer for agreement as a contract. ",
          "5.1.3. Deal Dashboard is not a contract forming service or platform.",
          "5.1.4. Deal Dashboard is not a formal legal or legal advisory body of any form and does not provide advice on or any legal products or legal services. ",
          "5.1.5. Deal Dashboard is not a formal financial or financial advisory body of any form and does not provide advice on any financial products or legal services.",
          "5.1.6. Any information uploaded to the Website has been prepared by Members. Deal Dashboard has not reviewed the information contained in any Deal Plan on any part of the Website and Deal Dashboard is not responsible for this information or any errors or omissions in it.",
          "5.1.7. Deal Dashboard does not offer any opinion, endorsement, advice, evaluation, or recommendation about the information presented by Members in Deal Plans.",
          "5.1.8. The information contained on the Website about Deal Plans is not intended to be the only information on which decisions are made and is not a substitute for the Member undertaking due diligence, using their own judgement and/or seeking appropriate external professional advice on the prospective transaction relating to the Deal Plan.",
          "5.1.9. Members must rely exclusively on their own assessment of the quality and reliability of the information collected and determine and pursue their own mechanisms for further and appropriate due diligence and / or formal contracting arrangements should they choose to do so. ",
          "5.1.10. To the extent information is provided on the Website, such information is general in nature and Deal Dashboard recommends that Members obtain their own professional advice that considers their personal circumstances.",
        ],
      },
    ],
  },
  {
    title: "6. Acceptable use of the Service ",
    childs: [
      {
        desc: "6.1. Deal Dashboard, its related features, and website must only be used lawfully. Deal dashboard reserves the right to suspend, cancel, or otherwise deny access to users and accounts who use the service:",
        collapse: [
          {
            desc: "6.1.1. To engage in any act that would disrupt the access, availability, and security of Deal Dashboard and other Deal Dashboard services, including but not limited to:",
            collapse: [
              "a. Tampering with, reverse-engineering, or hacking our servers.",
              "b. Modifying, disabling, or compromising the performance of Deal Dashboard or other Deal Dashboard Services. ",
              "c. Overwhelming, or attempting to overwhelm our infrastructure by imposing an unreasonably large load on our systems that consume extraordinary resources. ",
              "d. Compromising the integrity of our system, including probing, scanning and testing the vulnerability of our system unless expressly permitted by Deal Dashboard.",
            ],
          },
          {
            desc: "6.1.2. For any illegal purpose, or to violate any laws, including and without limitation to data, privacy, and export control laws.",
          },
          {
            desc: "6.1.3. To stalk, harass or threaten Members and or any member of the public.",
          },
          {
            desc: "6.1.4. To misrepresent or defraud any Member or member of the public through phishing, spoofing, manipulating headers or other identifiers, impersonating anyone else, or falsely implying any sponsorship or association with Deal Dashboard or any third party. ",
          },
          {
            desc: "6.1.5. To access or search any part of the Service, or any other Service owned by Deal Dashboard other than our publicly supported interface, or otherwise allowed for in an applicable Software Licensing Agreement.",
          },
          {
            desc: "6.1.6. To post, upload, share, or otherwise circulate content in violation of any other relevant Deal Dashboard Policy.",
          },
        ],
      },
    ],
  },
  {
    title: "7. Security and Data Use",
    childs: [
      {
        desc: "7.1. Deal Dashboard’s obligations: Deal Dashboard takes your privacy seriously and information provided through your use of the Website and/or Services are subject to the Deal Dashboard Privacy Policy, which is available on the Website.",
      },
      {
        desc: "7.2. Member’s Obligations: You agree you are responsible for maintaining the confidentiality of your account and password and that your password will meet the minimum password security standards and that you will not share that password with any other person.",
      },
      {
        desc: "7.3. As part of the registration process, or as part of your continued use of the Services, you may be required to provide:",
        collapse: [
          "7.3.1. personal information about yourself (such as identification or contact details), including but not limited to name, role, email address, contact number",
          "7.3.2. business related information such as business name, website, business address, ABN/ACN/DUNS number and other relevant business attributes",
        ],
      },
      {
        desc: "7.4. You warrant that any information you give to Deal Dashboard in the course of completing the registration process will be accurate, correct and up to date.",
        collapse: [
          "7.4.1. personal information about yourself (such as identification or contact details), including but not limited to name, role, email address, contact number",
          "7.4.2. business related information such as business name, website, business address, ABN/ACN/DUNS number and other relevant business attributes",
        ],
      },
      {
        desc: "7.5. You warrant that any information you give to Deal Dashboard in the course of completing the registration process will be accurate, correct and up to date.",
      },
    ],
  },
  {
    title: "8. Fees to use the Service",
    childs: [
      {
        desc: "8.1. Members pay a Subscription Fee and they must complete the registration process and create an Account to view and/or create Deal Plans. ",
      },
      {
        desc: "8.2. You acknowledge and agree that where a request for the payment of the Subscription Fee is returned or denied, for whatever reason, by your financial institution or is unpaid by you for any other reason, then you are liable for any costs, including banking fees and charges, associated with the non-payment of the Subscription Fee. ",
      },
      {
        desc: "8.3. You agree and acknowledge that Deal Dashboard can vary the Subscription Fee at any time and that the varied Subscription Fee will come into effect following the conclusion of the existing Subscription. ",
      },
    ],
  },
  {
    title: "9. Using the Service ",
    childs: [
      {
        desc: "9.1. By creating a Member Account or creating a Deal Plan, members acknowledge agree and represent to Deal Dashboard:",
        collapse: [
          "9.1.1. they are solely responsible for the accuracy and completeness of the information provided in any Deal Memos.",
          "9.1.2. they are solely responsible for the content they create by using The Service.",
          "9.1.3. Deal Dashboard does not verify the accuracy of any information, data, calculations, or documents input by the member and is not responsible for any inaccuracies.",
        ],
      },
    ],
  },
  {
    title: "10. Optional Services ",
    childs: [
      {
        desc: "10.1. This section 10, is only applicable if the Member purchases an optional and chargeable service or product from Deal Dashboard (“Optional Services”).",
      },
      {
        desc: "10.2. Subject to the terms of any applicable Software License Agreement, a Subscription Fee may be charged by any payment method on the Website and may change from time to time.",
      },
      {
        desc: "10.3. Payments made in the course of use of the Optional Services may be made using third-party applications and services not owned, operated, or otherwise controlled by Deal Dashboard. You acknowledge and agree that Deal Dashboard will not be liable for any losses or damage arising from the operations of third-party payment applications and services. You further acknowledge and warrant that you have read, understood and agree to be bound by the terms and conditions of the applicable third-party payment applications and services you choose to use as a payment method for Deal Dashboard’s Optional Services. ",
      },
    ],
  },
  {
    title: "11. Refund Policy",
    childs: [
      {
        desc: "11.1. Deal Dashboard offers refunds in accordance with the Australian Consumer Law and on the terms set out in these Terms. Any benefits set out in these Terms may apply in addition to consumer’s rights under the Australian Consumer Law.",
      },
      {
        desc: "11.2. Deal Dashboard will provide you with a refund of the Subscription Fee in the event they are unable to continue to provide the Services or Deal Dashboard decides, at its absolute discretion, that it is reasonable to do so under the circumstances. Where this occurs, the refund will be in the proportional amount of the Subscription Fee that remains unused by the User (“Refund”).",
      },
    ],
  },
  {
    title: "12. Copyright and Intellectual Property",
    childs: [
      {
        desc: "12.1. The Deal Dashboard Website, the Services and all of the related products of Deal Dashboard are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips, Website code, scripts, design elements and interactive features) or the Services are owned or controlled by and are reserved to Deal Dashboard or its contributors.",
      },
      {
        desc: "12.2. All trademarks, service marks and trade names are owned, registered and/or licensed by Deal Dashboard. Deal Dashboard grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a User to: ",
        collapse: [
          "12.2.1. use the Website pursuant to the Terms;",
          "12.2.2. copy and store the Website and the material contained in the Website in your device’s cache memory; and",
          "12.2.3. print pages from the Website for your own personal and non-commercial use only.",
        ],
      },
      {
        desc: "12.3. Deal Dashboard does not grant you any other rights whatsoever in relation to the Website or the Services. All other rights are expressly reserved by Deal Dashboard.",
      },
      {
        desc: "12.4. Deal Dashboard retains all rights, title and interest in and to the Website and all related Services. Nothing you do on or in relation to the Website will transfer any: ",
        collapse: [
          "12.4.1. business name, trading name, domain name, trademark, industrial design, patent, registered design or copyright, or",
          "12.4.2. right to use or exploit a business name, trading name, domain name, trademark or industrial design, or",
          "12.4.3. thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a thing, system or process), to you.",
        ],
      },
      {
        desc: "12.5. You may not, without the prior written permission of Deal Dashboard and the permission of any other relevant rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third-party Services for any purpose, unless otherwise expressly allowed by these Terms. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain. ",
      },
    ],
  },
  {
    title: "13. Links to other Sites ",
    childs: [
      {
        desc: "13.1. You may, through hypertext or other computer links, be able to access other websites operated either by Deal Dashboard, its affiliates (“Linked Sites”) or other third parties (“Third Party Linked Sites”).",
      },
      {
        desc: "13.2. The Linked Sites may have different terms of use. ",
      },
      {
        desc: "13.3. Unless otherwise specified, Deal Dashboard does not control the Third Party Linked Sites. The Third Party Linked Sites may have different terms of use and may be subject to different laws. Deal Dashboard is not responsible for the content of any Third Party Linked Sites, or any changes or updates to those sites. These links are provided for your convenience only. You link to any Third Party Linked Sites at your own risk. Deal Dashboard is not a party to any transaction between you and a Third Party Linked Site. Unless specified, Deal Dashboard does not sponsor, endorse, adopt, confirm, guarantee, or approve of any material or representations made in those Third Party Linked Sites ",
      },
    ],
  },
  {
    title: "14. General Disclaimer",
    childs: [
      {
        desc: "14.1. Nothing in the Terms limits or excludes any guarantees, warranties, representations, or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.",
      },
      {
        desc: "14.2. Subject to this clause, and to the extent permitted by law:",
        collapse: [
          {
            desc: "14.2.1. all terms, guarantees, warranties, representations, or conditions which are not expressly stated in the Terms are excluded; and",
          },
          {
            desc: "14.2.2. Deal Dashboard will not be liable for:",
            collapse: [
              {
                desc: "1. any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.",
              },
              {
                desc: "2. any loss or damage you might suffer as a result of any of the following: ",
                collapse: [
                  "a. failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third-party conduct, or theft, destruction, alteration or unauthorised access to records;",
                  "b. the accuracy, suitability or currency of any information on the Website, the Services, or any of its Services related products (including third party material and advertisements on the Website);",
                  "c. costs incurred because of you using the Website, the Services or any of the products of Deal Dashboard; and",
                  "d. the Services or operation in respect to links which are provided for your convenience. ",
                ],
              },
            ],
          },
        ],
      },
      {
        desc: "14.3. The Information provided on the Website is general in nature. You should not use it for making any decision, without consulting primary, more accurate, more complete or more timely sources of information. ",
      },
      {
        desc: "14.4. Use of the Website and the Services is at your own risk. Everything on the Website and the Services is provided to you “as is” and “as available” without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors, and licensors of Deal Dashboard make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of Deal Dashboard) referred to on the Website. ",
      },
    ],
  },
  {
    title: "15. Limitation of Liability",
    childs: [
      {
        desc: "15.1. Deal Dashboard’s total liability arising out of or in connection with the Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed value of payments made by the User to Deal Dashboard within the previous 12 month period",
      },
      {
        desc: "15.2. Deal Dashboard shall not be liable for any breach of the Corporations Act 2001 (Cth) by the User or any other legislation breached by the User.",
      },
    ],
  },
  {
    title: "16. Termination of Contract",
    childs: [
      {
        desc: "16.1. The Terms will continue to apply until terminated by either you or by Deal Dashboard as set out below.",
      },
      {
        desc: "16.2. If you wish to terminate the Terms, you may do so by:",
        collapse: [
          "16.2.1. not renewing the Subscription prior to the end of the Subscription Period, if a Subscription Fee is charged",
          <MDBox sx={{ color: "#282828", fontWeight: "light" }}>
            16.2.2. cancelling your subscription in your profile on{" "}
            <a href="/">http://www.dealdashboard.com.au</a>
          </MDBox>,
        ],
      },
      {
        desc: "16.3. Following the cancellation of your subscription you will have 30 days to download any of your documents on Deal Dashboard or renew your subscription. After this time, if your subscription is not renewed, all data connected to your profile will be deleted and no longer available.",
      },
      {
        desc: "16.4. Deal Dashboard may at any time, terminate the Terms with you if: ",
        collapse: [
          "16.4.1. you do not renew the Subscription at the end of the Subscription Period, if a Subscription Fee is charged; ",
          "16.4.2. you have breached any provision of the Terms or Deal Dashboard reasonably believes you intend to breach any provision;",
          "16.4.3. Deal Dashboard is required to do so by law;",
          "16.4.4. the provision of the Services to you by Deal Dashboard is, in the opinion of Deal Dashboard, no longer commercially viable.",
        ],
      },
      {
        desc: "16.5. Effect of termination:  Subject to applicable laws in Victoria and Australia, Deal Dashboard reserves the right to discontinue or cancel your Account at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts Deal Dashboard’s name or reputation or violates the rights of those of another party.",
      },
    ],
  },
  {
    title: "17. Indemnity",
    childs: [
      {
        desc: "17.1. You agree to indemnify Deal Dashboard, its affiliates, employees, agents, contributors, third party content providers and licensors from and against:",
        collapse: [
          {
            desc: "17.1.1. all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with: ",
            collapse: [
              "1. content loaded to the Website by you;. ",
              "2. your breach of these Terms; and ",
              "3. any breach by you of the Corporations Act 2001 (Cth); and/or",
            ],
          },
          {
            desc: "17.1.2. any direct or indirect damages suffered by Deal Dashboard arising out of or in connection with you accessing, using or transacting on the Website or attempts to do so.  ",
          },
        ],
      },
      {},
    ],
  },
  {
    title: "18. Dispute Resolution",
    childs: [
      {
        desc: "18.1. Compulsory: If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).",
      },
      {
        desc: "18.2. Notice: A party to the Terms claiming a dispute (“Dispute”) has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute.",
      },
      {
        desc: "18.3. Resolution: On receipt of that notice (“Notice”) by that other party, the parties to the Terms (“Parties”) must: ",
        collapse: [
          "18.3.1. Within 28 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;",
          "18.3.2. If for any reason whatsoever, 28 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the Resolution Institute;",
          "18.3.3. The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing, undertake to pay any amounts requested by the mediator as a precondition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;",
          "18.3.4. The mediation will be held in Melbourne, Australia.",
        ],
      },
      {
        desc: `18.4. Confidential. All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.`,
      },
      {
        desc: "18.5. Termination of Mediation: If two months have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.",
      },
    ],
  },
  {
    title: "19. Venue and Jurisdiction",
    childs: [
      {
        desc: "19.1. The Services offered by Deal Dashboard are intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria.",
      },
    ],
  },
  {
    title: "20. Governing Law ",
    childs: [
      {
        desc: "20.1. The Terms are governed by the laws of Victoria. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of Victoria, Australia without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns. ",
      },
      {
        desc: "20.2. If you access the Website from another jurisdiction, you must also comply with the laws of that jurisdiction.",
      },
    ],
  },
  {
    title: "21. Severance",
    childs: [
      {
        desc: "21.1. If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that term must:",
        collapse: [
          "21.1.1. be interpreted as narrowly as necessary to allow it to be enforceable or valid; or ",
          "21.1.2. if it cannot be interpreted narrowly to allow it to be enforceable and valid, be severed from the Terms without affecting the enforceability of the remaining terms. ",
        ],
      },
      {},
    ],
  },
];
