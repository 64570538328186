import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "examples/Footer";
import Header from "pages/terms-page/components/Header";
import MDBox from "components/MDBox";
import MainContact from "./components/Main";

function Contact() {
  return (
    <PageLayout background="#F5F7FA">
      <MDBox ml="-28px">
        <Header />
      </MDBox>
      <MainContact />
      <Footer />
    </PageLayout>
  );
}

export default Contact;
