import { useForm, Form, FormItem } from "components/Form";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { useEffect, useState, createRef } from "react";
import { Message } from "utils/message";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useFlatInject, useInject } from "store";
import Loading from "components/Loading";
import { useLocation } from "react-router-dom";
import { getToken } from "utils/auth";
import { useMaterialUIController } from "utils/hooks";

const recaptchaRef = createRef({});

function MainContact() {
  const [contact, setContact] = useState({
    email: "",
    name: "",
    description: "",
    token: "",
  });
  const [user] = useFlatInject("user");
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:480px)");
  const token = getToken();
  const form = useForm({
    initialValues: {
      email: contact.email,
      name: contact.name,
      description: contact.description,
      token: contact.token,
    },
    onSubmit: async (formData, { validateForm, resetForm }) => {
      try {
        await validateForm();
        await user.contactUs(formData);
        setContact({
          email: "",
          name: "",
          description: "",
          token: "",
        });

        resetForm();
        recaptchaRef.current?.reset();
        Message.success("Send mail success!");
      } catch (error) {
        Message.error(error?.message ?? "Error when send mail!");
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required("Name is a required field"),
      email: Yup.string().required("Email is required").email("Please enter a valid email address"),
      description: Yup.string().trim().required("Description is a required field"),
      token: Yup.string().trim().required(),
    }),
  });

  const onChangeCaptcha = (value) => {
    setContact({ ...contact, token: value });
    form.setValues({
      ...form.values,
      token: value,
    });
  };

  const [windowResize, setWindowResize] = useState(window.innerWidth);
  useEffect(() => {
    function handleMiniSidenav() {
      setWindowResize(window.innerWidth);
    }
    window.addEventListener("resize", handleMiniSidenav);
    // A function that sets the mini state of the sidenav.
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [location]);

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pb={8}
      sx={{
        ...(windowResize < 1600 &&
          windowResize > 1200 &&
          token &&
          !miniSidenav && { marginLeft: "250px" }),
      }}
    >
      <MDBox
        p={5}
        pb={10}
        sx={{
          background: "#fff",
          gap: "40px",
          borderRadius: "20px",
          ...(isMobile && { height: "100%" }),
        }}
        display="flex"
        flexDirection="column"
      >
        <MDTypography sx={{ fontSize: "24px", fontWeight: "500", color: "#282828" }}>
          Contact us
        </MDTypography>
        <MDTypography
          fontWeight="normal"
          sx={{ fontSize: "14px", color: "#282828", fontWeight: "400" }}
        >
          Thanks for visiting Deal Dashboard.{" "}
          <MDTypography
            fontWeight="normal"
            sx={{ fontSize: "14px", color: "#282828", fontWeight: "400" }}
          >
            If you have any questions, please complete the form below and a member of our team will
            get back to you soon.
          </MDTypography>
        </MDTypography>
        <MDBox form={form} component={Form}>
          <MDBox display="flex" flexDirection="column" sx={{ gap: "40px" }}>
            <MDBox
              display="flex"
              flexDirection="row"
              sx={{
                gap: "20px",
                width: "100%",
              }}
            >
              {/* <FormField
                label="Name"
                placeholder="Alec"
                value={contact.name}
                onChange={(e) => setContact({ ...contact, name: e.target.value })}
                // inputStyle={{ color: "#282828", fontSize: "16px", fontWeight: "400" }}
                labelStyle={{
                  color: "#282828 !important",
                  fontSize: "12px !important",
                  fontWeight: "400",
                }}
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                    color: "#757575",
                  },
                  "& .MuiInputBase-root": {
                    "& .MuiInputBase-input": {
                      color: "#282828",
                    },
                  },
                }}
              /> */}
              {/* <FormField
                label="Email"
                placeholder="example@email.com"
                value={contact.email}
                onChange={(e) => setContact({ ...contact, email: e.target.value })}
                inputProps={{ type: "email" }}
                // inputStyle={{ color: "#757575", fontSize: "16px", fontWeight: "400" }}
                labelStyle={{
                  color: "#282828 !important",
                  fontSize: "12px !important",
                  fontWeight: "400",
                }}
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                    color: "#757575",
                  },
                  "& .MuiInputBase-root": {
                    "& .MuiInputBase-input": {
                      color: "#282828",
                    },
                  },
                }}
              /> */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormItem
                    as={MDInput}
                    type="text"
                    name="name"
                    variant="standard"
                    fullWidth
                    placeholder="Alec"
                    InputLabelProps={{ shrink: true }}
                    label="Name"
                    labelStyle={{ display: "none" }}
                    fieldSx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          color: "#red !important",
                        },
                        input: {
                          "&::placeholder": {
                            color: "#757575 !important",
                          },
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "#282828 !important",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormItem
                    as={MDInput}
                    type="email"
                    name="email"
                    variant="standard"
                    fullWidth
                    placeholder="Email address"
                    InputLabelProps={{ shrink: true }}
                    label="Email"
                    labelStyle={{ display: "none" }}
                    fieldSx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          color: "#282828 !important",
                        },
                        input: {
                          "&::placeholder": {
                            color: "#757575 !important",
                          },
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "#282828 !important",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
            {/* <FormField
              label="Description"
              placeholder="Description"
              multiline
              value={contact.description}
              // inputStyle={{ color: "#282828", fontSize: "16px", fontWeight: "400" }}
              onChange={(e) => {
                setContact({ ...contact, description: e.target.value });
              }}
              labelStyle={{
                color: "#282828 !important",
                fontSize: "12px !important",
                fontWeight: "400",
              }}
              sx={{
                "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                  color: "#757575",
                },
                "& .MuiInputBase-root": {
                  "& .MuiInputBase-input": {
                    color: "#282828",
                  },
                },
              }}
            /> */}
            <FormItem
              as={MDInput}
              type="text"
              name="description"
              variant="standard"
              fullWidth
              placeholder="Description"
              InputLabelProps={{ shrink: true }}
              label="Description"
              multiline
              labelStyle={{ display: "none" }}
              fieldSx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  "& .MuiInputBase-input": {
                    color: "#282828 !important",
                  },
                  input: {
                    "&::placeholder": {
                      color: "#757575 !important",
                    },
                  },
                },
                "& .MuiFormLabel-root": {
                  color: "#282828 !important",
                },
              }}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAP_SITE_KEY ?? ""}
              onChange={onChangeCaptcha}
              hl="en"
              ref={recaptchaRef}
            />
            <Loading />
            <MDBox display="flex" flexDirection="column">
              {
                // form?.errors?.description ||
                // form?.errors?.name ||
                form?.errors?.token &&
                // form?.errors?.email
                !!Number(form.submitCount) ? (
                  <Box color="error.main" fontSize={pxToRem(14)} component="span">
                    Please enter all field
                  </Box>
                ) : null
              }
              <MDButton
                variant="contained"
                color="green"
                size="large"
                sx={{ width: "150px", marginTop: "10px", fontSize: "14px" }}
                loading={form.isSubmitting}
                onClick={form.submitForm}
              >
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default MainContact;
