/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React context
import { useMaterialUIController } from "utils/hooks";

function Sidenav() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const sidenavItems = [
    { icon: "receipt_long", label: "Basic info", href: "basic-info" },
    { icon: "lock", label: "Change password", href: "change-password" },
    { icon: "person", label: "Subscription and payment", href: "sessions" },
    { icon: "payment", label: "Payment method", href: "payment" },
    { icon: "lock", label: "Invoices", href: "invoices" },
    // { icon: "security", label: "2FA", href: "2fa" },
    // { icon: "badge", label: "accounts", href: "accounts" },
    // { icon: "campaign", label: "notifications", href: "notifications" },
    // { icon: "settings_applications", label: "sessions", href: "sessions" },
    // { icon: "delete", label: "delete account", href: "delete-account" },
  ];
  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li">
        <MDTypography
          component="a"
          href={`#${href}`}
          variant="button"
          // textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            padding: `${pxToRem(20)} 24px`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),
            "&::first-letter": {
              textTransform: "capitalize",
            },
            fontWeight:
              (href === "basic-info" && window.location.hash === "") ||
              window.location.hash.includes(href)
                ? "medium"
                : "regular",
            backgroundColor:
              (href === "basic-info" && window.location.hash === "") ||
              window.location.hash.includes(href)
                ? "#D4AF37"
                : "transparent",
          })}
        >
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        py={35 / 8}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
