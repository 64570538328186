import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "examples/Footer";
import Header from "./components/Header";
import Main from "./components/Main";

function TermsPage() {
  return (
    <PageLayout background="#F5F7FA">
      <Header />
      <Main />
      <Footer />
    </PageLayout>
  );
}

export default TermsPage;
