/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Admin PRO React base styles
import colors from "assets/theme/base/colors";

// Otis Admin PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { transparent } = colors;

export default {
  styleOverrides: {
    // select: {
    //   display: "grid",
    //   alignItems: "center",
    //   padding: `0 ${pxToRem(12)} !important`,
    //   "& .Mui-selected": {
    //     backgroundColor: transparent.main,
    //   },
    // },
    // selectMenu: {
    //   background: "none",
    //   height: "none",
    //   minHeight: "none",
    //   overflow: "unset",
    // },
    // icon: {
    //   display: "none",
    // },
  },
};
