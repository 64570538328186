/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Data
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Otis Admin PRO React components

// Data
import { Box, Stack, styled } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import { Margin, usePDF } from "react-to-pdf";
import { useFlatInject } from "store";
import { submitAutoSave } from "utils/common";
import { useAutosave, useHttp } from "utils/hooks";
import MDBox from "../../../../../components/MDBox";
import { Message } from "../../../../../utils/message";
import UpgradeFromTermSheet from "../TermSheetupgrade/TermSheetContent";
import TermSheetContent from "./TermSheetContent";

const StyledTermSheetContent = styled(TermSheetContent)`
  display: none;
  @media print {
    display: ${({ canPrint = false }) => (canPrint ? "block" : "none")} !important;
    padding: 0;
  }
`;

const initValue = () => ({
  date: new Date(),
  between:
    "<Buyer Company Full Name>, (<Buyer Company Shorthand Name>) a corporation organized and existing under the law of <Buyer Company Geo location>, with its head office address located at: <Buyer Company Address>",
  sellerCompanyName: "",
  sellerCompanyNameShort: "",
  theirAddress: "",
  yourCompanyName: "",
  yourCompanyNameShort: "",
  yourCompanyLocation: "",
  yourCompanyAddress: "",
  overallTerms: "",
  shares: "shares",
  otherShares: "",
  numberOfBuyerSigns: 1,
  numberOfSellerSigns: 1,
});
/**
 * Initial term sheet or term sheet .v1
 */
function TermSheet({ isMobile, modeSavePDF = "edit", setTextAutoSave }) {
  const [{ termSheet, saveTermSheet, switchTermSheetVersion, dealReview, setCardOpen, baseInfo }] =
    useFlatInject("dealPlanOperation");
  const [{ user, canPrint, upgradePayment }] = useFlatInject("user");
  const { toPDF, targetRef } = usePDF({
    filename: `InitialTermSheet-${dayjs().format("DD/MM")}.pdf`,
    page: { margin: Margin.MEDIUM },
  });

  const { toPDF: toMobilePDF, targetRef: targetMobileRef } = usePDF({
    filename: `InitialTermSheet-${dayjs().format("DD/MM")}.pdf`,
    page: { margin: Margin.MEDIUM },
  });
  const [value, setValue] = useState(
    isEmpty(termSheet)
      ? initValue()
      : {
          ...initValue(),
          ...termSheet,
        }
  );
  const [mode, setMode] = useState("edit");

  useEffect(() => {
    setMode(modeSavePDF);
    setValue({ ...initValue(), ...termSheet });
  }, [modeSavePDF, termSheet]);

  // const form = useForm({
  //   initialValues: {
  //     text: termSheet,
  //   },
  //   onSubmit: async (formData) => {
  //     await saveTermSheet(formData.text);
  //     Message.success("Save success!");
  //   },
  // });
  const navigate = useNavigate();
  const location = useLocation();

  const onSavePdf = () => {
    const extraButtons = document.getElementsByClassName("extra-buttons-term-sheets");
    extraButtons.forEach((button) => {
      button.style.display = "none";
    });
    setMode("view");
    switchTermSheetVersion("v2");
    setTimeout(() => {
      isMobile ? toMobilePDF() : toPDF();
      setMode("edit");
    }, 1000);

    setTimeout(() => {
      extraButtons.forEach((button) => {
        button.style.display = "block";
      });
    }, 4000);
  };

  const submitForm = async (isAuto = false) => {
    try {
      await saveTermSheet(value);
      if (!isAuto) {
        window.location.href = `${location.pathname + location.search}#deal-due`;
        Message.success("Changes saved");
      }

      // setCardOpen("deal-due");
    } catch (error) {
      Message.error(error.message);
    }
  };

  const { run, loading } = useHttp(() => submitForm(false), { manual: true });
  const { run: runAuto } = useHttp(() => submitForm(true), { manual: true });

  useAutosave(async () => {
    if (baseInfo?.id) {
      submitAutoSave(() => runAuto(), setTextAutoSave);
    }
  });

  const onPrintBtnClick = () => {
    switchTermSheetVersion("v1");
    window.print();
  };

  return (
    <Card
      id="term-sheet"
      sx={{
        overflow: "visible",
        "@media print": {
          boxShadow: "none",
        },
        boxShadow: isMobile ? "none" : null,
      }}
    >
      <MDBox p={isMobile ? 0 : 3}>
        {user.vip === 0 ? (
          <>
            {!isMobile && (
              <Box display="flex" alignItems="center">
                <MDTypography variant="h4">Initial Term Sheet</MDTypography>
              </Box>
            )}
            <MDBox p={isMobile ? 1 : 3}>
              <Stack
                // direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  direction: {
                    lg: "row",
                    md: "row",
                    sm: "row",
                    xs: "column",
                  },
                }}
              >
                <MDTypography variant="h6" color="secondary">
                  Upgrade to a Deal Maker account to access the Term Sheet
                </MDTypography>
                <UpgradeFromTermSheet />
              </Stack>
            </MDBox>
          </>
        ) : (
          <div id="divcontents">
            <MDBox ref={targetRef}>
              {!isMobile && (
                <Box display="flex" alignItems="center">
                  <MDTypography variant="h4">Initial Term Sheet</MDTypography>
                </Box>
              )}
              <TermSheetContent
                className="no-print"
                mode={mode}
                value={value}
                onChange={setValue}
                isMobile={isMobile}
              />
            </MDBox>
            <StyledTermSheetContent canPrint={canPrint} value={value} />
            <MDBox pb={3} py={3} className="no-print" mt={3}>
              {!isMobile ? (
                <Grid
                  item
                  xs={12}
                  textAlign="right"
                  sx={{ display: "flex", gap: "8px", justifyContent: "end" }}
                >
                  <MDButton
                    color="green"
                    variant="outlined"
                    onClick={() => navigate("/deal-planner", { replace: true })}
                    sx={{ fontSize: "14px" }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    color="green"
                    sx={{ fontSize: "14px" }}
                    onClick={() => {
                      switchTermSheetVersion("v1");
                      setTimeout(() => {
                        onPrintBtnClick();
                      }, 3000);
                    }}
                  >
                    Print
                  </MDButton>
                  <MDButton color="green" onClick={onSavePdf} sx={{ fontSize: "14px" }}>
                    Save PDF
                  </MDButton>
                  <MDButton
                    color="green"
                    disabled={loading || !dealReview.businessName}
                    loading={loading}
                    onClick={() => run()}
                    sx={{ fontSize: "14px" }}
                  >
                    Save and next
                  </MDButton>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    textAlign="right"
                    sx={{ display: "flex", gap: "8px", justifyContent: "end" }}
                  >
                    {/* <MDButton
                      color="green"
                      sx={{ fontSize: isMobile ? "14px" : "12px", flex: 1 }}
                      onClick={() => {
                        switchTermSheetVersion("v1");
                        setTimeout(() => {
                          onPrintBtnClick();
                        }, 3000);
                      }}
                    >
                      Print
                    </MDButton> */}
                    <MDButton
                      color="green"
                      onClick={onSavePdf}
                      sx={{ fontSize: isMobile ? "14px" : "12px", flex: 1 }}
                    >
                      Save PDF
                    </MDButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    textAlign="right"
                    sx={{ display: "flex", gap: "8px", justifyContent: "end", marginTop: "10px" }}
                  >
                    <MDButton
                      color="green"
                      variant="outlined"
                      onClick={() => navigate("/deal-planner", { replace: true })}
                      sx={{ fontSize: isMobile ? "14px" : "12px", flex: 1 }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      color="green"
                      disabled={loading || !dealReview.businessName}
                      loading={loading}
                      onClick={() => run()}
                      sx={{ fontSize: isMobile ? "14px" : "12px", flex: 1 }}
                    >
                      Save and next
                    </MDButton>
                  </Grid>
                  <MDBox
                    ref={targetMobileRef}
                    sx={{
                      position: "absolute",
                      width: "1100px",
                      right: "100000px",
                      overflowx: "hidden",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <MDTypography variant="h4">Initial Term Sheet</MDTypography>
                    </Box>
                    <TermSheetContent
                      className="no-print"
                      mode={mode}
                      value={value}
                      onChange={setValue}
                      isMobile={isMobile}
                    />
                  </MDBox>
                </>
              )}
            </MDBox>
          </div>
        )}
      </MDBox>
    </Card>
  );
}

export default TermSheet;
