/* eslint-disable no-restricted-globals */
/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { MenuItem, Select, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import AddDealLogo from "assets/images/small-logos/add-logo.svg";
import ArchiveLogo from "assets/images/small-logos/archive.svg";
import DeleteLogo from "assets/images/small-logos/delete.svg";

import useMediaQuery from "@mui/material/useMediaQuery";
import ActiveAgainIcon from "assets/images/small-logos/active-again.png";
import BlackInforIcon from "assets/images/small-logos/black-infor.svg";
import CheckBoxActiveIcon from "assets/images/small-logos/checkbox-active.svg";
import CheckBoxIcon from "assets/images/small-logos/checkbox.svg";
import CloseIcon from "assets/images/small-logos/close.svg";
import MDTypography from "components/MDTypography";
import ModalUpdate from "components/Modal";
import { Table } from "components/Table";
import dayjs from "dayjs";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFlatInject } from "store";
import { Message } from "utils/message";
import { Modal } from "utils/modal";
import MDButton from "../../../components/MDButton";
import { useHttp, useLoading } from "../../../utils/hooks";

function DataTables() {
  const [dealPlanner] = useFlatInject("dealPlanner");
  const [{ updateId, resetDealPlanState }] = useFlatInject("dealPlanOperation");
  const isMobile = useMediaQuery("(max-width:430px)");
  const {
    fetchDealPlanList,
    pageInfo,
    tableData,
    updateName,
    deleteDealPlan,
    updateIsArchived,
    multiUpdateIsArchived,
    multiDeleteDealPlanner,
    updatePageInfo,
  } = dealPlanner;

  const [dataSource, setTableDataSource] = useState([]);
  const [isAll, setAll] = useState(false);
  const { loading, run: fetchDealPlanListProxy } = useHttp(fetchDealPlanList, {
    single: true,
    deps: [pageInfo?.pageNum, pageInfo?.pageSize, pageInfo.isArchived],
  });
  const { run } = useHttp(fetchDealPlanListProxy, {
    debounceTime: 1500,
    manual: true,
  });
  const { loading: deleting, run: deleteDealPlanProxy } = useHttp(deleteDealPlan, {
    single: true,
    manual: true,
  });
  const [user] = useFlatInject("user");
  const { user: userInfor, resumeSubscription } = user;

  const [isWarning, setWarning] = useState(true);
  const [openRenew, setOpenRenew] = useState(false);

  useEffect(() => {
    setTableDataSource((prevState) =>
      tableData?.map((data, index) => ({
        ...data,
        isChecked: prevState[index]?.isChecked ? data?.isChecked : false,
      }))
    );
  }, [tableData]);

  useEffect(() => {
    const isCheckAll = dataSource?.some((data) => !data?.isChecked);
    setAll(!isCheckAll);
  }, [dataSource]);
  const navigate = useNavigate();
  const multiUpdateArchived = async (isArchived) => {
    const ids = dataSource.filter((item) => item.isChecked).map((data) => data.id);
    const isChecked = dataSource.every((item) => !item.isChecked);
    if (!ids.length || isChecked) {
      try {
        await Modal.confirm({
          title: "Warning",
          content: "Please select at least one plan",
        });
      } catch (er) {
        console.log(er);
      }
    } else {
      try {
        await Modal.confirm({
          title: "Warning",
          content: isArchived
            ? "Are you sure you want to archive the selected deals?"
            : "Are you sure you want to set the selected deals to active?",
        });
        await multiUpdateIsArchived(ids, isArchived);
      } catch (er) {
        console.log(er);
      }
    }
  };
  const multiDeleteData = async () => {
    const ids = dataSource.filter((item) => item.isChecked).map((data) => data.id);
    const isChecked = dataSource.every((item) => !item.isChecked);
    if (!ids.length || isChecked) {
      await Modal.confirm({
        title: "Warning",
        content: "Please select at least one plan",
      });
    } else {
      await Modal.confirm({
        title: "Warning",
        content: "Are you sure you want to permanently delete the selected deals?",
      });
      await multiDeleteDealPlanner(ids);
      setAll(false);
      Message.success("Your deals has been successfully deleted");
    }
  };

  // const onClickRenew = () => {
  //   navigate("/my-profile");
  // };

  const columnsMobile = [
    {
      key: "checkBox",
      title: (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <MDBox
            onClick={() => {
              setAll(!isAll);
              setTableDataSource((prevState) =>
                prevState?.map((data) => ({ ...data, isChecked: !isAll }))
              );
            }}
            sx={{ width: "20px", height: "20px" }}
            component="img"
            src={isAll ? CheckBoxActiveIcon : CheckBoxIcon}
          />
          <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
            <MDBox display="flex" justifyContent="center" flexDirection="row" sx={{ gap: "23px" }}>
              <Tooltip title="Active" placement="top-start">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  flexDirection="row"
                  alignItems="center"
                  sx={{ gap: "8px", cursor: "pointer" }}
                  onClick={() => multiUpdateArchived(false)}
                >
                  <MDBox component="img" src={ActiveAgainIcon} sx={{ ml: "auto", mr: 0 }} />
                </MDBox>
              </Tooltip>
              <Tooltip title="Archive" placement="top-start">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  flexDirection="row"
                  alignItems="center"
                  sx={{ gap: "8px", cursor: "pointer" }}
                  onClick={() => multiUpdateArchived(true)}
                >
                  <MDBox component="img" src={ArchiveLogo} sx={{ ml: "auto", mr: 0 }} />
                </MDBox>
              </Tooltip>
              <Tooltip title="Delete" placement="top-start">
                <MDBox
                  component="img"
                  src={DeleteLogo}
                  sx={{ ml: "auto", mr: 0, cursor: "pointer" }}
                  onClick={multiDeleteData}
                />
              </Tooltip>
            </MDBox>
          </MDBox>
        </MDBox>
      ),
      dataIndex: "checkBox",
      render: (data) => {
        const title = data ? JSON.parse(data.dealReview).businessName : "";

        return (
          <MDBox>
            <MDBox sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <MDBox
                onClick={() => {
                  setTableDataSource((prevState) => {
                    const checked = prevState
                      .filter((state) => state?.id !== data?.id)
                      .some((item) => item.isChecked === false);
                    checked || data?.isChecked ? setAll(false) : setAll(true);
                    return prevState?.map((state) =>
                      state?.id === data.id
                        ? { ...state, isChecked: !data?.isChecked }
                        : { ...state }
                    );
                  });
                }}
                component="img"
                sx={{ width: "20px", height: "20px" }}
                src={data?.isChecked ? CheckBoxActiveIcon : CheckBoxIcon}
              />
              <MDTypography
                sx={{ cursor: "pointer", color: "#282828" }}
                fontWeight="regular"
                fontSize="18px"
                onClick={() => {
                  navigate(`/edit-a-deal?id=${data.id}`);
                }}
              >
                {title}
              </MDTypography>
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <MDBox>
                <MDTypography sx={{ color: "#757575" }} fontWeight="regular" fontSize="14px">
                  {dayjs(data.createdAt * 1000).format("DD/MM/YYYY")}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    flexDirection="row"
                    sx={{ gap: "23px", opacity: data.isChecked ? 0.5 : 1 }}
                  >
                    <Tooltip title="Active" placement="top-start">
                      <MDBox
                        display="flex"
                        disabled
                        justifyContent="center"
                        flexDirection="row"
                        alignItems="center"
                        sx={{
                          gap: "8px",
                          cursor: !data.isArchived ? "not-allowed" : "pointer",
                          opacity: !data.isArchived ? 0.5 : 1,
                        }}
                        onClick={async () => {
                          !data.isChecked && updateIsArchived(0, data.id);
                        }}
                      >
                        <MDBox component="img" src={ActiveAgainIcon} sx={{ ml: "auto", mr: 0 }} />
                      </MDBox>
                    </Tooltip>
                    <Tooltip title="Archive" placement="top-start">
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        flexDirection="row"
                        alignItems="center"
                        sx={{
                          gap: "8px",
                          cursor: !data.isArchived ? "pointer" : "not-allowed",
                          opacity: data.isArchived ? 0.5 : 1,
                        }}
                        onClick={async () => {
                          !data.isChecked && updateIsArchived(1, data.id);
                        }}
                      >
                        <MDBox component="img" src={ArchiveLogo} sx={{ ml: "auto", mr: 0 }} />
                      </MDBox>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top-start">
                      <MDBox
                        onClick={async () => {
                          if (!data.isChecked) {
                            await Modal.confirm({
                              title: "Warning",
                              content: "Are you sure you want to delete this plan?",
                            });
                            await deleteDealPlanProxy(data.id);
                            Message.success("Your deal has been successfully deleted");
                          }
                        }}
                        component="img"
                        src={DeleteLogo}
                        sx={{ cursor: !data.isChecked ? "pointer" : "not-allowed" }}
                      />
                    </Tooltip>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        );
      },
    },
  ];

  const columns = [
    {
      key: "checkBox",
      title: (
        <MDBox
          onClick={() => {
            setAll(!isAll);
            setTableDataSource((prevState) =>
              prevState?.map((data) => ({ ...data, isChecked: !isAll }))
            );
          }}
          component="img"
          src={isAll ? CheckBoxActiveIcon : CheckBoxIcon}
        />
      ),
      dataIndex: "checkBox",
      render: (record) => (
        <MDBox
          onClick={() => {
            setTableDataSource((prevState) => {
              const checked = prevState
                .filter((state) => state?.id !== record?.id)
                .some((item) => item.isChecked === false);
              checked || record?.isChecked ? setAll(false) : setAll(true);
              return prevState?.map((state) =>
                state?.id === record.id ? { ...state, isChecked: !record?.isChecked } : { ...state }
              );
            });
          }}
          component="img"
          src={record?.isChecked ? CheckBoxActiveIcon : CheckBoxIcon}
        />
      ),
    },
    {
      key: "targetBusiness",
      title: (
        <MDTypography fontWeight="normal" fontSize="10px" sx={{ color: "#282828" }}>
          Target Business
        </MDTypography>
      ),
      dataIndex: "targetBusiness",
      render: (data) => {
        const title = data ? JSON.parse(data.dealReview).businessName : "";
        return (
          <MDTypography
            sx={{ cursor: "pointer", color: "#282828" }}
            fontWeight="regular"
            fontSize="14px"
            onClick={() => {
              navigate(`/edit-a-deal?id=${data.id}`);
            }}
          >
            {title}
          </MDTypography>
        );
      },
    },
    {
      key: "createdAt",
      title: (
        <MDTypography fontWeight="normal" fontSize="10px" sx={{ color: "#282828" }}>
          Created at
        </MDTypography>
      ),
      dataIndex: "createdAt",
      render: (data) => (
        <MDTypography sx={{ color: "#757575" }} fontWeight="regular" fontSize="14px">
          {dayjs(data.createdAt * 1000).format("DD/MM/YYYY")}
        </MDTypography>
      ),
    },
    {
      key: "actions",
      title: (
        <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
          <MDBox display="flex" justifyContent="center" flexDirection="row" sx={{ gap: "23px" }}>
            <Tooltip title="Active" placement="top-start">
              <MDBox
                display="flex"
                justifyContent="center"
                flexDirection="row"
                alignItems="center"
                sx={{ gap: "8px", cursor: "pointer" }}
                onClick={() => multiUpdateArchived(false)}
              >
                <MDBox component="img" src={ActiveAgainIcon} sx={{ ml: "auto", mr: 0 }} />
              </MDBox>
            </Tooltip>
            <Tooltip title="Archive" placement="top-start">
              <MDBox
                display="flex"
                justifyContent="center"
                flexDirection="row"
                alignItems="center"
                sx={{ gap: "8px", cursor: "pointer" }}
                onClick={() => multiUpdateArchived(true)}
              >
                <MDBox component="img" src={ArchiveLogo} sx={{ ml: "auto", mr: 0 }} />
              </MDBox>
            </Tooltip>
            <Tooltip title="Delete" placement="top-start">
              <MDBox
                component="img"
                src={DeleteLogo}
                sx={{ ml: "auto", mr: 0, cursor: "pointer" }}
                onClick={multiDeleteData}
              />
            </Tooltip>
          </MDBox>
        </MDBox>
      ),
      render: (data) => (
        <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
          <MDBox
            display="flex"
            justifyContent="center"
            flexDirection="row"
            sx={{ gap: "23px", opacity: data.isChecked ? 0.5 : 1 }}
          >
            <Tooltip title="Active" placement="top-start">
              <MDBox
                display="flex"
                disabled
                justifyContent="center"
                flexDirection="row"
                alignItems="center"
                sx={{
                  gap: "8px",
                  cursor: !data.isArchived ? "not-allowed" : "pointer",
                  opacity: !data.isArchived ? 0.5 : 1,
                }}
                onClick={async () => {
                  !data.isChecked && updateIsArchived(0, data.id);
                }}
              >
                <MDBox component="img" src={ActiveAgainIcon} sx={{ ml: "auto", mr: 0 }} />
              </MDBox>
            </Tooltip>
            <Tooltip title="Archive" placement="top-start">
              <MDBox
                display="flex"
                justifyContent="center"
                flexDirection="row"
                alignItems="center"
                sx={{
                  gap: "8px",
                  cursor: !data.isArchived ? "pointer" : "not-allowed",
                  opacity: data.isArchived ? 0.5 : 1,
                }}
                onClick={async () => {
                  !data.isChecked && updateIsArchived(1, data.id);
                }}
              >
                <MDBox component="img" src={ArchiveLogo} sx={{ ml: "auto", mr: 0 }} />
              </MDBox>
            </Tooltip>
            <Tooltip title="Delete" placement="top-start">
              <MDBox
                onClick={async () => {
                  if (!data.isChecked) {
                    await Modal.confirm({
                      title: "Warning",
                      content: "Are you sure you want to delete this plan?",
                    });
                    await deleteDealPlanProxy(data.id);
                    Message.success("Your deal has been successfully deleted");
                  }
                }}
                component="img"
                src={DeleteLogo}
                sx={{ cursor: !data.isChecked ? "pointer" : "not-allowed" }}
              />
            </Tooltip>
          </MDBox>
        </MDBox>
      ),
    },
  ];

  useLoading(loading);

  return (
    <DashboardLayout backgroundColor={isMobile ? "white" : null}>
      <MDBox>
        <DashboardNavbar absolute isMini />
        <MDBox pb={3} mt={isMobile ? 0 : 10} sx={{ minHeight: "calc(100vh - 120px)" }}>
          {userInfor.stripeSubscriptionType === "canceled" && isWarning && (
            <MDBox
              sx={{
                backgroundColor: "#F09C00",
                display: "flex",
                padding: "6px 0",
                marginBottom: { xs: "20px", md: "40px" },
                position: "relative",
                marginLeft: "-50px",
                marginRight: "-24px",
                marginTop: isMobile ? 0 : "-12px",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <MDBox component="img" src={BlackInforIcon} sx={{ opacity: isMobile ? 0 : 1 }} />
                <MDTypography fontSize={14}>Your subscription has expired</MDTypography>
                <MDButton
                  variant="contained"
                  color="black"
                  sx={{ fontSize: "14px", marginleft: "8px", padding: "6px 8px" }}
                  onClick={() => setOpenRenew(true)}
                >
                  Renew Now
                </MDButton>
              </MDBox>

              <ModalUpdate
                open={openRenew}
                setOpen={setOpenRenew}
                title="Renew Subscription"
                content={
                  <p>
                    Are you sure you want to renew your previous Deal Dashboard subscription?
                    <br />
                    <br />
                    Your renewal will start from today giving you access to your previous deals and
                    the ability to create new deals.
                  </p>
                }
                confirmText="Confirm"
                cancelText="Cancel"
                confirmFunction={resumeSubscription}
              />

              <MDBox
                component="img"
                src={CloseIcon}
                onClick={() => setWarning(false)}
                sx={{
                  position: "absolute",
                  top: "calc(50% - 10px)",
                  right: "20px",
                  cursor: "pointer",
                  display: isMobile ? "none" : "block",
                }}
              />
            </MDBox>
          )}
          <Card sx={{ pt: 3, boxShadow: isMobile ? "none" : null }}>
            <MDBox
              display="flex"
              sx={{ flexWrap: "wrap", rowGap: "12px" }}
              mb={2}
              px={!isMobile ? 3 : 0}
            >
              <MDInput
                value={pageInfo.name}
                onChange={(e) => {
                  updateName(e.target.value);
                  run();
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    fetchDealPlanListProxy();
                  }
                }}
                placeholder="search..."
                size="small"
                sx={{ width: isMobile ? "100%" : "200px" }}
                searchInput
                isMobile={isMobile}
              />
              <MDBox
                sx={{
                  display: "flex",
                  gap: "12px",
                  width: isMobile ? "100%" : "250px",
                  ml: !isMobile ? 1 : 0,
                }}
              >
                <Select
                  value={pageInfo.isArchived}
                  sx={{ height: 37, width: "100%", padding: "5px 20px" }}
                  onChange={(e) => {
                    dealPlanner.updateIsArchivedQueryParams(e.target.value);
                  }}
                >
                  <MenuItem value="all">All Deals</MenuItem>
                  <MenuItem value="false">Active Deals</MenuItem>
                  <MenuItem value="true">Archived Deals</MenuItem>
                </Select>

                <MDButton
                  variant=""
                  loading={loading}
                  disabled={loading}
                  sx={{
                    border: "1px solid #004225",
                    borderRadius: "6px",
                    padding: "5px 20px",
                    color: "#004225 !important",
                    fontWeight: "bold",
                    fontSize: "14px",
                    width: "100%",
                  }}
                  onClick={() => fetchDealPlanListProxy()}
                >
                  Search
                </MDButton>
              </MDBox>

              <MDButton
                variant="nothing"
                disabled={userInfor.stripeSubscriptionType === "canceled"}
                sx={{
                  ml: "auto",
                  backgroundColor:
                    userInfor.stripeSubscriptionType === "canceled" ? "#757575" : "#004225",
                  padding: "5px 20px",
                  gap: "10px",
                  display: isMobile ? "none" : "inline-flex",
                  ...(isMobile && {
                    position: "sticky",
                    bottom: "30px",
                  }),
                }}
                onClick={() => {
                  // empty current plan data
                  resetDealPlanState();
                  navigate("/create-a-deal");
                }}
              >
                <MDBox component="img" src={AddDealLogo} />
                <MDTypography sx={{ color: "#ffffff", fontSize: "14px", fontWeight: "bold" }}>
                  Add deal
                </MDTypography>
              </MDButton>
            </MDBox>
            <Table
              isDeal
              sx={{ overFlowX: isMobile ? "none" : "auto", ...(isMobile && { minHeight: "65vh" }) }}
              px={isMobile ? 0 : 3}
              columns={isMobile ? columnsMobile : columns}
              data={dataSource}
              pagination={pageInfo}
              updatePageInfo={updatePageInfo}
              onTableChange={fetchDealPlanListProxy}
            />
          </Card>
        </MDBox>
        {isMobile && (
          <MDButton
            variant="nothing"
            disabled={userInfor.stripeSubscriptionType === "canceled"}
            sx={{
              ml: "auto",
              backgroundColor:
                userInfor.stripeSubscriptionType === "canceled" ? "#757575" : "#004225",
              padding: "5px 20px",
              gap: "10px",
              width: "100%",
              height: "56px",
              mt: "24px",
              boxShadow: "0px 8px 18px 0px rgba(0, 0, 0, 0.40)",
              ...(isMobile && {
                position: "sticky",
                bottom: "41px",
                zIndex: "1000",
              }),
            }}
            onClick={() => {
              // empty current plan data
              resetDealPlanState();
              navigate("/create-a-deal");
            }}
          >
            <MDBox component="img" src={AddDealLogo} />
            <MDTypography
              sx={{
                color: "#ffffff",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Add deal
            </MDTypography>
          </MDButton>
        )}
        <Footer />
      </MDBox>
    </DashboardLayout>
  );
}

export default DataTables;
