import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { dataPolicies } from "pages/policy-page/constants/data";
import TermItem from "pages/terms-page/components/TermItem";

function Main() {
  return (
    <MDBox p={60 / 8}>
      <MDBox>
        <MDTypography
          fontWeight="medium"
          fontSize={{ xs: 24, md: 36, lg: 54 }}
          sx={{ color: "#282828" }}
        >
          DEAL DASHBOARD PRIVACY POLICY
        </MDTypography>

        <MDTypography fontWeight="regular" fontSize={14} sx={{ color: "#282828" }}>
          Version 1.0 (15 March 2024)
          <br />
          <br />
        </MDTypography>

        <MDTypography fontWeight="regular" fontSize={14} sx={{ color: "#282828" }}>
          This Privacy Policy applies to all personal information collected by via the Scaling
          website located at <a href="/">www.dealdashboard.com.au </a>
          <br />
          <br />
        </MDTypography>

        {dataPolicies.map((policy) => (
          <TermItem title={policy.title} childs={policy.childs} />
        ))}
      </MDBox>
    </MDBox>
  );
}

export default Main;
