import { Alert, Box, Slide, Snackbar } from "@mui/material";
import { useInject } from "../../store";
// import MDBox from "../MDBox";
// import MDAlert from "../MDAlert";

const mt50 = {
  marginTop: 50,
};
const alertStyle = {
  marginTop: 20,
};

const position = {
  vertical: "top",
  horizontal: "center",
};

function Message() {
  const [message] = useInject("message");
  return (
    <Snackbar anchorOrigin={position} style={mt50} open={message.state.length !== 0}>
      <Box flexDirection="column">
        {message.state.map((ti) => (
          <Slide
            key={ti.id}
            direction="left"
            in={ti.show}
            mountOnEnter
            unmountOnExit
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Alert
              style={alertStyle}
              variant="filled"
              severity={ti.type}
              sx={{ marginTop: { xs: "70px !important" } }}
            >
              {ti.text}
            </Alert>
          </Slide>
        ))}
      </Box>
    </Snackbar>
  );
}

export default Message;
