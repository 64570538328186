/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import Stack from "@mui/material/Stack";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { downLoadFile } from "http/downloadFile";
import { useEffect } from "react";
import { useFlatInject } from "store";

function InvoiceList({ isMobile }) {
  // get current user Payment status, expired date, etc
  const [user] = useFlatInject("user");
  const { user: userObject, listInvoices } = user;
  const { invoices } = user.user;
  useEffect(() => {
    listInvoices();
  }, [user.paymentStatus, user.user.vip, user.endAt, user.user.canceledAt]);

  const downloadAll = async () => {
    await downLoadFile({ url: "/payment/invoices/download", name: "ListAllInvoices" });
  };

  return (
    <Card id="invoices" sx={{ boxShadow: "none" }}>
      <MDBox p={3} lineHeight={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        {!isMobile ? (
          <MDBox mb={1}>
            <MDTypography fontWeight="normal" fontSize={24} sx={{ color: "#282828" }}>
              Invoices
            </MDTypography>
          </MDBox>
        ) : null}

        <MDButton
          color="green"
          variant="outlined"
          sx={{
            padding: "5px 20px",
            fontSize: "14px",
            height: "fit-content",
          }}
          onClick={downloadAll}
        >
          Download all
        </MDButton>
      </MDBox>

      {invoices?.map((invoice) => (
        <MDBox pb={3} px={3} sx={{ overflow: "auto" }} key={invoice.id}>
          <Stack direction="column">
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <MDTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                sx={{ color: "#282828", fontSize: "14px" }}
              >
                {`number: ${invoice.number}`}
              </MDTypography>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                <a
                  href={invoice.invoice_pdf}
                  style={{
                    color: "#282828",
                    hover: "black",
                  }}
                >
                  Download invoice
                </a>
              </MDTypography>
            </Stack>
          </Stack>
        </MDBox>
      ))}
    </Card>
  );
}

export default InvoiceList;
