import { buffer, debounceTime, fromEvent, interval, Subject, switchMap } from "rxjs";
import { filter, map, pairwise } from "rxjs/operators";

/**
 * when window is active from backstage
 */
export const windowActive = interval(1000).pipe(
  map(() => document.visibilityState),
  pairwise(),
  filter((v) => v[0] === "hidden" && v[1] === "visible")
);

/**
 * create a triple click listener
 * @param ele
 */
export const createTripleClickListener = (ele) => {
  const click = fromEvent(ele, "click");
  return click.pipe(
    buffer(click.pipe(debounceTime(300))),
    filter((v) => v.length > 2)
  );
};

export const createSelectListener = (ele) => {
  const md = fromEvent(ele, "mousedown");
  return md.pipe(
    switchMap(() => fromEvent(ele, "mousemove").pipe(switchMap(() => fromEvent(ele, "mouseup"))))
  );
};

/**
 * @type {Subject<{message: string; type: 'success'|'info'|'warning'|'error', duration?: number}>}
 */
export const messageSubject = new Subject();
