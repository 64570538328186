import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import brandingLogo from "assets/images/small-logos/branding-logo-yellow.svg";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const isAuth = localStorage.getItem("token");
  const redirectSignup = () => {
    navigate("/sign-up");
  };
  const redirectSignin = () => {
    navigate("/sign-in");
  };
  const redirectHome = () => {
    navigate("/");
  };
  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        padding: "68px",
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          rowGap: "12px",
        }}
      >
        <MDBox
          onClick={redirectHome}
          sx={{ cursor: "pointer" }}
          component="img"
          src={brandingLogo}
          height={50}
        />
        {!isAuth && (
          <MDBox sx={{ display: "flex", gap: "12px", height: "fit-content" }}>
            <MDButton
              onClick={redirectSignup}
              variant="contained"
              color="green"
              size="large"
              sx={{ padding: "5px 20px" }}
            >
              Sign up
            </MDButton>
            <MDButton
              onClick={redirectSignin}
              variant="outlined"
              color="green"
              size="large"
              sx={{
                padding: "5px 20px",
                background: "#ffffff !important",
                border: "1px solid #004225 !important",
              }}
            >
              Login
            </MDButton>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

export default Header;
