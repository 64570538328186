import Accordion from "@mui/material/Accordion";
import { styled } from "@mui/material";

export default styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  border: "none",
  borderRadius: "8px",
  "&:before": {
    display: "none",
  },
  ".MuiAccordionDetails-root": {
    paddingLeft: "12px",
    paddingRight: "8px",
  },
  ".MuiAccordionSummary-root": {
    padding: "4px 8px 4px 12px",
  },
}));
