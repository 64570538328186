/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate } from "react-router-dom";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import { isEmpty } from "lodash";
import { useAutosave, useHttp } from "utils/hooks";
import { Message } from "utils/message";

// Data
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import { useEffect, useState } from "react";
import { useFlatInject } from "store";
import { submitAutoSave } from "utils/common";

const initValue = () => ({
  note: "",
});

function ConclusiveNotes({ isMobile, setTextAutoSave }) {
  const [{ dealReview, saveConclusiveNotes, conclusiveNotes, baseInfo }] =
    useFlatInject("dealPlanOperation");
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(
    isEmpty(conclusiveNotes)
      ? initValue()
      : {
          ...initValue(),
          ...conclusiveNotes,
        }
  );
  const submitForm = async () => {
    await saveConclusiveNotes(value);
    Message.success("Changes saved");
  };
  const submitFormAuto = async () => {
    await saveConclusiveNotes(value);
  };

  const { run, loading } = useHttp(submitForm, { manual: true });
  const { run: runAuto } = useHttp(submitFormAuto, { manual: true });

  useEffect(() => {
    setValue({ ...initValue(), ...conclusiveNotes });
  }, [conclusiveNotes]);

  useAutosave(async () => {
    if (baseInfo?.id) {
      submitAutoSave(() => runAuto(), setTextAutoSave);
    }
  });

  return (
    <Card id="second-deal-pitch" sx={{ overflow: "visible", boxShadow: isMobile ? "none" : null }}>
      {!isMobile && (
        <MDBox p={3}>
          <MDTypography variant="h4">Deal Repitch notes</MDTypography>
        </MDBox>
      )}
      <MDBox pb={3} px={isMobile ? 0 : 3}>
        <Stack direction="column" spacing={2} className="no-print">
          <MDEditor
            sx={{
              width: "100%",
            }}
            value={value.note}
            onChange={(e) => {
              setValue({ ...value, note: e });
            }}
          />
          <MDBox pb={3} py={3} className="no-print">
            <Grid
              item
              xs={12}
              textAlign="right"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <MDButton
                color="green"
                variant="outlined"
                onClick={() => navigate("/deal-planner", { replace: true })}
                sx={{
                  mr: 1,
                  fontSize: "14px",
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                color="green"
                disabled={loading || !dealReview.businessName}
                loading={loading}
                onClick={() => run()}
                sx={{
                  border: "1px solid #004225",
                  fontSize: "14px",
                }}
              >
                Save and next
              </MDButton>
            </Grid>
          </MDBox>
        </Stack>
      </MDBox>
    </Card>
  );
}

export default ConclusiveNotes;
