import { useForm, Form, FormItem } from "components/Form";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { useEffect, useState, createRef } from "react";
import { Message } from "utils/message";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useFlatInject, useInject } from "store";
import Loading from "components/Loading";
import { useLocation } from "react-router-dom";
import { getToken } from "utils/auth";
import { useMaterialUIController } from "utils/hooks";
import CircularProgress from "@mui/material/CircularProgress";

function MainVerifyEmail() {
  const [user] = useInject("user");
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:480px)");
  const token = getToken();

  const [windowResize, setWindowResize] = useState(window.innerWidth);
  useEffect(() => {
    function handleMiniSidenav() {
      setWindowResize(window.innerWidth);
    }
    window.addEventListener("resize", handleMiniSidenav);
    // A function that sets the mini state of the sidenav.
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [location]);

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pb={8}
      sx={{
        ...(windowResize < 1600 &&
          windowResize > 1200 &&
          token &&
          !miniSidenav && { marginLeft: "250px" }),
      }}
    >
      <MDBox
        p={5}
        pb={10}
        sx={{
          background: "#fff",
          gap: "40px",
          borderRadius: "20px",
          ...(isMobile && { height: "100%" }),
        }}
        display="flex"
        flexDirection="column"
      >
        <MDTypography sx={{ fontSize: "24px", fontWeight: "500", color: "#282828" }}>
          Verify your email
        </MDTypography>
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <CircularProgress disableShrink />
        </MDBox>
        <Loading />
      </MDBox>
    </MDBox>
  );
}

export default MainVerifyEmail;
