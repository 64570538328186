import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";

export default styled(Radio)(({ theme, ownerState }) => {
  const { palette, functions } = theme;
  const { color, disabled } = ownerState;

  const { transparent, gradients } = palette;
  const { linearGradient, pxToRem } = functions;

  return {
    "& .MuiSvgIcon-root": {
      color: transparent.main,
    },
    "&:after": {
      backgroundImage: linearGradient(gradients[color].main, gradients[color].main),
      width: pxToRem(12),
      height: pxToRem(12),
    },
    "&:hover": {
      backgroundColor: transparent.main,
    },
    "&.Mui-checked": {
      color: gradients[color].main,
      "& .MuiSvgIcon-root": {
        borderColor: gradients[color].main,
      },
      "&:after": {
        opacity: 1,
      },
    },
    ...(disabled && {
      pointerEvent: "none",
      opacity: 0.65,
    }),
  };
});
