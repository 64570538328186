import { Delete } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, InputAdornment, MenuItem, Select, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import plusIcon from "assets/images/logos/plus.svg";
import MDButton from "components/MDButton";
import dayjs from "dayjs";
import { produce } from "immer";
import { useState } from "react";
import { getCopyText } from "utils/common";
import MDBox from "../../../../../../components/MDBox";
import MDEditor from "../../../../../../components/MDEditor";
import MDInput from "../../../../../../components/MDInput";
import MDTypography from "../../../../../../components/MDTypography";
import { useFlatInject } from "../../../../../../store";

const shareValuesMap = {
  shares: " shares ",
  assets: " assets ",
  sharesAndAssets: " shares & assets ",
  other: "",
};

/**
 *
 * @param {{
 *  mode: 'view'|'edit',
 *  value: {
 *      date: string;
 *      between: string;
 *      sellerCompanyName: string;
 *      sellerCompanyNameShort: string;
 *      theirAddress: string;
 *      yourCompanyName: string;
 *      yourCompanyNameShort: string;
 *      yourCompanyLocation: string;
 *      yourCompanyAddress: string;
 *  }
 *  onChange: (value: {
 *      date: string;
 *      between: string;
 *      sellerCompanyName: string;
 *      sellerCompanyNameShort: string;
 *      theirAddress: string;
 *      yourCompanyName: string;
 *      yourCompanyNameShort: string;
 *      yourCompanyLocation: string;
 *      yourCompanyAddress: string;
 *  }) => any;
 * }} param0
 * @returns
 */

let countOfBuyer = 1;
let countOfSeller = 1;

export default function FinalTermSheetContent({
  mode = "view",
  value = {},
  isMobile,
  onChange,
  ...props
}) {
  const [{ dealBundleWorkSheet, dealReview, dealRework }] = useFlatInject("dealPlanOperation");

  const [sellerCompanyName, setSellerCompanyName] = useState(value?.sellerCompanyName ?? "");

  const preClosingStrategyTable = dealBundleWorkSheet.preClosingStrategy.table.filter(
    (i) => i.yesOrNo === "Y" && i.name
  );
  const gapFundingStrategyTable = dealBundleWorkSheet.gapFundingStrategy.table.filter(
    (i) => i.yesOrNo === "Y" && i.name
  );
  const postClosingStrategyTable = dealBundleWorkSheet.postClosingStrategy.table.filter(
    (i) => i.yesOrNo === "Y" && i.name
  );

  countOfBuyer = value?.numberOfBuyerSigns ?? 1;
  countOfSeller = value?.numberOfSellerSigns ?? 1;

  const noCompanyValue = !value.yourCompanyName || !value.sellerCompanyName;
  // const [numberOfBuyer, setNumberOfBuyer] = useState(value?.numberOfBuyerSigns ?? 1);
  const handleChangeNumberOfBuyer = (number) => {
    onChange?.((pre) => ({ ...pre, numberOfBuyerSigns: pre.numberOfBuyerSigns + number }));
    // setNumberOfBuyer((pre) => pre + number);
    countOfBuyer += 1;
  };

  // const [numberOfSeller, setNumberOfSeller] = useState(value?.numberOfSellerSigns ?? 1);
  const handleChangeNumberOfSeller = (number) => {
    onChange?.((pre) => ({ ...pre, numberOfSellerSigns: pre.numberOfSellerSigns + number }));
    // setNumberOfSeller((pre) => pre + number);
    countOfSeller += 1;
  };

  return (
    <MDBox className="print-content" {...props}>
      <MDTypography variant="body2">
        {/* eslint-disable */}
        <MDBox display="flex" mb={2} alignItems="baseline">
          {mode === "view" ? (
            <MDTypography flex={1} fontSize="1rem" sx={{ wordBreak: "break-word" }}>
              {`This Acknowledgement of the Term Sheet (the "Agreement") is made and effective on ${dayjs(
                value.date
              ).format("DD MMMM YYYY")}.`}
            </MDTypography>
          ) : (
            <>
              <MDBox
                flex="1"
                mr={1}
                sx={{
                  fontSize: {
                    lg: "1rem",
                    md: "1rem",
                    sm: "0.75rem",
                    xs: "0.75rem",
                  },
                  alignSelf: {
                    lg: "auto",
                    md: "auto",
                    sm: "auto",
                    xs: "flex-start",
                  },
                  color: "#282828",
                }}
              >
                This Acknowledgement of the Term Sheet (the "Agreement") is made and effective on
              </MDBox>
              <MDBox
                component={DatePicker}
                label="Select date"
                sx={{
                  width: {
                    lg: 230,
                    md: 230,
                    sm: "auto",
                    xs: "auto",
                  },
                  flex: {
                    lg: "none",
                    md: "none",
                    sm: 1,
                    xs: 1,
                  },
                  "& .MuiFormLabel-root": {
                    marginLeft: "14px",
                    marginTop: isMobile ? "12px" : 0,
                    color: "#282828 !important",
                  },
                  "& .MuiOutlinedInput-input": {
                    color: "#282828",
                  },
                  ...(isMobile && { padding: "10px 0" }),
                }}
                format={"DD MMMM YYYY"}
                value={dayjs(value.date)}
                onChange={(v) =>
                  onChange?.(
                    produce(value, (draft) => {
                      draft.date = v.valueOf();
                    })
                  )
                }
                slotProps={{
                  ...(isMobile && {
                    textField: {
                      // size: 'small',
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end" sx={{ color: "#282828" }}>
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }),
                }}
              />
            </>
          )}
        </MDBox>
        <MDBox sx={{ padding: isMobile ? "0 8px" : "0px" }}>
          <MDBox
            display="flex"
            mb={2}
            alignItems="baseline"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: {
                lg: "nowrap",
                md: "nowrap",
                sm: "nowrap",
                xs: "wrap",
              },
            }}
          >
            <MDBox
              flex="none"
              mr={1}
              sx={{ minWidth: "85px", color: "#282828", fontSize: "18px", fontWeight: 500 }}
            >
              {`BETWEEN${isMobile ? "" : ":"}`}
            </MDBox>

            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                flex: {
                  lg: 1,
                  md: 1,
                  sm: "0 0 100%",
                  xs: "0 0 100%",
                },
              }}
            >
              {mode === "view" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <MDBox sx={{ wordBreak: "break-word" }}>
                    {`${value.yourCompanyName}, a corporation organised and existing under the laws of ${value.yourCompanyLocation}, with its head office address located at ${value.yourCompanyAddress}`}
                  </MDBox>
                </Box>
              ) : (
                <>
                  <Stack
                    direction="row"
                    spacing={isMobile ? 0 : 2}
                    sx={{
                      flexWrap: {
                        lg: "nowrap",
                        md: "nowrap",
                        sm: "nowrap",
                        xs: "wrap",
                      },
                    }}
                  >
                    <MDBox
                      component={MDInput}
                      placeholder="Buyer's Company Name"
                      width="100%"
                      value={value.yourCompanyName}
                      onChange={(e) =>
                        onChange?.(
                          produce(value, (draft) => {
                            draft.yourCompanyName = e.target.value;
                          })
                        )
                      }
                      sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                          color: "#757575",
                        },
                        "& .MuiInputBase-root": {
                          "& .MuiInputBase-input": {
                            color: "#282828",
                          },
                        },
                      }}
                    />
                    {/* <MDBox
                    component={MDInput}
                    placeholder="Abbreviated Buyer's Company Name"
                    width="100%"
                    value={value.yourCompanyNameShort}
                    onChange={(e) =>
                      onChange?.(
                        produce(value, (draft) => {
                          draft.yourCompanyNameShort = e.target.value;
                        })
                      )
                    }
                  /> */}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="baseline"
                    spacing={isMobile ? 0 : 2}
                    sx={{
                      flexWrap: {
                        lg: "nowrap",
                        md: "nowrap",
                        sm: "nowrap",
                        xs: "wrap",
                      },
                    }}
                  >
                    <MDBox
                      flex={1}
                      ml={1}
                      sx={{
                        fontSize: {
                          lg: "1rem",
                          md: "1rem",
                          sm: "0.75rem",
                          xs: "0.75rem",
                        },
                        color: "#282828",
                      }}
                    >{`a corporation organised and existing under the laws of`}</MDBox>
                    <MDBox
                      component={MDInput}
                      placeholder="Buyer's Company Location .eg: Victoria"
                      width="calc(50% - 8px)"
                      value={value.yourCompanyLocation}
                      onChange={(e) =>
                        onChange?.(
                          produce(value, (draft) => {
                            draft.yourCompanyLocation = e.target.value;
                          })
                        )
                      }
                      sx={{
                        flex: {
                          lg: 1,
                          md: 1,
                          sm: "0 0 100%",
                          xs: "0 0 100%",
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                          color: "#757575",
                        },
                        "& .MuiInputBase-root": {
                          "& .MuiInputBase-input": {
                            color: "#282828",
                          },
                        },
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="baseline"
                    spacing={isMobile ? 0 : 2}
                    sx={{
                      flexWrap: {
                        lg: "nowrap",
                        md: "nowrap",
                        sm: "nowrap",
                        xs: "wrap",
                      },
                    }}
                  >
                    <MDBox
                      flex={1}
                      ml={1}
                      sx={{
                        fontSize: {
                          lg: "1rem",
                          md: "1rem",
                          sm: "0.75rem",
                          xs: "0.75rem",
                        },
                      }}
                    >{`with its head office address located at`}</MDBox>
                    <MDBox
                      component={MDInput}
                      placeholder="Buyer's Company Address"
                      width="calc(50% - 8px)"
                      value={value.yourCompanyAddress}
                      onChange={(e) =>
                        onChange?.(
                          produce(value, (draft) => {
                            draft.yourCompanyAddress = e.target.value;
                          })
                        )
                      }
                      sx={{
                        flex: {
                          lg: 1,
                          md: 1,
                          sm: "0 0 100%",
                          xs: "0 0 100%",
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                          color: "#757575",
                        },
                        "& .MuiInputBase-root": {
                          "& .MuiInputBase-input": {
                            color: "#282828",
                          },
                        },
                      }}
                    />
                  </Stack>
                </>
              )}
            </Box>
          </MDBox>
          <MDBox
            display="flex"
            mb={2}
            alignItems="baseline"
            marginTop="1rem"
            sx={{
              flexWrap: {
                lg: "nowrap",
                md: "nowrap",
                sm: "nowrap",
                xs: "wrap",
              },
            }}
          >
            <MDBox
              flex="none"
              mr={1}
              sx={{ minWidth: "85px", color: "#282828", fontSize: "18px", fontWeight: 500 }}
            >
              {`AND${isMobile ? "" : ":"}`}
            </MDBox>
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                flex: {
                  lg: 1,
                  md: 1,
                  sm: "0 0 100%",
                  xs: "0 0 100%",
                },
              }}
            >
              {mode === "view" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <MDBox>{`${value.sellerCompanyName}, individuals with office or residence at ${value.theirAddress}`}</MDBox>
                </Box>
              ) : (
                // <Stack direction="column" width={"100%"}>
                <>
                  <Stack
                    direction="row"
                    spacing={isMobile ? 0 : 2}
                    alignItems={"baseline"}
                    sx={{
                      width: "100%",
                      // marginBottom: "0.7rem",
                      flexWrap: {
                        lg: "nowrap",
                        md: "nowrap",
                        sm: "nowrap",
                        xs: "wrap",
                      },
                    }}
                  >
                    <MDBox
                      component={MDInput}
                      placeholder="Seller's Company Name"
                      flex={1}
                      value={value.sellerCompanyName}
                      onChange={(e) =>
                        onChange?.(
                          produce(value, (draft) => {
                            draft.sellerCompanyName = e.target.value;
                            setSellerCompanyName(e.target.value);
                          })
                        )
                      }
                      sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                          color: "#757575",
                        },
                        "& .MuiInputBase-root": {
                          "& .MuiInputBase-input": {
                            color: "#282828",
                          },
                        },
                      }}
                    />
                    {/* <MDBox flex="none" mx={1}>
                    the{" "}
                  </MDBox> */}
                    {/* <MDBox
                    component={MDInput}
                    placeholder="Abbreviated Seller's Company Name"
                    flex={1}
                    value={value.sellerCompanyNameShort}
                    onChange={(e) =>
                      onChange?.(
                        produce(value, (draft) => {
                          draft.sellerCompanyNameShort = e.target.value;
                        })
                      )
                    }
                  /> */}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="baseline"
                    sx={{
                      flexWrap: {
                        lg: "nowrap",
                        md: "nowrap",
                        sm: "nowrap",
                        xs: "wrap",
                      },
                    }}
                  >
                    <MDBox
                      flex="none"
                      mx={1}
                      sx={{
                        fontSize: {
                          lg: "1rem",
                          md: "1rem",
                          sm: "0.75rem",
                          xs: "0.75rem",
                        },
                      }}
                    >
                      individuals with residence at{" "}
                    </MDBox>
                    {/* <MDBox flex={1} borderBottom="1px solid #344767"></MDBox> */}

                    <MDBox
                      component={MDInput}
                      placeholder="Seller's Company Address"
                      flex={1}
                      value={value.theirAddress}
                      onChange={(e) =>
                        onChange?.(
                          produce(value, (draft) => {
                            draft.theirAddress = e.target.value;
                          })
                        )
                      }
                      sx={{
                        flex: {
                          lg: 1,
                          md: 1,
                          sm: "0 0 100%",
                          xs: "0 0 100%",
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                          color: "#757575",
                        },
                        "& .MuiInputBase-root": {
                          "& .MuiInputBase-input": {
                            color: "#282828",
                          },
                        },
                      }}
                    />
                  </Stack>
                </>
                // </Stack>
              )}
            </Box>
          </MDBox>
          <MDBox
            mb={2}
            sx={{
              fontSize: {
                lg: "1rem",
                md: "1rem",
                sm: "0.75rem",
                xs: "0.75rem",
              },
              color: "#282828",
            }}
          >
            {`NOW, THEREFORE, in consideration of the mutual agreements and covenants herein
              contained the Partners, hereby acknowledges that it has been retained by purchaser ${value.yourCompanyNameShort} for
              purposes of:`}
          </MDBox>

          <MDBox mb={2} fontWeight="bold" alignItems="baseline">
            <MDBox
              fontWeight="bold"
              display="flex"
              alignItems={mode !== "view" ? "center" : "baseline"}
              gap={1}
            >
              <MDTypography
                // fontWeight="bold"
                sx={{
                  fontWeight: {
                    lg: 700,
                    md: 700,
                    sm: 700,
                    xs: 600,
                  },
                  fontSize: {
                    lg: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "14px",
                  },
                  color: "#282828",
                }}
              >
                Purchasing
              </MDTypography>
              {mode !== "view" ? (
                <Select
                  value={value.shares}
                  onChange={(e) => {
                    onChange?.(
                      produce(value, (draft) => {
                        draft.shares = e.target.value;
                      })
                    );
                  }}
                  sx={{
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                    ...(isMobile && { width: "100%" }),
                    "& .MuiSelect-select": {
                      color: "#282828",
                    },
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  <MenuItem value="shares">Shares</MenuItem>
                  <MenuItem value="assets">Assets</MenuItem>
                  <MenuItem value="sharesAndAssets">Shares & assets</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              ) : (
                shareValuesMap[value.shares]
              )}
              <MDBox>
                {mode !== "view"
                  ? value.shares === "other" && (
                      <MDInput
                        value={value.otherShares}
                        onChange={(e) =>
                          onChange?.(
                            produce(value, (draft) => {
                              draft.otherShares = e.target.value;
                            })
                          )
                        }
                        sx={{ marginRight: "8px" }}
                      />
                    )
                  : ` ${value.otherShares} `}
              </MDBox>
              <MDTypography
                // fontWeight="bold"
                sx={{
                  fontWeight: {
                    lg: 700,
                    md: 700,
                    sm: 700,
                    xs: 600,
                  },
                  fontSize: {
                    lg: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "14px",
                  },
                  color: "#282828",
                }}
              >
                in
              </MDTypography>
              {!isMobile && (
                <MDTypography
                  fontWeight="bold"
                  fontSize="16px"
                  ml={1}
                  sx={{ wordBreak: "break-word" }}
                >
                  {value?.sellerCompanyName}
                </MDTypography>
              )}
            </MDBox>
            {isMobile && (
              <MDTypography
                fontWeight="bold"
                fontSize="16px"
                ml={1}
                sx={{
                  fontWeight: {
                    lg: 700,
                    md: 700,
                    sm: 700,
                    xs: 600,
                  },
                  fontSize: {
                    lg: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "14px",
                  },
                  color: "#282828",
                }}
              >
                {value?.sellerCompanyName}
              </MDTypography>
            )}
          </MDBox>

          <MDBox
            mb={2}
            sx={{
              fontSize: {
                lg: "1rem",
                md: "1rem",
                sm: "0.75rem",
                xs: "0.75rem",
              },
              color: "#282828",
            }}
          >
            In consideration of the foregoing both parties agree as follows:
          </MDBox>
          <MDBox mb={2} sx={{ fontWeight: 500, fontSize: "18px", color: "#282828" }}>
            OVERALL TERMS:
            <MDBox className="extra-buttons-deal-repitch no-print">
              <MDButton
                color="green"
                onClick={() => {
                  const res = getCopyText(dealRework);
                  onChange?.((pre) => ({ ...pre, overallTerms: res }));
                }}
                sx={{
                  border: "1px solid #004225",
                  fontSize: "14px",
                }}
              >
                Populate from Notes
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox
            mb={2}
            ml={isMobile ? 0 : 2}
            sx={{
              ".ql-editor.ql-blank::before": {
                color: "#b3b6b9",
              },
            }}
          >
            {mode !== "view" ? (
              <MDEditor
                placeholder="Each term here in bullet points"
                value={value.overallTerms}
                onChange={(e) => {
                  onChange?.(
                    produce(value, (draft) => {
                      draft.overallTerms = e;
                    })
                  );
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: value.overallTerms }}
                style={{
                  marginLeft: "3rem",
                }}
              ></div>
            )}
            {/* <MDBox fontWeight="bold">PRE-CLOSING STRATEGY</MDBox>
          {preClosingStrategyTable.map((item, index) => (
            <MDBox key={item.name}>
              {index + 1}) {item.name?.labela\
            </MDBox>
          ))}
          <br />
          <MDBox fontWeight="bold">GAP FUNDING STRATEGY</MDBox>
          {gapFundingStrategyTable.map((item, index) => (
            <MDBox key={item.name}>
              {index + 1}) {item.name?.label}
            </MDBox>
          ))}
          <br />
          <MDBox fontWeight="bold">POST CLOSING STRATEGY</MDBox>
          {postClosingStrategyTable.map((item, index) => (
            <MDBox key={item.name}>
              {index + 1}) {item.name?.label}
            </MDBox>
          ))} */}
          </MDBox>
          {/* <MDBox mb={6}>
          &copy; Copyright Envision Corporation. 2002. All rights reserved. Protected by the
          copyright laws of the united States and Canada and by international treaties. IT IS
          ILLEGAL AND STRICTLY PROHIBITED TO DISTRIBUTE, PUBLISH, OFFER FOR SALE, LICENCE OR
          SUBLICENSE, GIVE OR DISCLOSE TO ANY OTHER PARTY, THIS PRODUCT IN HARD COPY OR DIGITAL
          FORM. ALL OFFENDERS WILL AUTOMATICALLY BE SUED IN A COURT OF LAW.
        </MDBox> */}
          {/* <MDBox mb={6}>
          Upon signing, {value.sellerCompanyNameShort} agrees to help in any and all requests from{" "}
          {value.yourCompanyNameShort} for the Due Diligence period and to provide all information,
          to be accurate to the best of the Director's knowledge, in a timely manner.
        </MDBox> */}
          <MDBox
            mb={2}
            sx={{
              fontSize: {
                lg: "1rem",
                md: "1rem",
                sm: "0.75rem",
                xs: "0.75rem",
              },
              color: "#282828",
            }}
          >
            IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the date
            above.
          </MDBox>

          <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={2} gap={2}>
              {mode === "view" ? (
                <MDTypography fontWeight="bold" fontSize="medium" sx={{ wordBreak: "break-word" }}>
                  {value.yourCompanyName}
                </MDTypography>
              ) : (
                <MDTypography fontWeight="bold" fontSize="medium" sx={{ wordBreak: "break-word" }}>
                  {value.yourCompanyName}
                </MDTypography>
              )}
              {Array(countOfBuyer)
                .fill(0)
                .map((__val, idx) => (
                  <Stack key={idx} direction="column" spacing={2} gap={2}>
                    <MDBox
                      mb={6}
                      borderBottom="1px solid #344767"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      Authorised Signature:
                      {idx > 0 && (
                        <MDBox className="extra-buttons-deal-repitch no-print">
                          <IconButton
                            sx={{ padding: "0px !important" }}
                            onClick={() => handleChangeNumberOfBuyer(-1)}
                          >
                            <Delete />
                          </IconButton>
                        </MDBox>
                      )}
                    </MDBox>
                    <MDBox display="flex" mb={6} sx={{ gap: isMobile ? "32px" : "200px" }}>
                      <MDBox borderBottom="1px solid #344767" flex={1}>
                        Name:
                      </MDBox>
                      <MDBox borderBottom="1px solid #344767" flex={1}>
                        Date:
                      </MDBox>
                    </MDBox>
                  </Stack>
                ))}
              <MDBox className="extra-buttons-deal-repitch no-print">
                <MDButton
                  disabled={countOfBuyer >= 3}
                  color="green"
                  variant="outlined"
                  onClick={() => handleChangeNumberOfBuyer(1)}
                >
                  <MDBox component="img" src={plusIcon} /> &nbsp;Add more
                </MDButton>
              </MDBox>
            </Stack>
            <Stack direction="column" spacing={2} gap={2}>
              {mode === "view" ? (
                <MDTypography fontWeight="bold" fontSize="medium" sx={{ wordBreak: "break-word" }}>
                  {value.sellerCompanyName}
                </MDTypography>
              ) : (
                <MDTypography fontWeight="bold" fontSize="medium" sx={{ wordBreak: "break-word" }}>
                  {value.sellerCompanyName}
                </MDTypography>
              )}

              {Array(countOfSeller)
                .fill(0)
                .map((__val, idx) => (
                  <Stack key={idx} direction="column" spacing={2} gap={2}>
                    <MDBox
                      mb={6}
                      gap={10}
                      borderBottom="1px solid #344767"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      Authorised Signature:
                      {idx > 0 && (
                        <MDBox className="extra-buttons-deal-repitch no-print">
                          <IconButton
                            sx={{ padding: "0px !important" }}
                            onClick={() => handleChangeNumberOfSeller(-1)}
                          >
                            <Delete />
                          </IconButton>
                        </MDBox>
                      )}
                    </MDBox>
                    <MDBox display="flex" mb={6} sx={{ gap: isMobile ? "32px" : "200px" }}>
                      <MDBox borderBottom="1px solid #344767" flex={1}>
                        Name:
                      </MDBox>
                      <MDBox borderBottom="1px solid #344767" flex={1}>
                        Date:
                      </MDBox>
                    </MDBox>
                  </Stack>
                ))}
              <MDBox className="extra-buttons-deal-repitch no-print">
                <MDButton
                  disabled={countOfSeller >= 3}
                  color="green"
                  variant="outlined"
                  onClick={() => handleChangeNumberOfSeller(1)}
                >
                  <MDBox component="img" src={plusIcon} /> &nbsp;Add more
                </MDButton>
              </MDBox>
            </Stack>
          </Stack>
        </MDBox>
      </MDTypography>
    </MDBox>
  );
}
