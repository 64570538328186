export class HttpError extends Error {
  code;
  constructor(message, code) {
    super(message);
    this.code = code;
  }
  toString() {
    return `code: ${this.code}\n${super.toString()}`;
  }
}
