import { createDeepConvertor } from "convert-key";
import { http } from "http";
import { pick, unionBy } from "lodash";
import { withAPI } from "natur-immer";

const convertDealReview = createDeepConvertor({
  productAndServiceOverview: "productOrServiceOverview",
  industryMultiplie: "industryMultiple",
  businessChallenge: "whatsChallengingAboutTheBusiness",
  avgMonthlyTurnOver: "aVGMonthlyTurnOver",
  avgMonthlyExpenses: "aVGMonthlyExpenses",
  avgMonthlyProfit: "aVGMonthlyProfit",
  impactToEmpire: "impacttoEmpire",
  motivationToSell: "motivationtoSell",
  turnoverMonthlyAvg: "turnoverMonthlyAVG",
  recrringMonthlyAvg: "recrringMonthlyAVG",
  customerDbTotal: "customerDatabaseTotal",
  prospectDbTotal: "prospectDatabaseTotal",
});

const convertDealAnalysis = createDeepConvertor({
  cashOnHand: "cashOnhand",
});
const createDealPlan = (data) => http.put("/dealplans", data);
const updateDealPlan = (data) => http.patch(`/dealplan/${data.id}`, data);

const saveDealPlan = (data) => {
  if (!data.id) {
    return createDealPlan(data);
  }
  return updateDealPlan(data);
};

/**
 *
 * @param {*} arr {{label: string; key: string}[]}
 * @returns {{label: string; key: string; yesOrNo: 'Y'|'N'; values: string; notes: string}[]}
 */
const genTable = (arr, defaultValue = false) => {
  const listDefaultYes = [
    "askPrice",
    "profit",
    "aksMultiple",
    "turnoverAnnual",
    "turnoverMonthlyAvg",
    "customerDbTotal",
  ];
  return arr.map((i) => ({
    ...i,
    yesOrNo: listDefaultYes.includes(i.key) ? "Y" : null,
    value: defaultValue ? "0" : "",
    notes: "",
  }));
};
const initState = () => ({
  baseInfo: {
    id: null,
  },
  idCardOpen: "deal-review",
  printSelection: "v1",
  dealReview: {
    businessName: "",
    productAndServiceOverview: "",
    industryInfo: "",
    timing: "",
    industryMultiplie: "",
    connectionInIndustry: "",
    impactToEmpire: "",
    motivationToSell: "",
    theirPersonalStory: "",
    theBusinessStory: "",
    yearsInBusiness: "",
    ownerIsA: "",
    numberOfTeam: null,
    goingConcern: "",
    goingConcernTrigger: "",
    businessChallenge: "",
    avgMonthlyTurnOver: 0,
    avgMonthlyExpenses: 0,
    avgMonthlyProfit: 0,
    timeFramesSell: "",
    anyOtherLeveragePoints: "",
    dealReviewNotes: "",
  },
  dealAnalysis: {
    businessOverviewForm: genTable(
      [
        {
          label: "Cash on hand",
          key: "cashOnHand",
          description: " ",
        },
        {
          label: "Accounts receivable",
          key: "aOrR",
        },
        {
          label: "Invested money (term/crypto)",
          key: "investedMoney",
        },
        {
          label: "Security (stocks/bonds)",
          key: "security",
        },
        {
          label: "Stock (parts or products)",
          key: "stock",
        },
        {
          label: "Inventory (ready to sell)",
          key: "inventory",
        },
        {
          label: "Fixtures & fittings",
          key: "fixturesAndFittings",
        },
        {
          label: "Equipment",
          key: "equipment",
        },
        {
          label: "Vehicles",
          key: "vehicles",
        },

        {
          label: "Real Estate",
          key: "realEstate",
        },
      ],
      true
    ),
    dealOverviewForm: genTable(
      [
        {
          label: "Ask Price",
          key: "askPrice",
        },
        {
          label: "EBITDA / profit",
          key: "profit",
        },
        {
          label: "Ask multiple",
          key: "aksMultiple",
        },
        {
          label: "Actual Annual Turnover",
          key: "turnoverAnnual",
        },
        {
          label: "Turnover monthly avg.",
          key: "turnoverMonthlyAvg",
        },
        {
          label: "Avg. monthly profit",
          key: "avgMonthlyProfit",
        },
        {
          label: "Recurring monthly avg. (if any)",
          key: "recrringMonthlyAvg",
        },
        {
          label: "Customer database total (buyers)",
          key: "customerDbTotal",
        },
        {
          label: "Customer pipeline value",
          key: "customerPipeline",
        },
        {
          label: "Prospect database total (non-buyers)",
          key: "prospectDbTotal",
        },
        {
          label: "A/P (outstanding to Pay)",
          key: "aOrP",
        },
        {
          label: "IP owned",
          key: "ipOwned",
        },
        {
          label: "Social media presence/following",
          key: "socialMediaFollowing",
        },
        {
          label: "Capacity - more output potential",
          key: "capacity",
        },
        {
          label: "Under management",
          key: "underManagement",
        },
        {
          label: "Reasons for selling",
          key: "reasonsForSelling",
        },
      ],
      true
    ),
    dealNotes: "",
  },
  dealBundleWorkSheet: {
    askPrice: null,
    ebitda: "",
    askMultiple: "",
    preClosingStrategy: {
      goalPurchasePrice: 0,
      goalMultiple: "",
      table: [
        {
          name: "",
          yesOrNo: "",
          value: null,
          notes: "",
        },
      ],
      notes: "",
    },
    gapFundingStrategy: {
      goalPurchasePrice: 0,
      dealBundleValue: 0,
      gapLeft: 0,
      table: [
        {
          name: "",
          yesOrNo: "",
          value: null,
          notes: "",
        },
      ],
      notes: "",
    },
    postClosingStrategy: {
      goalPurchasePrice: 0,
      dealBundleValue: 0,
      gapLeft: 0,
      table: [
        {
          name: "",
          yesOrNo: "",
          value: null,
          notes: "",
        },
      ],
      notes: "",
    },
    dealNotes: {
      notes: "",
      bizImpact: "",
      netValueAdded: null,
      annualPositiveCashAdded: null,
      fullyFundedOrPartiallyFunded: null,
    },
  },
  dealBundleWorkSheetRunningCache: {
    dealBundleValue: {
      preClosingStrategy: 0,
      gapFundingStrategy: 0,
    },
  },
  termSheet: "",
  dealDue: {
    questions: [
      {
        title: "Where the business is conducted through a company",
        children: [
          {
            row: 1,
            name: "Has a company search been done to verify the vendor?	",
            value: "",
          },
          {
            row: 2,
            name: "Are all ASIC compliance requirements up-to-date?",
            value: "",
          },
          {
            row: 3,
            name: "Does the company have any overseas operations?",
            value: "",
          },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },
      {
        title: "Financial Health",
        children: [
          {
            row: 4,
            name: "Have you obtained the last four years financial statements for the business?	",
            value: "",
            tips: " The financial statements should contain a breakdown of: liabilities (inc. contingent liabilities); inventory; accounts receivable and payable.",
          },
          {
            row: 5,
            name: "Have you obtained the business capital structure and, where possible, a current list of vested interests (such as shares, options, warrants, etc) and outstanding debt instruments?	",
            value: "",
          },
          {
            row: 6,
            name: "Have you obtained an up-to-date copy of the business' credit report, if available?	",
            value: "",
          },
          {
            row: 7,
            name: "Has a comparison between the business gross profits and the industry trends been done?",
            value: "",
          },
          {
            row: 8,
            name: "Have you considered the financial projections and major growth drivers of the business in the next four years?	",
            value: "",
          },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },
      {
        title: "Taxation Considerations	",
        children: [
          {
            row: 9,
            name: "Have you obtained the last four year’s tax return, including supporting schedules and workpapers of the business, such as capital allowance schedules, business activities statements, fringe benefits tax return, etc?",
            value: "",
            alerts:
              "Consideration must be given to the duties of a director under the tax law. New directors need take action within 30 days of their appointment if the company has outstanding pay-as-you-go (PAYG) withholding or superannuation contribution amounts, or face the possibility of the becoming personally liable to pay a director penalty.",
          },
          {
            row: 10,
            name: "Have you obtained confirmation that all tax obligations such as income tax, GST, PAYG withholding, stamp duty and payroll tax are up-to-date and paid.	",
            value: "",
          },
          {
            row: 11,
            name: "Have you familiarised yourself with the tax obligations of the entity to be purchased?	",
            value: "",
          },

          {
            row: 12,
            name: "Have you reviewed all correspondence with the ATO and determined whether the business has any private tax ruling, tax elections, amended notices of assessment, etc that may apply?	",
            value: "",
          },
          {
            row: 13,
            name: "Are you aware if the business is currently being audited by the ATO or has been audited in the last four years and, if so, what the outcome was?	",
            value: "",
          },
          {
            row: 14,
            name: "Have you considered the stamp duty implications of the purchase of the business?	",
            value: "",
            // alerts:
            //   "Where a business is sold through an asset sale, the purchaser does not inherit any tax liabilities of the business. However, where a business is sold through the sale of units or shares, it will not qualify as a supply of a going concern, ie the purchase of the units or shares will be an input taxed financial supply.",
          },

          {
            row: 15,
            name: "Have you considered whether the purchase of the business will be a supply of a going concern, ie GST-free?	",
            value: "",
            alerts:
              "Where a business is sold through an asset sale, the purchaser does not inherit any tax liabilities of the business. However, where a business is sold through the sale of units or shares, it will not qualify as a supply of a going concern, ie the purchase of the units or shares will be an input taxed financial supply.",
          },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },

      {
        title: "If you are buying the business through an asset sale",
        children: [
          {
            row: 16,
            name: "Has a fixed asset register been obtained detailing all the assets being sold?",
            value: "",
            tips: ` The register should detail the following information about the assets being sold? - the original purchase price; - the purchase date; - the depreciation method used; - the depreciation rate used; - the effective life of the asset; - the written-down value`,
          },
          {
            row: 17,
            name: "Have you checked the ownership and condition of the assets being sold? Are copies of instruction manuals available?",
            value: "",
          },
          {
            row: 18,
            name: "Where assets are leased by the business, have you obtained copies of the leases?",
            value: "",
            tips: " If you are taking over existing leases, consideration should be given to whether the leasing terms are reasonable.",
          },
          {
            row: 19,
            name: "Are the assets adequately insured until settlement of the purchase?",
            value: "",
          },

          {
            row: 20,
            name: "Has the purchase price been apportioned across the assets being purchased?",
            value: "",
          },
          // {
          //   row: 21,
          //   name: "Have you considered whether the purchase of the business will be a supply of a going concern, ie GST-free?",
          //   value: "",
          // },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },

      {
        title: "If you are buying the business through a sale of units or shares",
        children: [
          {
            row: 21,
            name: "Have you obtained a listing of all current shareholders or unitholders?",
            value: "",
          },
          {
            row: 22,
            name: "If you are purchasing the business through the sale of units, has the trust made a family trust election? (FTE)",
            value: "",
          },
          {
            row: 23,
            name: "If you are purchasing the business through the sale of shares, has the company made an interposed entity election (IEE)?",
            value: "",
            alerts:
              "The existence of an FTE and IEE restricts who may receive a distribution from the trust or company, and to whom a trust may distribute any income derived.",
          },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },

      {
        title: "Employee Obligations",
        children: [
          {
            row: 24,
            name: "Have you obtained a list of the employees, including their salaries and other entitlements?",
            value: "",
          },
          {
            row: 25,
            name: "Are there any key staff who would be imperative to the smooth, continued running of the business?",
            value: "",
          },
          {
            row: 26,
            name: "Are you aware of all employment conditions, including key workplace agreements, any incentive bonus plans, staff rotation policies, disciplinary procedures, standards of conduct, etc?",
            value: "",
          },
          {
            row: 27,
            name: "Have all outstanding employee entitlements, such as superannuation guarantee and annual leave, been accounted for?",
            value: "",
          },

          {
            row: 28,
            name: "Are the WorkCover premiums up-to-date?",
            value: "",
          },

          {
            row: 29,
            name: "Are you comfortable with the current business culture, the level of staff relations and turnover the last four years?",
            value: "",
            alerts:
              "Where a business is sold through an asset sale, the purchaser can choose not to take on the employees of the vendor. However, where a business is sold through the sales of units or shares, there will be a continuity of employment and the buyer is responsible for any employee liabilities accrued.",
          },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },

      {
        title: "Trading Stock",
        children: [
          {
            row: 30,
            name: "Does the trading stock include any obsolete stock?",
            value: "",
          },
          {
            row: 31,
            name: "Has the trading stock been valued at market value?",
            value: "",
            tips: "Where the purchase of the business includes trading stock, the trading stock must be valued at market value on the day of disposal, which is normally the data of the purchased contrast.",
          },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },

      {
        title: "Business premises",
        children: [
          {
            row: 32,
            name: "Have you sighted copies of all real estate lease agreements, deeds, mortagages and any relevant documents relating to the premises?",
            value: "",
          },
          {
            row: 33,
            name: "If the same business premises are to continue, has the vendor facilitated a lease assignment and have all documents been signed by you?",
            value: "",
          },
          {
            row: 34,
            name: "If there are improvements to the business premises, has a register been obtained detailing the improvements?",
            value: "",
            tips: "The register should detail the following information: the cost of the improvements; and date of construction or acquisition.",
          },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },

      {
        title: "Other considerations",
        children: [
          {
            row: 35,
            name: "Do you know why the vendor is selling?",
            value: "",
            tips: "You should be wary of a vendor who does not disclose important information. Sources of information can include social media, the internet and press items.",
          },
          {
            row: 36,
            name: "Is there a documented business plan?",
            value: "",
          },
          {
            row: 37,
            name: "If you are acquiring the business with other people, do you have the necessary agreements in place?",
            value: "",
          },
          {
            row: 38,
            name: "Are the business operations subject to any government regulations? If so, are all relevant government licences, permits or consents up-to-dates?",
            value: "",
          },
          {
            row: 39,
            name: "Does the business have any pending or ongoing lawsuits, or any recently finalised litigation causes?",
            value: "",
          },

          {
            row: 40,
            name: "Have you given thought to whether the structure (company, partnership or trust) that the business operates through is suitable for your needs?",
            value: "",
          },
          {
            row: 41,
            name: "Have you searched the local council and other government agency records to ensure there are no plans or council orders that could disrupt the business or lead to a potential drop in sales?",
            value: "",
          },
          {
            row: 42,
            name: "If a restrictive covenant, earn-out clause or claw-back clause is included in the purchase contract, have you ascertained the legal and tax implications?",
            value: "",
          },

          {
            row: 43,
            name: "If you are acquiring the business with other people, do you have the necessary agreements in place?",
            value: "",
          },
          {
            row: 44,
            name: "Have you examined current production, distribution, sales and marketing strategies (including websites) of the business and the likely impact of a change of ownership?",
            value: "",
          },
          {
            row: 45,
            name: "Do you know who the main competitors are?",
            value: "",
          },
          {
            row: 46,
            name: "Have you considered potential future issues that could negatively impact the viability of the business (eg, changing the technology, trends, etc?)",
            value: "",
            // tips: "The register should detail the following information: the cost of the improvements; and date of construction or acquisition.",
            tips: "",
          },
          {
            row: "",
            name: "notes",
            value: "",
          },
        ],
      },
    ],
    notes: "",
  },
  dealRework: {
    askPrice: null,
    ebitda: "",
    askMultiple: "",
    preClosingStrategy: {
      goalPurchasePrice: 0,
      goalMultiple: "",
      table: [
        {
          name: "",
          yesOrNo: "",
          value: null,
          notes: "",
        },
      ],
      notes: "",
    },
    gapFundingStrategy: {
      goalPurchasePrice: 0,
      dealBundleValue: 0,
      gapLeft: 0,
      table: [
        {
          name: "",
          yesOrNo: "",
          value: null,
          notes: "",
        },
      ],
      notes: "",
    },
    postClosingStrategy: {
      goalPurchasePrice: 0,
      dealBundleValue: 0,
      gapLeft: 0,
      table: [
        {
          name: "",
          yesOrNo: "",
          value: null,
          notes: "",
        },
      ],
      notes: "",
    },
    dealNotes: {
      notes: "",
      bizImpact: "",
      netValueAdded: null,
      annualPositiveCashAdded: null,
      fullyFundedOrPartiallyFunded: null,
      yesOrNo: "",
    },
  },
  dealReworkSheetRunningCache: {
    dealBundleValue: {
      preClosingStrategy: 0,
      gapFundingStrategy: 0,
    },
  },
  finalTermSheet: "",
  conclusiveNotes: "",
  fileList: [
    // {
    //   name: "api.svg",
    //   url: "/xxx",
    // },
    // {
    //   name: "api2.svg",
    //   url: "/xxx",
    // },
  ],
  step: 10,
});

const state = initState();

const maps = {
  step: ["step", Number],
  mode: ["baseInfo.id", (id) => (id ? "edit" : "create")],
  preClosingStrategyTable: [
    "dealBundleWorkSheet.preClosingStrategy.table",
    (table) =>
      table
        .filter((i) => i.name)
        .map((i) => ({
          ...i,
          label: `${i.name.label} (${i.yesOrNo === "Y" ? "Yes" : "No"})`,
          value: i.value,
          notes: i.notes,
        })),
  ],
  gapFundingStrategyTable: [
    "dealBundleWorkSheet.gapFundingStrategy.table",
    (table) =>
      table
        .filter((i) => i.name)
        .map((i) => ({
          ...i,
          label: `${i.name.label} (${i.yesOrNo === "Y" ? "Yes" : "No"})`,
          value: i.value,
          notes: i.notes,
        })),
  ],
  postClosingStrategyTable: [
    "dealBundleWorkSheet.postClosingStrategy.table",
    (table) =>
      table
        .filter((i) => i.name)
        .map((i) => ({
          ...i,
          label: `${i.name.label} (${i.yesOrNo === "Y" ? "Yes" : "No"})`,
          value: i.value,
          notes: i.notes,
        })),
  ],
  dealAnalysisDealOverviewTable: [
    "dealAnalysis.dealOverviewForm",
    "dealReview.motivationToSell",
    (form, motivationToSell) => {
      const newForm = form.map((i) => ({
        ...i,
      }));
      // newForm.at(-1).label = motivationToSell || newForm.at(-1).label;
      // console.log(newForm, motivationToSell);
      return newForm;
    },
  ],
  reworkPreClosingStrategyTable: [
    "dealRework.preClosingStrategy.table",
    (table) =>
      table
        .filter((i) => i.name)
        .map((i) => ({
          ...i,
          label: `${i.name.label} (${i.yesOrNo === "Y" ? "Yes" : "No"})`,
          value: i.value,
          notes: i.notes,
        })),
  ],
  reworkGapFundingStrategyTable: [
    "dealRework.gapFundingStrategy.table",
    (table) =>
      table
        .filter((i) => i.name)
        .map((i) => ({
          ...i,
          label: `${i.name.label} (${i.yesOrNo === "Y" ? "Yes" : "No"})`,
          value: i.value,
          notes: i.notes,
        })),
  ],
  reworkPostClosingStrategyTable: [
    "dealRework.postClosingStrategy.table",
    (table) =>
      table
        .filter((i) => i.name)
        .map((i) => ({
          ...i,
          label: `${i.name.label} (${i.yesOrNo === "Y" ? "Yes" : "No"})`,
          value: i.value,
          notes: i.notes,
        })),
  ],
  calculateTotal:
    () =>
    (t, usedValues = ["Y"]) =>
      t
        .filter((i) => i.value && i.name && usedValues.includes(i.yesOrNo))
        .reduce((res, i) => res + i.value, 0),
};

const actions = {
  initState,
  updateId: withAPI((id, { setState }) => {
    setState((v) => {
      // eslint-disable-next-line no-plusplus
      v.baseInfo.id = id;
    });
  }),
  updateDealBundleValueOfPreClosingStrategy:
    (value) =>
    ({ setState }) => {
      setState((s) => {
        s.dealBundleWorkSheetRunningCache.dealBundleValue.preClosingStrategy = value;
      });
    },
  updateDealReworkValueOfPreClosingStrategy:
    (value) =>
    ({ setState }) => {
      setState((s) => {
        s.dealReworkSheetRunningCache.dealBundleValue.preClosingStrategy = value;
      });
    },
  updateDealBundleValueOfGapFundingStrategy:
    (value) =>
    ({ setState }) => {
      setState((s) => {
        s.dealBundleWorkSheetRunningCache.dealBundleValue.gapFundingStrategy = value;
      });
    },
  updateReworkValueOfGapFundingStrategy:
    (value) =>
    ({ setState }) => {
      setState((s) => {
        s.dealReworkSheetRunningCache.dealBundleValue.gapFundingStrategy = value;
      });
    },
  fetchPlanData:
    () =>
    async ({ getState, setState, localDispatch }) => {
      const { id } = getState().baseInfo;
      const res = await http.get(`/dealplan/${id}`);
      const planData = res.data;
      const {
        dealReview,
        dealAnalysis,
        dealBundleWorkSheet,
        termSheet,
        dealDue,
        dealRework,
        finalTermSheet,
        conclusiveNotes,
        files,
      } = planData;
      setState((s) => {
        dealReview && (s.dealReview = JSON.parse(dealReview));
        dealAnalysis && (s.dealAnalysis = JSON.parse(dealAnalysis));
        dealBundleWorkSheet && (s.dealBundleWorkSheet = JSON.parse(dealBundleWorkSheet));
        termSheet && (s.termSheet = JSON.parse(termSheet || "{}"));
        dealDue && (s.dealDue = JSON.parse(dealDue));
        dealRework && (s.dealRework = JSON.parse(dealRework));
        finalTermSheet && (s.finalTermSheet = JSON.parse(finalTermSheet || "{}"));
        files && (s.fileList = JSON.parse(files));
        conclusiveNotes && (s.conclusiveNotes = JSON.parse(conclusiveNotes));

        s.step = Number(planData.step || "10");
      });
      return localDispatch("syncAnalysisDataToBundleWorkSheet");
    },
  nextStep: (targetStep) => (api) => api.getState(),
  setCardOpen:
    (id) =>
    ({ setState }) =>
      setState((v) => {
        v.idCardOpen = id;
      }),
  saveDealReview: withAPI(async (newValue, { setState, getState, localDispatch }) => {
    setState((v) => {
      Object.assign(v.dealReview, newValue);
    });
    const { baseInfo, dealReview } = getState();
    const params = {
      id: baseInfo.id || undefined,
      targetBusiness: dealReview.businessName,
      dealReview: JSON.stringify(dealReview),
      step: String(Math.max(2, getState().step)),
    };
    const res = await saveDealPlan(params);
    if (res.data?.id) {
      setState((v) => {
        Object.assign(v.baseInfo, {
          id: res.data.id,
        });
      });
    }
    return localDispatch("nextStep", 2);
  }),
  syncAnalysisDataToBundleWorkSheet: () => (api) => {
    api.setState((v) => {
      const { dealAnalysis } = v;
      v.dealBundleWorkSheet.askPrice = dealAnalysis.dealOverviewForm[0].value || 0;
      v.dealBundleWorkSheet.ebitda = dealAnalysis.dealOverviewForm[1].value || 0;
      v.dealBundleWorkSheet.askMultiple = dealAnalysis.dealOverviewForm[2].value || 0;
    });
  },
  saveDealAnalysis: withAPI(async (newValue, { setState, getState, localDispatch }) => {
    await saveDealPlan({
      id: getState().baseInfo.id,
      dealAnalysis: JSON.stringify(newValue),
      step: String(Math.max(4, getState().step)),
    });
    setState((v) => {
      Object.assign(v.dealAnalysis, newValue);
    });
    localDispatch("syncAnalysisDataToBundleWorkSheet");
    return localDispatch("nextStep", 4);
  }),
  saveDealBundleWorkSheet: withAPI(async (newValue, { setState, getState, localDispatch }) => {
    await saveDealPlan({
      id: getState().baseInfo.id,
      dealBundleWorkSheet: JSON.stringify(newValue),
      step: String(Math.max(6, getState().step)),
    });
    setState((v) => {
      Object.assign(v.dealBundleWorkSheet, newValue);
    });
    return localDispatch("nextStep", 8);
  }),
  saveDealReworkSheet: withAPI(async (newValue, { setState, getState, localDispatch }) => {
    await saveDealPlan({
      id: getState().baseInfo.id,
      dealRework: JSON.stringify(newValue),
      step: String(Math.max(6, getState().step)),
    });
    setState((v) => {
      Object.assign(v.dealRework, newValue);
    });
    return localDispatch("nextStep", 8);
  }),
  copyDealBundleWorkSheet: withAPI(async (newValue, { setState, getState, localDispatch }) => {
    await saveDealPlan({
      id: getState().baseInfo.id,
      dealRework: JSON.stringify(newValue),
      step: String(Math.max(6, getState().step)),
    });
    setState((v) => {
      Object.assign(v.dealRework, newValue);
    });
    return localDispatch("nextStep", 8);
  }),
  // saveTermSheet: withAPI(async (newValue, { setState, getState, localDispatch }) => {
  //   await saveDealPlan({
  //     id: getState().baseInfo.id,
  //     termSheet: newValue,
  //     step: String(Math.max(7, getState().step)),
  //   });
  //   setState((v) => {
  //     v.termSheet = newValue;
  //   });
  //   return localDispatch("nextStep", 7);
  // }),
  saveTermSheet:
    (newValue) =>
    async ({ setState, getState, localDispatch }) => {
      await saveDealPlan({
        id: getState().baseInfo.id,
        termSheet: JSON.stringify(newValue),
        step: String(Math.max(10, getState().step)),
      });
      setState((v) => {
        v.termSheet = newValue;
      });
      return localDispatch("nextStep", 10);
    },
  saveDealDue: withAPI(async (newValue, { setState, getState, localDispatch }) => {
    await saveDealPlan({
      id: getState().baseInfo.id,
      dealDue: JSON.stringify(newValue),
      step: String(Math.max(9, getState().step)),
    });
    setState((v) => {
      Object.assign(v.dealDue, newValue);
    });
    return localDispatch("nextStep", 9);
  }),
  saveFinalTermSheet:
    (newValue) =>
    async ({ setState, getState, localDispatch }) => {
      await saveDealPlan({
        id: getState().baseInfo.id,
        finalTermSheet: JSON.stringify(newValue),
        step: String(Math.max(10, getState().step)),
      });
      setState((v) => {
        v.finalTermSheet = newValue;
      });
      return localDispatch("nextStep", 10);
    },
  saveConclusiveNotes:
    (newValue) =>
    async ({ setState, getState, localDispatch }) => {
      await saveDealPlan({
        id: getState().baseInfo.id,
        conclusiveNotes: JSON.stringify(newValue),
        step: String(Math.max(10, getState().step)),
      });
      setState((v) => {
        v.conclusiveNotes = newValue;
      });
      return localDispatch("nextStep", 10);
    },
  addFile: withAPI(async (file, { setState, getState }) => {
    setState((s) => {
      s.fileList.push(file);
      s.fileList = unionBy(s.fileList, "name");
    });
    return saveDealPlan({
      id: getState().baseInfo.id,
      files: JSON.stringify(getState().fileList),
    });
  }),
  deleteFile: withAPI(async (file, { setState, getState }) => {
    setState((s) => {
      s.fileList = s.fileList.filter((i) => i.name !== file.name);
    });
    return saveDealPlan({
      id: getState().baseInfo.id,
      files: JSON.stringify(getState().fileList),
    });
  }),
  addFileThroughAWS:
    (fileName, contentType, file) =>
    async ({ setState, getState }) => {
      const dealId = getState().baseInfo.id;
      const url = await http.post("/aws_upload", {
        dealId,
        fileName,
        fileType: contentType,
      });
      const { presignedUrl, fileUrL } = url.data;
      const form = new FormData();
      form.append("file", file);
      await fetch(presignedUrl, {
        method: "PUT",
        body: form,
      }).then(() => {
        setState((s) => {
          s.fileList.push({ name: fileName, url: fileUrL });
        });
        return saveDealPlan({
          id: dealId,
          files: JSON.stringify(getState().fileList),
        });
      });
    },
  deleteFileThroughAWS:
    (fileName) =>
    async ({ setState, getState }) => {
      const dealId = getState().baseInfo.id;
      await http.post("/aws_delete", {
        fileName,
        dealId,
      });
      setState((s) => {
        // delete file url from the list
        s.fileList = s.fileList.filter((i) => i.name !== fileName);
      });
      return saveDealPlan({
        id: getState().baseInfo.id,
        files: JSON.stringify(getState().fileList),
      });
    },
  // caculation of multiples
  cacAskMultiple:
    (askPrice, ebitda) =>
    async ({ setState, getState }) => {
      const askMultiple = askPrice / ebitda;
      setState((s) => {
        s.dealAnalysis.dealOverviewForm[2].value = askMultiple;
      });
      return saveDealPlan({
        id: getState().baseInfo.id,
        dealAnalysis: JSON.stringify(getState().dealAnalysis),
      });
    },
  // switch between print of v1 and v2 of term sheet
  switchTermSheetVersion:
    (version) =>
    async ({ setState, getState }) => {
      console.log("version gets in", version);
      setState((s) => {
        s.printSelection = version;
      });
      console.log("printSelection", getState().printSelection);
    },
  // reset deal plan state to initial state before creating a new deal plan
  resetDealPlanState: () => (api) => {
    api.setState(initState());
    // console.log(api.getState());
  },
};

export default {
  state,
  maps,
  actions,
};
