import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { loadStripe } from "@stripe/stripe-js";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useMemo, useState } from "react";
import { useFlatInject } from "store";
import { Modal } from "utils/modal";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY_STRIPE ?? "");
const stripeErrorMessage = {
  card_declined: "Payment declined by the issuer.",
  expired_card: "Card expired.",
  default: "Other card error.",
};

function PaymentMethod({ isMobile = false }) {
  const [user] = useFlatInject("user");
  const [{ cardPayment, user: userObject }] = useFlatInject("user");
  const { stripeErrorCode } = userObject;
  const [errorStripe, setErrorStripe] = useState("");

  useEffect(() => {
    const errorMessage =
      Boolean(stripeErrorCode) === false
        ? ""
        : stripeErrorMessage[stripeErrorCode] ?? stripeErrorMessage.default;

    setErrorStripe(errorMessage);
  }, [stripeErrorCode]);

  const { getPaymentMethod } = user;
  useEffect(() => {
    getPaymentMethod();
  }, []);

  const paramsUpgradePaymentModal = useMemo(
    () => ({
      stripePromise,
    }),
    []
  );

  const openUpdatePaymentModal = async () => {
    try {
      await Modal.confirm(paramsUpgradePaymentModal);
    } catch (error) {
      console.log("cancel modal");
    }
  };

  return (
    <Card
      id="payment"
      sx={{
        boxShadow: "none",
        position: "relative",
        flexDirection: { xs: "column-reverse", md: "column" },
      }}
    >
      <MDButton
        variant="contained"
        color="green"
        sx={{
          padding: "5px 20px",
          fontSize: "14px",
          border: "1px solid transparent",
          height: "39px",
          position: isMobile ? "relative" : "absolute",
          right: isMobile ? 0 : "24px",
          top: isMobile ? 0 : "24px",
          marginBottom: isMobile ? "12px" : 0,
        }}
        onClick={openUpdatePaymentModal}
      >
        Update payment method
      </MDButton>
      {!isMobile ? (
        <MDBox p={3}>
          <MDTypography fontWeight="normal" fontSize={24} sx={{ color: "#282828" }}>
            Payment method
          </MDTypography>
        </MDBox>
      ) : null}
      <Grid container px={3} pb={3} spacing={3}>
        <Grid item xs={12} lg={6}>
          <MDTypography fontSize={12} sx={{ color: "#282828" }}>
            Credit card
          </MDTypography>
          <MDBox
            sx={{
              borderBottom: "1px solid rgba(117, 117, 117, 0.56)",
              paddingBottom: "3px",
              width: "100%",
              display: "flex",
              marginTop: "8px",
            }}
          >
            <input
              style={{
                fontSize: "24px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                width: "44px",
                letterSpacing: "1.25px",
              }}
              value={1234}
              type="password"
              disabled
            />
            <input
              style={{
                fontSize: "24px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                width: "44px",
                letterSpacing: "1.25px",
              }}
              value={1234}
              type="password"
              disabled
            />
            <input
              style={{
                fontSize: "24px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                width: "44px",
                letterSpacing: "1.25px",
              }}
              type="password"
              value={1234}
              disabled
            />
            <input
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#282828",
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
              }}
              value={cardPayment?.lastFourNumber}
              disabled
            />
          </MDBox>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: "flex", textAlign: "center", gap: "24px", flexDirection: "row-reverse" }}
        >
          <MDTypography fontSize={14} sx={{ color: "#137523", margin: "auto 0" }}>
            {`${cardPayment?.expMonth} / ${cardPayment?.expYear}`}
          </MDTypography>
          <MDTypography fontSize={14} sx={{ color: "#282828", margin: "auto 0" }}>
            Expires
          </MDTypography>
        </Grid>
        {errorStripe && (
          <Grid item xs={12} lg={6}>
            <MDTypography sx={{ fontSize: "14px", color: "red" }}>{errorStripe}</MDTypography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export default PaymentMethod;
