export default {
  state: {
    pathname: "",
    search: "",
    hash: "",
    query: {},
  },
  maps: {},
  actions: {
    updatePath: (path) => ({ path }),
  },
};
