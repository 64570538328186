import MDBox from "components/MDBox";
import bgSigninImage from "assets/images/illustrations/background-sign-in.jpg";
import BrandingLogo from "assets/images/small-logos/branding-logo.svg";
import MDTypography from "components/MDTypography";
// import { MDCustomSwitch } from "layouts/pages/pricing-page/components/Header";

export default function AuthMobileLayout({
  title,
  description,
  children,
  isPricing,
  px,
  setMonthly,
}) {
  return (
    <MDBox
      sx={{
        backgroundImage: `url(${bgSigninImage})`,
        pt: 10,
        ...(!isPricing && { height: "calc(100% + 160px)" }),
        ...(px && { px }),
      }}
      display="flex"
      flexDirection="column"
    >
      <MDBox
        mt={3}
        mb={4}
        component="img"
        src={BrandingLogo}
        height="32px"
        sx={{ alignSelf: "flex-start" }}
      />
      <MDBox sx={{ gap: "16px" }} pb={10}>
        {title || (
          <MDTypography
            fontSize="32px"
            fontWeight="regular"
            sx={{
              color: "#fff",
            }}
          >
            Get started
          </MDTypography>
        )}
        {description}
        {/* {isPricing && (
          <MDBox display="flex" flexDirection="row" justifyContent="center">
            <MDBox
              display="flex"
              flexDirection="row"
              gap="20px"
              pt="24px"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <MDTypography color="white" fontWeight="normal">
                Monthly
              </MDTypography>
              <MDCustomSwitch onChange={(event) => setMonthly(!event.target.checked)} />
              <MDTypography color="white" fontWeight="normal">
                Yearly
              </MDTypography>
              <MDBox
                sx={{
                  background: "#D4AF37",
                  padding: "0px 8px",
                  borderRadius: "4px",
                  marginLeft: "-8px",
                  marginRight: "-20px",
                }}
              >
                <MDTypography fontWeight="medium" fontSize="16px">
                  20% discount
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        )} */}
      </MDBox>
      {children}
    </MDBox>
  );
}
