/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { styled } from "@mui/material";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import CheckedIcon from "assets/images/icons/pay/checked-icon.svg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React from "react";
import { useFlatInject } from "store";

const TransitionBox = styled(MDBox)`
  transition: background-color 0.3s;
  background: #fff;
  padding: 32px 20px;
`;

function DefaultPricingCard({
  color,
  badge,
  price,
  specifications,
  action,
  shadow,
  onClick,
  landingPage,
  isMobile,
  isMonthly,
}) {
  const [bgColor, setBgColor] = React.useState(color);
  const [{ loading }] = useFlatInject("user", { state: ["loading"] });
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <MDBox
      key={label}
      display="flex"
      alignItems="start"
      justifyContent="start"
      gap="10px"
      sx={{ margin: "20px 0" }}
    >
      <MDBox display="flex">
        <MDBox component="img" src={CheckedIcon} />
      </MDBox>
      <MDTypography fontWeight="light" textAlign="start" fontSize="14px" sx={{ color: "#757575" }}>
        {label}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <TransitionBox
        variant={bgColor === "white" ? "contained" : "gradient"}
        borderRadius="xl"
        sx={{
          background: landingPage ? "#F5F7FA" : null,
          border: "3px solid #D4AF37",
          minHeight: "540px",
        }}
      >
        <MDBox width="max-content" mx="auto">
          <MDTypography
            sx={{
              backgroundColor: badge.color === "light" ? "rgba(232, 190, 81, 0.12)" : "white",
              border: "1px solid #D4AF37",
              padding: "4px 20px",
              borderRadius: "23px",
              color: "#0E361C",
              fontSize: "18px",
            }}
            variant="caption"
            fontWeight="regular"
          >
            {badge.label}
          </MDTypography>
        </MDBox>
        <MDBox textAlign="center">
          <MDBox>
            <MDTypography variant="h1" sx={{ color: "#0E361C", marginTop: "20px" }}>
              ${price.value}
              <MDTypography
                display="inline"
                component="small"
                variant="h5"
                color="inherit"
                verticalAlign="bot"
              >
                AUD
              </MDTypography>
              <MDTypography fontSize="16px" fontWeight="medium" sx={{ color: "#282828" }}>
                per month
              </MDTypography>
              <MDTypography sx={{ color: "#282828", minHeight: "24px", fontSize: "14px" }}>
                {!isMonthly ? "billed annually" : ""}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          <MDBox sx={{ minHeight: "275px" }}>{renderSpecifications}</MDBox>
          {action.type === "internal" ? (
            <MDBox position="absolute" bottom="32px" right="20px" left="20px">
              <MDButton variant="contained" color={action?.color} fullWidth onClick={onClick}>
                {action.label}&nbsp;
              </MDButton>
            </MDBox>
          ) : (
            <MDBox position="absolute" bottom="32px" right="20px" left="20px">
              <MDButton
                target="_blank"
                rel="noreferrer"
                disabled={loading.fetchingPaymentInfo}
                loading={loading.fetchingPaymentInfo}
                variant="contained"
                color={action?.color}
                fullWidth
                onClick={onClick}
              >
                {action.label}&nbsp;
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </TransitionBox>
    </Card>
  );
}

// Setting default props for the DefaultPricingCard
DefaultPricingCard.defaultProps = {
  color: "white",
  shadow: true,
};

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.instanceOf(Array).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default DefaultPricingCard;
