import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { listDataTerms } from "pages/terms-page/constants/data";
import TermItem from "../TermItem";

function Main() {
  return (
    <MDBox p={60 / 8}>
      <MDBox>
        <MDTypography
          fontWeight="medium"
          fontSize={{ xs: 24, md: 36, lg: 54 }}
          sx={{ color: "#282828" }}
        >
          DEAL DASHBOARD TERMS & CONDITIONS
        </MDTypography>
        <MDTypography fontWeight="regular" fontSize={14} sx={{ color: "#282828" }}>
          Version 0.0 13 March 2024
        </MDTypography>
        <MDTypography fontWeight="light" fontSize={14} sx={{ color: "#282828" }}>
          <br />
          <br />
        </MDTypography>
        {listDataTerms.map((term) => (
          <TermItem title={term.title} childs={term.childs} />
        ))}
      </MDBox>
    </MDBox>
  );
}

export default Main;
