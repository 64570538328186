import MDInput from "components/MDInput";
import { useState } from "react";
import numeral from "numeral";

export function FormatNumInput({
  value,
  twoDecimal,
  isRaw = false,
  hiddenLabel = false,
  ...props
}) {
  const [focus, setFocus] = useState(false);
  return (
    <MDInput
      {...props}
      type={focus ? "number" : "text"}
      value={focus ? value : numeral(value).format(twoDecimal ? "0,0.00" : "0,0")}
      onFocus={(e) => {
        setFocus(true);
        props.onFocus?.(e);
      }}
      placeholder="0"
      onBlur={(e) => {
        setFocus(false);
        props.onBlur?.(e);
      }}
      sx={{
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& .MuiFormLabel-root": {
          opacity: hiddenLabel ? 0 : 1,
        },
      }}
    />
  );
}
