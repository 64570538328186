/* eslint-disable array-callback-return */
/* eslint-disable no-var */
/**
 =========================================================
 * Otis Admin PRO - v2.0.1
 =========================================================
 
 * Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Settings page components
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFlatInject } from "store";
import { useHttp, useLoading, useLocation } from "utils/hooks";

import { useMediaQuery } from "@mui/material";
import MDButton from "components/MDButton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BodySettingMobileLayout from "pages/settings/components/LayoutMobile/BodySettingMobile";
import ConclusiveNotes from "./components/ConclusiveNotes";
import DealAnalysis from "./components/DealAnalysis";
import DealBundleWorkSheet from "./components/DealBundleWorkSheet";
import DealDue from "./components/DealDue";
import DealOverview from "./components/DealOverview";
import DealRework from "./components/DealRework";
import DealSummery from "./components/DealSummery";
import FileUpload from "./components/FileUpload";
import FinalTermSheet from "./components/FinalTermSheet";
import Sidenav from "./components/Sidenav";
import TermSheet from "./components/TermSheet";

function DealPlanOperation() {
  const [{ step, updateId, fetchPlanData, initStates }] = useFlatInject("dealPlanOperation", {
    maps: ["step"],
  });
  const [{ printSelection }] = useFlatInject("dealPlanOperation");
  const [{ user }] = useFlatInject("user");
  const location = useLocation();
  const mainRef = useRef();
  const [businessName, setBusinessName] = useState("");
  const [textAutoSave, setTextAutoSave] = useState("");
  const isMobile = useMediaQuery("(max-width:430px)");
  const is1280 = useMediaQuery("(max-width:1280px)");
  const isSmallMobile = useMediaQuery("(max-width:380px)");
  const [idCard, setIdCard] = useState("deal-review");
  const [modeSavePDF, setModeSavePDF] = useState("");
  const [loadingState, setLoading] = useState(false);
  const isBasicUser = Number(user?.vip) === 0;

  const { loading } = useHttp(
    async () => {
      if (location.query?.id) {
        updateId(Number(location.query?.id));
        await fetchPlanData();
      }
    },
    { deps: [location.query?.id] }
  );

  const listMobile = React.useMemo(
    () => [
      {
        title: "Deal Overview",
        id: "deal-review",
        component: (
          <Grid item xs={12} className="no-print" paddingX="8px">
            <DealOverview
              isMobile={isMobile}
              setBusinessName={setBusinessName}
              setTextAutoSave={setTextAutoSave}
              isSmallMobile={isSmallMobile}
            />
          </Grid>
        ),
      },
      {
        title: "Deal Analysis",
        id: "deal-analysis",
        component: (
          <Grid item xs={12} className="no-print" paddingX="8px">
            <DealAnalysis
              isMobile={isMobile}
              isSmallMobile={isSmallMobile}
              setTextAutoSave={setTextAutoSave}
            />
          </Grid>
        ),
      },
      {
        title: "Deal Bundle Worksheet",
        id: "deal-bundle-work-sheet",
        component: (
          <Grid item xs={12} className="no-print" paddingX="8px">
            <DealBundleWorkSheet
              isMobile={isMobile}
              isSmallMobile={isSmallMobile}
              setTextAutoSave={setTextAutoSave}
            />
          </Grid>
        ),
      },
      {
        title: "Deal Summary",
        id: "deal-pitch",
        component: (
          <Grid item xs={12} className="no-print" paddingX="8px">
            <DealSummery isMobile={isMobile} />
          </Grid>
        ),
      },
      {
        title: "Initial Term Sheet",
        id: "term-sheet",
        component: (
          <Grid
            item
            xs={12}
            className={printSelection !== "v1" && "no-print"}
            paddingX={isMobile ? "0px" : "8px"}
          >
            <TermSheet isMobile={isMobile} setTextAutoSave={setTextAutoSave} />
          </Grid>
        ),
      },
      {
        title: "Deal Discovery",
        id: "deal-due",
        component: (
          <Grid item xs={12} className="no-print" paddingX="8px">
            <DealDue isMobile={isMobile} setTextAutoSave={setTextAutoSave} />
          </Grid>
        ),
      },
      {
        title: "Deal Rework",
        id: "deal-rework",
        component: (
          <Grid item xs={12} className="no-print" paddingX={isMobile ? "0px" : "8px"}>
            <DealRework
              isMobile={isMobile}
              isSmallMobile={isSmallMobile}
              setTextAutoSave={setTextAutoSave}
            />
          </Grid>
        ),
      },
      {
        title: "Deal Repitch",
        id: "final-term-sheet",
        component: (
          <Grid
            item
            xs={12}
            className={printSelection !== "v2" && "no-print"}
            paddingX={isMobile ? "0px" : "8px"}
          >
            <FinalTermSheet isMobile={isMobile} setTextAutoSave={setTextAutoSave} />
          </Grid>
        ),
      },
      {
        title: "Deal Repitch Notes",
        id: "second-deal-pitch",
        component: (
          <Grid item xs={12} className="no-print" paddingX="8px">
            <ConclusiveNotes isMobile={isMobile} setTextAutoSave={setTextAutoSave} />
          </Grid>
        ),
      },
      {
        title: "Upload Files",
        id: "file-upload",
        component: (
          <Grid item xs={12} className="no-print" paddingX="8px">
            <FileUpload isMobile={isMobile} />
          </Grid>
        ),
      },
    ],
    []
  );
  const changeBackGround = useCallback(
    (id) => setIdCard((prevState) => (id !== prevState ? id : prevState)),
    []
  );
  useEffect(() => {
    const onScroll = (e) => {
      const limitHeight = document.body.scrollHeight - 300;
      const currentHeight = document.body.offsetHeight + window.scrollY;
      const isEnd = currentHeight >= limitHeight;
      const isBasic = Number(user?.vip) === 0;
      // 1280 x 720
      if (is1280) {
        if (window.scrollY >= 0 && window.scrollY < 1110 && !isEnd) {
          changeBackGround("deal-review");
        } else if (window.scrollY >= 1110 && window.scrollY < 3330 && !isEnd) {
          changeBackGround("deal-analysis");
        } else if (window.scrollY >= 3330 && window.scrollY < 5350 && !isEnd) {
          changeBackGround("deal-bundle-work-sheet");
        } else if (window.scrollY >= 5350 && window.scrollY < 10810 && !isEnd) {
          changeBackGround("deal-pitch");
        } else if (
          window.scrollY >= 10810 &&
          window.scrollY < (isBasic ? 11029 : 12050) &&
          !isEnd
        ) {
          changeBackGround("term-sheet");
        } else if (
          window.scrollY >= (isBasic ? 11029 : 12050) &&
          window.scrollY < (isBasic ? 16064 : 17040) &&
          !isEnd
        ) {
          changeBackGround("deal-due");
        } else if (
          window.scrollY >= (isBasic ? 16064 : 17040) &&
          window.scrollY < (isBasic ? 17040 : 18450) &&
          !isEnd
        ) {
          changeBackGround("final-term-sheet");
        } else if (window.scrollY >= 18450 || isEnd) {
          changeBackGround("file-upload");
        }
      } else if (window.scrollY >= 0 && window.scrollY < 1000) {
        changeBackGround("deal-review");
      } else if (window.scrollY >= 1000 && window.scrollY < 3160) {
        changeBackGround("deal-analysis");
      } else if (window.scrollY >= 3160 && window.scrollY < 5090) {
        changeBackGround("deal-bundle-work-sheet");
      } else if (window.scrollY >= 5090 && window.scrollY < 10550) {
        changeBackGround("deal-pitch");
      } else if (window.scrollY >= 10550 && window.scrollY < (isBasic ? 10872 : 11775)) {
        changeBackGround("term-sheet");
      } else if (
        window.scrollY >= (isBasic ? 10872 : 11775) &&
        window.scrollY < (isBasic ? 15355 : 16120)
      ) {
        changeBackGround("deal-due");
      } else if (
        window.scrollY >= (isBasic ? 15355 : 16120) &&
        window.scrollY < (isBasic ? 17285 : 18050)
      ) {
        changeBackGround("deal-rework");
      } else if (
        window.scrollY >= (isBasic ? 17285 : 18050) &&
        window.scrollY < (isBasic ? 17630 : 19130)
      ) {
        changeBackGround("final-term-sheet");
      } else if (window.scrollY >= (isBasic ? 17630 : 19130) || isEnd) {
        changeBackGround("file-upload");
      }
    };
    const scrollEnd = (event) => {
      const limitHeight = document.body.scrollHeight - 300;
      const currentHeight = document.body.offsetHeight + window.scrollY;
      if (currentHeight >= limitHeight) {
        changeBackGround("file-upload");
      }
    };
    window.addEventListener("scroll", onScroll, { passive: true });
    window.addEventListener("scrollend", scrollEnd);
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("scrollend", scrollEnd);
    };
  }, []);

  useLoading(loading);
  useLoading(loadingState);

  if (loading) {
    return null;
  }

  const planStepsUI = [
    <Grid item xs={12} className="no-print">
      <DealOverview setBusinessName={setBusinessName} setTextAutoSave={setTextAutoSave} />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <DealAnalysis setTextAutoSave={setTextAutoSave} />
    </Grid>,
    // <Grid item xs={12} className="no-print">
    //   <DealStrategies />
    // </Grid>,
    <Grid item xs={12} className="no-print">
      <DealBundleWorkSheet setTextAutoSave={setTextAutoSave} />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <DealSummery />
    </Grid>,
    <Grid item xs={12} className={printSelection !== "v1" && "no-print"}>
      <TermSheet modeSavePDF={modeSavePDF} setTextAutoSave={setTextAutoSave} />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <DealDue setTextAutoSave={setTextAutoSave} />
    </Grid>,
    // <Grid item xs={12} className="no-print">
    //   <SecondDealPitch />
    // </Grid>,
    <Grid item xs={12} className="no-print">
      <DealRework setTextAutoSave={setTextAutoSave} />
    </Grid>,
    <Grid item xs={12} className={printSelection !== "v2" && "no-print"}>
      <FinalTermSheet modeSavePDF={modeSavePDF} setTextAutoSave={setTextAutoSave} />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <ConclusiveNotes setTextAutoSave={setTextAutoSave} />
    </Grid>,
    <Grid item xs={12} className="no-print">
      <FileUpload />
    </Grid>,
    // eslint-disable-next-line react/no-array-index-key
  ].map((i, index) => React.cloneElement(i, { key: index }));

  // const exportPdf = ({ targetRef, fileName }) => generatePDF(targetRef, { filename: fileName });
  const listSectionId = isBasicUser
    ? [
        "deal-review",
        "deal-analysis",
        "deal-bundle-work-sheet",
        "deal-pitch",
        "deal-due",
        "deal-rework",
        "second-deal-pitch",
      ]
    : [
        "deal-review",
        "deal-analysis",
        "deal-bundle-work-sheet",
        "deal-pitch",
        "term-sheet",
        "deal-due",
        "deal-rework",
        "final-term-sheet",
        "second-deal-pitch",
      ];

  const beforeSavePDF = () => {
    const inputEls = document.querySelectorAll("#deal-review input");
    // eslint-disable-next-line no-restricted-syntax
    for (const el of inputEls) {
      const placeholderValue = el.getAttribute("placeholder");
      el.setAttribute("placeholder", "");
      el.setAttribute("placeholdertemp", placeholderValue);
    }

    /* eslint-disable no-restricted-syntax */
    const textareaEls = document.querySelectorAll("#deal-review textarea");
    for (const el of textareaEls) {
      const placeholderValue = el.getAttribute("placeholder");
      el.setAttribute("placeholder", "");
      el.setAttribute("placeholdertemp", placeholderValue);
    }

    /* eslint-disable no-restricted-syntax */
    // const labelDealReviews = document.querySelectorAll('#deal-review label[data-shrink="true"]');
    // for (const el of labelDealReviews) {
    //   // el.style.letterSpacing = "4px";
    //   el.style.letterSpacing = "normal";
    //   el.style.height = "30px";
    //   el.style.textAlign = "left";
    //   // el.style.width = "100%";
    //   // el.style.fontSize = "16px";
    //   // el.style.lineHeight = "30px";
    //   el.style.border = "1px solid";
    // }

    const labelDealBundles = document.querySelectorAll(
      '#deal-bundle-work-sheet label[data-shrink="true"]'
    );
    for (const el of labelDealBundles) {
      el.style.letterSpacing = "4px";
      el.style.height = "30px";
      el.style.lineHeight = "30px";
    }

    const labelDealRework = document.querySelectorAll('#deal-rework label[data-shrink="true"]');
    for (const el of labelDealRework) {
      el.style.letterSpacing = "4px";
      el.style.height = "30px";
      el.style.lineHeight = "30px";
    }

    const logo = document.querySelector("#deal-review img");
    logo.style.display = "block";

    listSectionId.map((item) => {
      const buttonDealReviews = document.querySelectorAll(`#${item} button`);
      for (const el of buttonDealReviews) {
        el.style.display = "none";
      }
    });
  };

  const afterSavePDF = () => {
    const inputEls = document.querySelectorAll("#deal-review input");
    // eslint-disable-next-line no-restricted-syntax
    const inputAfterEls = document.querySelectorAll("#deal-review input");
    for (const el of inputAfterEls) {
      const placeholderValue = el.getAttribute("placeholdertemp");
      el.setAttribute("placeholdertemp", "");
      el.setAttribute("placeholder", placeholderValue);
    }

    /* eslint-disable no-restricted-syntax */
    const textareaAfterEls = document.querySelectorAll("#deal-review textarea");
    for (const el of textareaAfterEls) {
      const placeholderValue = el.getAttribute("placeholdertemp");
      el.setAttribute("placeholdertemp", "");
      el.setAttribute("placeholder", placeholderValue);
    }

    // const labelDealReviews = document.querySelectorAll('#deal-review label[data-shrink="true"]');
    // for (const el of labelDealReviews) {
    //   el.style.letterSpacing = "normal";
    // }

    const labelDealBundles = document.querySelectorAll(
      '#deal-bundle-work-sheet label[data-shrink="true"]'
    );
    for (const el of labelDealBundles) {
      el.style.letterSpacing = "normal";
    }

    const labelDealRework = document.querySelectorAll('#deal-rework label[data-shrink="true"]');
    for (const el of labelDealRework) {
      el.style.letterSpacing = "normal";
    }

    const logo = document.querySelector("#deal-review img");
    logo.style.display = "none";

    listSectionId.map((item) => {
      const buttonDealReviews = document.querySelectorAll(`#${item} button`);
      for (const el of buttonDealReviews) {
        el.style.display = "block";
      }
    });
  };

  const onSavePdf = async () => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: "p",
      unit: "in",
      format: [8.5, 11],
    });

    beforeSavePDF();
    const listContent = listSectionId.map((item) => document.getElementById(item));
    const listCanVas = listContent.map((item, index) =>
      html2canvas(item, {
        useCORS: true,
        allowTaint: true,
        scrollY: 0,
        letterRendering: true,
        scale: 3,
        onclone: (clonedDocument) => {
          // Array.from(clonedDocument.querySelectorAll("#item-multiline")).forEach(
          //   (itemMultiline) => {
          //     if (itemMultiline.childNodes[2].textContent.trim()) {
          //       const div = clonedDocument.createElement("div");
          //       const p = clonedDocument.createElement("p");
          //       const node = document.createTextNode(
          //         `${
          //           itemMultiline.childNodes[0].textContent.trim() +
          //           itemMultiline.childNodes[1].textContent.trim()
          //         } ${itemMultiline.childNodes[2].textContent.trim()}`
          //       );
          //       p.append(node);
          //       div.append(p);
          //       // div.style.padding = "0 10px";
          //       div.style.fontSize = "14px";
          //       itemMultiline.style.display = "none";
          //       itemMultiline.parentElement.append(div);
          //     }
          //   }
          // );
          if (listSectionId[index] === "deal-review") {
            Array.from(
              clonedDocument.querySelectorAll('#deal-review label[data-shrink="true"]')
            ).forEach((el) => {
              const p = clonedDocument.createElement("label");
              p.style.fontSize = "14px";
              p.style.position = "absolute";
              p.style.color = "#7b809a";
              p.style.top = "-20px";
              p.style.fontSize = "12px";
              const node = document.createTextNode(el.textContent);
              el.style.display = "none";
              el.parentElement.append(p);
              p.append(node);
            });
          }
          // replace textarea => div
          Array.from(clonedDocument.querySelectorAll("textarea")).forEach((itemMultiline) => {
            if (itemMultiline.value) {
              const div = clonedDocument.createElement("div");
              // div.setAttribute("contenteditable", true);
              const p = clonedDocument.createElement("p");
              p.setAttribute("id", "tea");
              const node = document.createTextNode(itemMultiline.value);
              p.append(node);
              div.append(p);
              itemMultiline.style.display = "none";
              itemMultiline.parentElement.append(div);
            }
          });
          // remove x in div after replaced textarea => div
          Array.from(clonedDocument.querySelectorAll("#tea")).forEach((itemMultiline) => {
            if (itemMultiline.childNodes[0].textContent.trim() === "x") {
              itemMultiline.style.display = "none";
            }
          });
          Array.from(clonedDocument.querySelectorAll(".ql-editor")).forEach((qlEditor) => {
            if (qlEditor.value) {
              const div = clonedDocument.createElement("div");
              const p = clonedDocument.createElement("p");
              p.setAttribute("id", "tea");
              const node = document.createTextNode(qlEditor.value);
              p.append(node);
              div.append(p);
              qlEditor.style.display = "none";
              qlEditor.parentElement.append(div);
            }
          });
          Array.from(clonedDocument.querySelectorAll(".ql-toolbar")).forEach((qlToolbar) => {
            qlToolbar.style.display = "none";
          });
          Array.from(clonedDocument.querySelectorAll(".ql-container")).forEach((qlToolbar) => {
            qlToolbar.style.border = "none";
          });
        },
      })
    );
    const canvases = await Promise.all([...listCanVas]);

    // eslint-disable-next-line no-restricted-syntax
    for (const [i, canvas] of canvases.entries()) {
      const image = { type: "jpeg", quality: 0.98 };
      const margin = [0.4, 0.55];

      const imgWidth = 8.5;
      let pageHeight = 11;

      const innerPageWidth = imgWidth - margin[0] * 2;
      const innerPageHeight = pageHeight - margin[1] * 2;

      // Calculate the number of pages.
      let pxFullHeight = canvas.height;
      // remove blank space follow screen size (section Bundleworksheet)
      if (i === 2) {
        pxFullHeight -= 21;
      }
      const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));

      const nPages = Math.ceil(pxFullHeight / pxPageHeight);

      // Define pageHeight separately so it can be trimmed on the final page.
      pageHeight = innerPageHeight;

      // Create a one-page canvas to split up the full image.
      const pageCanvas = document.createElement("canvas");
      const pageCtx = pageCanvas.getContext("2d");
      pageCanvas.width = canvas.width;
      pageCanvas.height = pxPageHeight + 50;

      for (let page = 0; page <= nPages; page++) {
        // Trim the final page to reduce file size.
        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
          pageCanvas.height = pxFullHeight % pxPageHeight;
          pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
        }

        // Display the page.
        const w = pageCanvas.width;
        const h = pageCanvas.height;
        pageCtx.fillStyle = "white";
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

        // Add the page to the PDF.
        if (page > 0) {
          // remove blank last page
          if (((isBasicUser && i === 6) || (!isBasicUser && i === 8)) && nPages === 1) {
            break;
          }
          pdf.addPage();
        }
        const imgData = pageCanvas.toDataURL(`image/${image.type}`, image.quality);
        pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
      }
      // pdf.save();
    }

    pdf.save(`${businessName}.pdf`, { returnPromise: true }).then(() => {
      // Code will be executed after save
      setLoading(false);
    });
    afterSavePDF();
  };

  return (
    <DashboardLayout isMobile={isMobile}>
      <DashboardNavbar absolute isMini className="no-print" />
      {isMobile && (
        <MDBox
          pt="24px"
          pb="4px"
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            // marginBottom: "24px",
            position: "sticky",
            top: "120px",
            background: "#f0f2f5",
            zIndex: 1000,
            marginLeft: "-24px",
            marginRight: "-24px",
            paddingLeft: "12px",
          }}
          className="no-print"
        >
          <MDTypography sx={{ color: "#282828", fontSize: "12px" }}>Target business </MDTypography>
          <MDTypography
            sx={{
              color: "#282828",
              fontSize: "14px",
              fontWeight: "medium",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {businessName ?? "no business name"}
          </MDTypography>
          <MDTypography sx={{ color: "#757575", fontSize: "12px" }}>
            <i>{textAutoSave}</i>
          </MDTypography>
        </MDBox>
      )}
      {isMobile ? (
        <BodySettingMobileLayout data={listMobile} />
      ) : (
        <MDBox
          mt={isMobile ? 0 : 10}
          sx={{
            "@media print": {
              margin: "0",
              size: "auto",
            },
            width: "fit-content",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={2} className="no-print">
              {!!businessName && !isMobile && (
                <MDBox
                  sx={{
                    display: "flex",
                    gap: "4px",
                    flexDirection: "column",
                    marginBottom: "24px",
                    position: "sticky",
                    top: "1%",
                  }}
                >
                  <MDTypography sx={{ color: "#282828", fontSize: "12px" }}>
                    Target business
                  </MDTypography>
                  <MDTypography
                    sx={{
                      color: "#282828",
                      fontSize: "14px",
                      fontWeight: "medium",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "270px",
                    }}
                  >
                    {businessName ?? "no business name"}
                  </MDTypography>
                  <MDTypography sx={{ color: "#757575", fontSize: "12px", minHeight: "24px" }}>
                    <i>{textAutoSave}</i>
                  </MDTypography>
                </MDBox>
              )}
              {!isMobile && <Sidenav idCard={idCard} />}
            </Grid>
            <Grid item lg={10}>
              <MDBox mb={3}>
                <Grid container spacing={3} ref={mainRef}>
                  {planStepsUI.filter((_, index) => index < step).map((s, index) => s)}
                </Grid>
              </MDBox>
              {/* <MDButton
                onClick={() => {
                  setLoading(true);
                  setModeSavePDF("view");
                  setTimeout(() => {
                    onSavePdf();
                    setModeSavePDF("edit");
                  }, 1000);
                  // onSavePdf();
                }}
                fullWidth
              >
                Export
              </MDButton> */}
              <MDBox
                mb={3}
                sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
                className="no-print"
              >
                <MDButton
                  color="green"
                  sx={{
                    border: "1px solid #004225",
                    fontSize: "14px",
                  }}
                  loading={loadingState}
                  onClick={() => {
                    setLoading(true);
                    setModeSavePDF("view");
                    setTimeout(() => {
                      onSavePdf();
                      setModeSavePDF("edit");
                    }, 1000);
                    // onSavePdf();
                  }}
                >
                  Export deal
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default DealPlanOperation;
