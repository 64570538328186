import axios from "axios";
import { HttpError } from "utils/HttpError";
import { BASE_URL } from "constants/urls/urls";
import { setToken, getUserId, getToken } from "../utils/auth";
// import { Message } from "utils/message";
import { messageSubject } from "../utils/common-event";

/**
 * @type {{
 *  get(...arg: Parameters<import("axios").AxiosInstance["post"]>): Promise<{code: number; data: any; message: string}>;
 *  post(...arg: Parameters<import("axios").AxiosInstance["post"]>): Promise<{code: number; data: any; message: string}>;
 * }}
 */
export const http = axios.create({
  timeout: 1000 * 60,
  baseURL: BASE_URL,
});

http.interceptors.request.use(
  (config) => {
    config.headers.token = getToken();
    config.headers["user-id"] = getUserId();
    config.headers["request-id"] = localStorage.getItem("request-id");
    config.headers.user_id = getUserId();
    return config;
  },
  (err) => {
    messageSubject.next({ message: err.message, type: "error" });
    throw err;
  }
);

http.interceptors.response.use(
  (response) => {
    const { pathname, search } = window.location;
    // 如果是未登录或者登陆错误状态, 则跳转到登录页面
    if ([401004, 401005].includes(response?.data?.code)) {
      const redirect = pathname + search;
      if (
        pathname === "/sign-in" ||
        pathname === "/sign-up" ||
        pathname === "/policy-page" ||
        pathname === "/terms-conditions-page" ||
        pathname === "/landing-page" ||
        pathname === "/contact"
      ) {
        return;
      }
      if (
        pathname !== "/landing-page" &&
        pathname !== "/email/verify" &&
        !pathname.includes("/email/verify")
      ) {
        // window.location.href = `/landing-page?redirect=${redirect}`;
        return response.data;
      }

      // if (pathname === "/email/verify") {
      //   // messageSubject.next({ message: response.data.msg, type: "error" });
      //   // localStorage.clear();
      //   // window.location.href = `/sign-in`;
      //   return;
      // }
      throw new HttpError(response.data.msg, Number(response.data.code));
    }

    if (response?.data?.code === 400005) {
      if (pathname === "/resend-email") {
        messageSubject.next({ message: response.data.msg, type: "error" });
        throw new HttpError(response.data.msg, Number(response.data.code));
      }
    }
    if (response?.data?.code === 400001) {
      if (pathname !== "/resend-email" && pathname !== "/email/verify") {
        window.location.href = `/resend-email`;
      }
      return;
    }

    if (response.data.code !== 0 && response.data.code !== 200 && response.data.code !== 201) {
      messageSubject.next({ message: response.data.msg, type: "error" });
      throw new HttpError(response.data.msg, Number(response.data.code));
    }
    return response.data;
  },
  (err) => {
    messageSubject.next({ message: err.response?.data?.message ?? err.message, type: "error" });
    throw new HttpError(err.message || "network error");
  }
);
