/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Box } from "@mui/material";
import bgSigninImage from "assets/images/illustrations/background-sign-in.jpg";
import facebookSigninLogo from "assets/images/small-logos/facebook-sign-in.svg";
import googleSigninLogo from "assets/images/small-logos/google-sign-in.svg";
import pxToRem from "assets/theme/functions/pxToRem";
import Form, { FormItem, useForm } from "components/Form";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInputSignIn from "components/MDInputSignIn";
import MDTypography from "components/MDTypography";
import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInject } from "store";
import { signinSocial } from "utils/auth";
import * as Yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import DescriptionSigninText from "./DescriptionSigninText";
import SigninText from "./SigninText";
import AuthMobileLayout from "../Mobile";

function SignIn() {
  const isMobile = useMediaQuery("(max-width:480px)");
  const [rememberMe, setRememberMe] = useState(false);
  const [user] = useInject("user");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [er, setEr] = useState("");

  const handleNavigateAfterLogin = async (userFlat) => {
    if (Number(userFlat?.user?.endAt) === 0 && !Number.isNaN(Number(userFlat?.user?.endAt))) {
      if (userFlat?.user?.isVerified || userFlat?.user?.type !== "EMAIL") {
        navigate("/pay");
      } else {
        navigate("/resend-email");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      user.actions.fetchUserInfo().then((userFlat) => {
        handleNavigateAfterLogin(userFlat);
      });
    }
  }, []);
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (formData, { validateForm }) => {
      await validateForm();
      await user.actions.login(formData);
      const res = await user.actions.fetchUserInfo();
      handleNavigateAfterLogin(res);
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email is required").email("Please enter a valid email address"),
      password: Yup.string().required("Password is required"),
    }),
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleOnPressEnter = (e) => e.key === "Enter" && form.submitForm();

  const onClickShowPassword = useCallback(() => {
    setShowPassword((pre) => !pre);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isMobile ? (
        <AuthMobileLayout
          px={5}
          title={<SigninText isMobile={isMobile} />}
          description={<DescriptionSigninText isMobile={isMobile} />}
        >
          <Form form={form} fieldStyle={{ flex: 1 }} labelSx={{ display: "none" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <MDButton
                sx={{ pt: 11 / 8, pb: 11 / 8, pl: 22 / 8, pr: 22 / 8 }}
                fullWidth
                onClick={() =>
                  signinSocial(
                    new GoogleAuthProvider(),
                    setEr,
                    user,
                    navigate,
                    handleNavigateAfterLogin
                  )
                }
                color="white"
              >
                <MDBox component="img" src={googleSigninLogo} />
              </MDButton>
              {/* <MDButton
                sx={{ pt: 11 / 8, pb: 11 / 8, pl: 22 / 8, pr: 22 / 8 }}
                fullWidth
                onClick={() => signinSocial(new FacebookAuthProvider(), setEr, user, navigate)}
                color="white"
              >
                <MDBox component="img" src={facebookSigninLogo} />
              </MDButton> */}
            </MDBox>

            <MDBox display="flex" gap="16px" sx={{ padding: "24px 0" }}>
              <MDBox
                component="span"
                sx={{ width: "100%", background: "#757575", height: "1px", margin: "auto 0" }}
              />
              <MDTypography fontSize="14px" sx={{ color: "#fff !important" }} color="#fff">
                OR
              </MDTypography>
              <MDBox
                component="span"
                sx={{ width: "100%", background: "#757575", height: "1px", margin: "auto 0" }}
              />
            </MDBox>

            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <FormItem
                  as={MDInputSignIn}
                  type="email"
                  name="email"
                  variant="standard"
                  fullWidth
                  placeholder="Email address"
                  onKeyPress={handleOnPressEnter}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <FormItem
                  as={MDInputSignIn}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  variant="standard"
                  fullWidth
                  showPassword="true"
                  eyeDisplay={showPassword}
                  onShowPassword={onClickShowPassword}
                  onKeyPress={handleOnPressEnter}
                  placeholder="Password"
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>

              {er && (
                <Box color="error.main" fontSize={pxToRem(14)} component="span">
                  {er}
                </Box>
              )}
              <MDBox>
                <MDTypography
                  onClick={() => navigate("/reset-password")}
                  sx={{ color: "#fff", cursor: "pointer", width: "fit-content" }}
                  fontSize="12px"
                  fontWeight="regular"
                >
                  Forgot password?
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1} gap={3} display="flex" flexDirection="column">
                <MDButton
                  variant="contained"
                  color="green"
                  size="large"
                  fullWidth
                  loading={form.isSubmitting}
                  onClick={form.submitForm}
                >
                  Sign in
                </MDButton>
                {/* <MDButton
                  fullWidth
                  sx={{ color: "#0E361C" }}
                  onClick={() => navigate("/reset-password")}
                >
                  Forgot password?
                </MDButton> */}
              </MDBox>
            </MDBox>
          </Form>
        </AuthMobileLayout>
      ) : (
        <IllustrationLayout
          title={<SigninText />}
          description={<DescriptionSigninText />}
          illustration={bgSigninImage}
          pr="0px"
        >
          <Form form={form} fieldStyle={{ flex: 1 }} labelSx={{ display: "none" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <MDButton
                sx={{ pt: 11 / 8, pb: 11 / 8, pl: 22 / 8, pr: 22 / 8 }}
                fullWidth
                onClick={() =>
                  signinSocial(
                    new GoogleAuthProvider(),
                    setEr,
                    user,
                    navigate,
                    handleNavigateAfterLogin
                  )
                }
                variant="outlined"
                color="grey"
              >
                <MDBox component="img" src={googleSigninLogo} />
              </MDButton>
              {/* <MDButton
                sx={{ pt: 11 / 8, pb: 11 / 8, pl: 22 / 8, pr: 22 / 8 }}
                fullWidth
                onClick={() => signinSocial(new FacebookAuthProvider(), setEr, user, navigate)}
                variant="outlined"
                color="grey"
              >
                <MDBox component="img" src={facebookSigninLogo} />
              </MDButton> */}
            </MDBox>

            <MDBox display="flex" gap="16px" sx={{ padding: "24px 0" }}>
              <MDBox
                component="span"
                sx={{ width: "100%", background: "#757575", height: "1px", margin: "auto 0" }}
              />
              <MDTypography fontSize="14px" sx={{ color: "#282828" }}>
                OR
              </MDTypography>
              <MDBox
                component="span"
                sx={{ width: "100%", background: "#757575", height: "1px", margin: "auto 0" }}
              />
            </MDBox>

            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <FormItem
                  as={MDInputSignIn}
                  type="email"
                  name="email"
                  variant="standard"
                  fullWidth
                  placeholder="Email address"
                  onKeyPress={handleOnPressEnter}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <FormItem
                  as={MDInputSignIn}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  variant="standard"
                  fullWidth
                  showPassword="true"
                  eyeDisplay={showPassword}
                  onKeyPress={handleOnPressEnter}
                  onShowPassword={onClickShowPassword}
                  placeholder="Password"
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>

              {er && (
                <Box color="error.main" fontSize={pxToRem(14)} component="span">
                  {er}
                </Box>
              )}
              <MDBox>
                <MDTypography
                  onClick={() => navigate("/reset-password")}
                  sx={{ color: "#004225", cursor: "pointer", width: "fit-content" }}
                  fontSize="12px"
                  fontWeight="regular"
                >
                  Forgot password?
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1} gap={3} display="flex" flexDirection="column">
                <MDButton
                  variant="contained"
                  color="green"
                  size="large"
                  fullWidth
                  loading={form.isSubmitting}
                  onClick={form.submitForm}
                >
                  Sign in
                </MDButton>
                {/* <MDButton
                  fullWidth
                  sx={{ color: "#0E361C" }}
                  onClick={() => navigate("/reset-password")}
                >
                  Forgot password?
                </MDButton> */}
              </MDBox>
            </MDBox>
          </Form>
        </IllustrationLayout>
      )}
    </>
  );
}

export default SignIn;
