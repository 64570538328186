// react and Data
import { useState } from "react";
import { useFlatInject } from "store";

// Otis Admin PRO React components
import MDButton from "components/MDButton";

// mui native components
import ModalUpdate from "components/Modal";
import numeral from "numeral";
import { Message } from "utils/message";

export default function UpgradeFromTermSheet() {
  // prepare for state mutation
  const [user] = useFlatInject("user");
  const { user: userObject, upgradePayment, getProrationUpgrade } = user;
  const [proration, setProration] = useState("");
  const [nextPlan, setNextPlan] = useState({
    subscriptionAmount: 0,
    subscriptionType: "",
  });
  const [open, setOpen] = useState(false);

  const callGetDataUpdateModal = async () => {
    try {
      const res = await getProrationUpgrade();
      setProration(res?.prorationAmount);
      setNextPlan({
        subscriptionAmount: res?.subscriptionAmount,
        subscriptionType: res?.subscriptionType,
      });
      setOpen(true);
    } catch (er) {
      console.log(er);
    }
  };

  const handleUpgradePayment = async () => {
    try {
      await upgradePayment(() => {
        Message.success("Subscription updated successfully");
      });
    } catch (er) {
      console.log(er);
    }
  };

  return (
    <div>
      <MDButton
        variant="contained"
        color="green"
        size="large"
        disabled={userObject.canceledAt !== 0}
        onClick={callGetDataUpdateModal}
      >
        Upgrade
      </MDButton>
      <ModalUpdate
        open={open}
        setOpen={setOpen}
        title="Upgrade to Deal Maker today and enjoy the full features of Deal Dashboard."
        content={
          <p>
            Payment will be deducted immediately using your current payment method.
            <br />
            <br />
            You will be charged a pro rata amount today of ${numeral(proration).format("0,0")}. Your
            regular subscription will then be updated to $
            {numeral(nextPlan.subscriptionAmount).format("0,0")} {nextPlan.subscriptionType}.
          </p>
        }
        confirmFunction={handleUpgradePayment}
      />
    </div>
  );
}
