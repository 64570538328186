/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import { Box, Stack, styled } from "@mui/material";
import MDButton from "components/MDButton";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import { useFlatInject } from "store";
import { submitAutoSave } from "utils/common";
import { useAutosave, useHttp } from "utils/hooks";
import { Message } from "utils/message";
import { useForm } from "../../../../../components/Form";
import UpgradeFromTermSheet from "../TermSheetupgrade/TermSheetContent";
import FinalTermSheetContent from "./TermSheetContent";

const StyledTermSheetContent = styled(FinalTermSheetContent)`
  display: none;
  @media print {
    display: ${({ canPrint = false }) => (canPrint ? "block" : "none")} !important;
    padding: 0;
  }
`;

const initValue = () => ({
  date: new Date(),
  between:
    "<Buyer Company Full Name>, (<Buyer Company Shorthand Name>) a corporation organized and existing under the law of <Buyer Company Geo location>, with its head office address located at: <Buyer Company Address>",
  sellerCompanyName: "",
  sellerCompanyNameShort: "",
  theirAddress: "",
  yourCompanyName: "",
  yourCompanyNameShort: "",
  yourCompanyLocation: "",
  yourCompanyAddress: "",
  overallTerms: "",
  shares: "shares",
  otherShares: "",
  numberOfBuyerSigns: 1,
  numberOfSellerSigns: 1,
});

/**
 * Final term sheet or term sheet .v2
 */
function FinalTermSheet({ isMobile, modeSavePDF = "edit", setTextAutoSave }) {
  // basic info preparation
  const [
    {
      finalTermSheet,
      saveFinalTermSheet,
      switchTermSheetVersion,
      dealReview,
      setCardOpen,
      baseInfo,
    },
  ] = useFlatInject("dealPlanOperation");
  const [{ canPrint, user, logPrint }] = useFlatInject("user");
  const userVIPstate = user;
  const { toPDF, targetRef } = usePDF({
    filename: `TermSheetV2-${dayjs().format("DD/MM")}.pdf`,
    page: { margin: Margin.MEDIUM },
  });

  const { toPDF: toMobilePDF, targetRef: targetMobileRef } = usePDF({
    filename: `TermSheetV2-${dayjs().format("DD/MM")}.pdf`,
    page: { margin: Margin.MEDIUM },
  });

  const [value, setValue] = useState(
    isEmpty(finalTermSheet)
      ? initValue()
      : {
          ...initValue(),
          ...finalTermSheet,
        }
  );
  const [mode, setMode] = useState("edit");
  useEffect(() => {
    setMode(modeSavePDF);
    setValue({ ...initValue(), ...finalTermSheet });
  }, [modeSavePDF, finalTermSheet]);

  const form = useForm({
    initialValues: {
      text: finalTermSheet,
    },
    onSubmit: async (formData) => {
      await saveFinalTermSheet(formData.text);
      Message.success("Changes saved");
    },
  });
  const location = useLocation();
  const navigate = useNavigate();

  const submitForm = async (isAuto = false) => {
    try {
      await saveFinalTermSheet(value);
      if (!isAuto) {
        window.location.href = `${location.pathname + location.search}#file-upload`;
        Message.success("Changes saved");
      }
      // setCardOpen("file-upload");
    } catch (error) {
      Message.error(error.message);
    }
  };

  const { run, loading } = useHttp(() => submitForm(false), { manual: true });
  const { run: runAuto } = useHttp(() => submitForm(true), { manual: true });

  useAutosave(async () => {
    if (baseInfo?.id) {
      submitAutoSave(() => runAuto(), setTextAutoSave);
    }
  });

  const onPrintBtnClick = () => {
    switchTermSheetVersion("v2");
    if (!canPrint) {
      Message.warning(
        "Your printing quota has been exhausted. You can utilize this feature by upgrading to a premium package."
      );
      return;
    }
    window.print();
  };

  const onSavePdf = () => {
    const extraButtons = document.getElementsByClassName("extra-buttons-deal-repitch");
    extraButtons.forEach((button) => {
      button.style.display = "none";
    });
    setMode("view");
    switchTermSheetVersion("v2");
    setTimeout(() => {
      isMobile ? toMobilePDF() : toPDF();
      setMode("edit");
    }, 1000);

    setTimeout(() => {
      extraButtons.forEach((button) => {
        button.style.display = "block";
      });
    }, 4000);
  };

  useEffect(() => {
    const afterPrintHandler = (e) => {
      if (canPrint) {
        logPrint();
      }
    };
    window.addEventListener("afterprint", afterPrintHandler);
    return () => window.removeEventListener("afterprint", afterPrintHandler);
  }, [canPrint]);

  // IMPORTANT: className === "no-print" is used to hide the component when printing
  return (
    <Card
      id="final-term-sheet"
      sx={{
        overflow: "visible",
        "@media print": {
          boxShadow: "none",
        },
        boxShadow: isMobile ? "none" : null,
      }}
    >
      <MDBox p={isMobile ? 0 : 3}>
        {user.vip === 0 ? (
          <>
            {!isMobile && (
              <Box display="flex" alignItems="center">
                <MDTypography variant="h4">Term Sheet .v2 </MDTypography>
              </Box>
            )}
            <MDBox p={isMobile ? 1 : 3}>
              <Stack
                // direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  direction: {
                    lg: "row",
                    md: "row",
                    sm: "row",
                    xs: "column",
                  },
                }}
              >
                <MDTypography variant="h6" color="secondary">
                  Upgrade to a Deal Maker account to access the Term Sheet
                </MDTypography>
                <UpgradeFromTermSheet />
              </Stack>
            </MDBox>
          </>
        ) : (
          <div>
            <MDBox ref={targetRef}>
              {!isMobile && (
                <Box display="flex" alignItems="center">
                  <MDTypography variant="h4">Term Sheet .v2 </MDTypography>
                </Box>
              )}
              <FinalTermSheetContent
                className="no-print"
                mode={mode}
                value={value}
                onChange={setValue}
                isMobile={isMobile}
              />
            </MDBox>
            <StyledTermSheetContent canPrint={canPrint} value={value} />
            <MDBox pb={3} py={3} className="no-print" mt={3}>
              {!isMobile ? (
                <Grid
                  item
                  xs={12}
                  textAlign="right"
                  sx={{ display: "flex", gap: "8px", justifyContent: "end" }}
                >
                  <MDButton
                    color="green"
                    variant="outlined"
                    onClick={() => navigate("/deal-planner", { replace: true })}
                    sx={{ fontSize: "14px" }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    color="green"
                    sx={{ fontSize: "14px" }}
                    onClick={() => {
                      switchTermSheetVersion("v2");
                      setTimeout(() => {
                        onPrintBtnClick();
                      }, 4000);
                    }}
                  >
                    Print
                  </MDButton>
                  <MDButton color="green" sx={{ fontSize: "14px" }} onClick={onSavePdf}>
                    Save PDF
                  </MDButton>
                  <MDButton
                    color="green"
                    disabled={loading || !dealReview.businessName}
                    loading={loading}
                    onClick={() => run()}
                    sx={{ fontSize: "14px" }}
                  >
                    Save and next
                  </MDButton>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    textAlign="right"
                    sx={{ display: "flex", gap: "8px", justifyContent: "end" }}
                  >
                    {/* <MDButton
                      color="green"
                      sx={{ fontSize: isMobile ? "14px" : "12px", flex: 1 }}
                      onClick={() => {
                        switchTermSheetVersion("v2");
                        setTimeout(() => {
                          onPrintBtnClick();
                        }, 4000);
                      }}
                    >
                      Print
                    </MDButton> */}
                    <MDButton
                      color="green"
                      sx={{ fontSize: isMobile ? "14px" : "12px", flex: 1 }}
                      onClick={onSavePdf}
                    >
                      Save PDF
                    </MDButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    textAlign="right"
                    sx={{ display: "flex", gap: "8px", justifyContent: "end", marginTop: "10px" }}
                  >
                    <MDButton
                      color="green"
                      variant="outlined"
                      onClick={() => navigate("/deal-planner", { replace: true })}
                      sx={{ fontSize: isMobile ? "14px" : "12px", flex: 1 }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      color="green"
                      disabled={loading || !dealReview.businessName}
                      loading={loading}
                      onClick={() => run()}
                      sx={{ fontSize: isMobile ? "14px" : "12px", flex: 1 }}
                    >
                      Save and next
                    </MDButton>
                  </Grid>
                  <MDBox
                    ref={targetMobileRef}
                    sx={{
                      position: "absolute",
                      width: "1100px",
                      right: "100000px",
                      overflowx: "hidden",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <MDTypography variant="h4">Term Sheet .v2 </MDTypography>
                    </Box>
                    <FinalTermSheetContent
                      className="no-print"
                      mode={mode}
                      value={value}
                      onChange={setValue}
                      isMobile={isMobile}
                    />
                  </MDBox>
                </>
              )}
            </MDBox>
          </div>
        )}
      </MDBox>
    </Card>
  );
}

export default FinalTermSheet;
