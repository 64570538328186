import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MuiTable from "@mui/material/Table";
import Pagination from "@mui/material/Pagination";
import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import MDBox from "components/MDBox";
import { css } from "@emotion/css";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import { omit } from "lodash";
import { useMediaQuery } from "@mui/material";

function Table({
  data = [],
  columns,
  pagination,
  updatePageInfo,
  onTableChange,
  children,
  px,
  beforePagi,
  isDeal,
  isDealDue,
  sxTableRow,
  sxTitle,
  sxItem,
  ...props
}) {
  const isMobile = useMediaQuery("(max-width:480px)");
  const calculateElements = React.useCallback(
    (pageNum) =>
      Number(pageNum) > 1
        ? (Number(pageNum) - 1) * (pagination?.pageSize || 10) + 1
        : pagination?.total === 0
        ? 0
        : 1,
    [pagination?.pageNum, pagination?.total]
  );
  const calculateTotal = React.useCallback(() => {
    const total =
      data.length !== pagination?.pageSize
        ? pagination?.total
        : calculateElements(pagination?.pageNum || 1) - 1 + (pagination?.pageSize || 10);
    return total;
  }, [pagination?.pageNum, pagination?.total, data.length, pagination?.pageSize]);

  React.useEffect(() => {
    const info = {
      ...pagination,
      pageSize: Number(pagination?.pageSize),
    };
    if (data.length < pagination?.pageSize) {
      info["pageNum"] = 1;
    }
    updatePageInfo?.(info);
    onTableChange?.();
  }, [pagination?.pageSize]);

  React.useEffect(() => {
    const info = {
      ...pagination,
      pageSize: Number(pagination?.pageSize),
    };

    if (data.length === 0 && Number(info?.pageNum) > 1 && Number(info?.pageNum)) {
      info["pageNum"] = (info?.pageNum ?? 1) - 1;
    }
    updatePageInfo?.(info);
    onTableChange?.();
  }, [data.length]);

  return (
    <TableContainer
      sx={{
        overflowX: isMobile ? "visible !important" : "auto",
      }}
      {...props}
    >
      <MuiTable size="small">
        <TableHead component="thead">
          <TableRow sx={{ ...sxTableRow }}>
            {columns.map((c) => (
              <DataTableHeadCell
                pl={1}
                width={c.width ? c.width : "auto"}
                align={c.align ? c.align : "left"}
                key={c.key}
                px={px}
                sorted={false}
              >
                {c.title}
              </DataTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={row.id || index}
              sx={{ background: row.isChecked ? "#E7EBE8" : "transparent", ...sxItem }}
            >
              {columns.map((c) => {
                const colspan =
                  typeof c.colSpan === "function" ? c.colSpan(row, index, data) : c.colSpan;
                if (colspan === "0") {
                  return null;
                }
                return (
                  <DataTableBodyCell
                    vertiAlign={c.vertiAlign}
                    width={c.width}
                    align={c.align ? c.align : "left"}
                    key={c.key}
                    px={px}
                    pl={1}
                    colSpan={colspan}
                    rowSpan={
                      typeof c.rowSpan === "function" ? c.rowSpan(row, index, data) : c.rowSpan
                    }
                    {...omit(
                      c,
                      "width",
                      "align",
                      "key",
                      "title",
                      "render",
                      "dataIndex",
                      "rowSpan",
                      "colSpan"
                    )}
                  >
                    {c.render
                      ? c.render?.(row, index, data)
                      : c.dataIndex
                      ? row[c.dataIndex]
                      : null}
                  </DataTableBodyCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
      {pagination && (
        <>
          {beforePagi}
          <MDBox display="flex" p={isMobile ? 0 : 3} py={3}>
            <MDBox display="flex" alignItems="center" sx={{ flexWrap: "wrap", gap: "8px" }}>
              <MDTypography
                variant="button"
                sx={{ display: "flex", alignItems: "center" }}
                color="secondary"
                fontWeight="regular"
                fontSize={12}
              >
                Showing {calculateElements(pagination?.pageNum ?? 0)} - {calculateTotal()} of{" "}
                {pagination.total} entries
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <Autocomplete
                  disableClearable
                  options={["10", "20", "50"]}
                  size="small"
                  sx={{ width: "5rem" }}
                  value={String(pagination?.pageSize)}
                  onChange={(_, v) => {
                    updatePageInfo({
                      ...pagination,
                      pageSize: Number(v),
                    });
                    onTableChange?.();
                  }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;entries per page
                </MDTypography>
              </MDBox>
            </MDBox>

            <Pagination
              showFirstButton={false}
              // aria-label={false}
              sx={{
                ml: "auto",
                ...(isMobile && {
                  ".MuiPagination-ul": {
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "flex-start",
                  },
                  "& .MuiPaginationItem-page": {
                    display: "none !important",
                  },
                  ".MuiPagination-ul li:nth-child(2)": {
                    display: "none !important",
                  },
                  ".MuiPagination-ul li:nth-child(3)": {
                    display: "none !important",
                  },
                  ".MuiPagination-ul li:last-child": {
                    display: "flex !important",
                  },
                }),
              }}
              classes={{
                root: css`
                  justify-content: flex-end;
                  display: flex;
                `,
              }}
              page={pagination.pageNum}
              onChange={(e, v) => {
                updatePageInfo({ ...pagination, pageNum: Number(v) });
                onTableChange?.();
              }}
              count={Math.ceil(pagination.total / (pagination?.pageSize || 1))}
              variant="outlined"
              color="primary"
            />
          </MDBox>
        </>
      )}
      {children}
    </TableContainer>
  );
}

export { Table };
export default Table;
