import { getAuth, signInWithPopup } from "firebase/auth";

export const setToken = (token) => {
  localStorage.token = token;
};

export const setUserId = (userId) => {
  localStorage.userId = userId;
};

export const setUserType = (userType) => {
  localStorage.userType = userType;
};
export const setIsVerify = (isVerified) => {
  localStorage.isVerified = isVerified;
};

export const getUserType = () => localStorage.userType;
export const getIsVerify = () => localStorage.isVerified;

export const getToken = () => localStorage.token;

export const getUserId = () => localStorage.userId;

export const getData = () => JSON.parse(localStorage.getItem("_data/user"))?.user;

export const signinSocial = async (provider, setEr, user, navigate, handleNavigate) => {
  const auth = getAuth();
  provider.addScope("email");
  provider.setCustomParameters({ prompt: "select_account" });

  // The signed-in user info.
  try {
    const result = await signInWithPopup(auth, provider);
    const userData = result?.user;
    const idToken = await userData.getIdToken();
    const useInfo = await user.actions.signInSocial(idToken);
    setEr("");
    handleNavigate(useInfo);
  } catch (er) {
    setEr("Can not sign in as this social account");
  }
};
