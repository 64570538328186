import { store } from "../store";
import { messageSubject } from "./common-event";

// eslint-disable-next-line import/prefer-default-export
export class Message {
  static success(msg, time) {
    store.dispatch("message", "success", msg, time);
  }

  static error(msg, time) {
    store.dispatch("message", "error", msg, time);
  }

  static info(msg, time) {
    store.dispatch("message", "info", msg, time);
  }

  static warning(msg, time) {
    store.dispatch("message", "warning", msg, time);
  }
}

messageSubject.subscribe((m) => {
  Message[m.type](m.message, m.duration);
});
