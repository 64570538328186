import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "examples/Footer";
import Header from "pages/terms-page/components/Header";
import MDBox from "components/MDBox";
import { useEffect } from "react";
import { useInject, useFlatInject } from "store";
import { Message } from "utils/message";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { getUserType, getIsVerify } from "utils/auth";
import MainVerifyEmail from "./components/Main";

function VerifyEmail() {
  const [user] = useInject("user");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("_data/user"))?.user;
  const isVerified = getIsVerify();
  const userType = getUserType();
  const handleVerifyUser = async () => {
    try {
      const token = searchParams.get("token");
      const email = searchParams.get("email");
      await user.actions.verifyEmail(email ?? userInfo?.email, token);
      Message.success(`Email succesfully verified!`);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      localStorage.clear();
      Message.error(`${error?.message}`);
      setTimeout(() => {
        navigate("/sign-in");
      }, 2000);
    }
  };

  useEffect(() => {
    if (
      ((userInfo?.isVerified || isVerified === "true") && userType === "EMAIL") ||
      ["GOOGLE", "FACEBOOK"].includes(userType)
    ) {
      navigate("/pay");
    } else {
      handleVerifyUser();
    }
  }, []);

  return (
    <PageLayout background="#F5F7FA">
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <MDBox ml="-28px">
          <Header isNoRedirect />
        </MDBox>
        <MDBox justifyContent="center" alignItems="center">
          <MainVerifyEmail />
        </MDBox>
        <Footer />
      </MDBox>
    </PageLayout>
  );
}

export default VerifyEmail;
