import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "35vh",
  margin: "auto",
  // bgcolor: "background.paper",
  boxShadow: 50,
  backGround: "red",
  borderRadius: "8px",
};

export default function TransitionsModalVideo({ children, open, setOpen }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      sx={{
        backdropFilter: "blur(3px)",
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <MDBox sx={style}>{children}</MDBox>
      </Fade>
    </Modal>
  );
}
