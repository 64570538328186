import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDRadio
import MDRadioRoot from "components/MDRadio/MDRadioRoot";

const MDRadio = forwardRef(({ color, disabled, size, ...rest }, ref) => (
  <MDRadioRoot {...rest} ref={ref} disabled={disabled} ownerState={{ color, disabled, size }} />
));

// Setting default values for the props of MDBox
MDRadio.defaultProps = {
  color: "dark",
  disabled: false,
};

// Typechecking props for the MDBox
MDRadio.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "teal",
  ]),
  disabled: PropTypes.bool,
};

export default MDRadio;
