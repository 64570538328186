/**
 * 全局loading状态管理
 */

const state = {
  loadingCount: 0,
  loadingText: "loading...",
  loadingZIndex: 100,
};

export default {
  state,
  maps: {
    showLoading: ["loadingCount", (lc) => !!lc],
  },
  actions: {
    show:
      () =>
      ({ getState }) => ({
        loadingCount: getState().loadingCount + 1,
      }),

    hide:
      () =>
      ({ getState }) => ({
        loadingCount: getState().loadingCount - 1,
      }),
    changeLoadingText: (loadingText) => ({ loadingText }),
    changeLoadingZIndex: (loadingZIndex) => ({
      loadingZIndex,
    }),
  },
};
