/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components

// Otis Admin PRO React page layout routes

// Images
import bgPrice from "assets/images/illustrations/background-sign-in.jpg";
import BrandingLogo from "assets/images/small-logos/branding-logo.svg";
// eslint-disable-next-line import/no-unresolved
import styled from "@emotion/styled";
import { Switch, useMediaQuery } from "@mui/material";
import FooterImage from "assets/images/icons/pay/footer-img.svg";
import { useNavigate } from "react-router-dom";
import { useFlatInject } from "store";

export const MDCustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" defaultChecked disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiButtonBase-root": {
    top: "-1px",
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#004225 !important",
        opacity: 1,
        border: "1px solid #D4AF37",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    width: "100%",
    height: "100%",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Header({ tabValue, tabHandler, children, setMonthly }) {
  const navigate = useNavigate();
  const [user] = useFlatInject("user");
  const isMobile = useMediaQuery("(max-width:480px)");
  const handleClickLogin = async () => {
    await user.logout();
    window.localStorage.clear();
    navigate("/sign-in");
  };
  return (
    <MDBox>
      <MDBox
        position="relative"
        minHeight="100vh"
        height="100%"
        borderRadius="xl"
        m={2}
        pt={2}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { black } }) =>
            `${linearGradient(rgba(black.main, 0.25), rgba(black.main, 0.25))}, url(${bgPrice})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <MDBox
          container
          width={{ xs: "86%", lg: "70%" }}
          m="auto"
          sx={{
            position: "relative",
            textAlign: "center",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MDBox display="flex" justifyContent="space-between" py="24px">
            <MDBox component="img" sx={{ width: "150px" }} src={BrandingLogo} />

            <MDBox display="flex" gap="6px">
              <MDTypography
                sx={{
                  fontWeight: "regular",
                  fontSize: "14px",
                  margin: "auto 0",
                  color: "#F5F7FA",
                }}
              >
                Already have an account?
              </MDTypography>

              <MDBox sx={{ cursor: "pointer" }} onClick={handleClickLogin}>
                <MDTypography
                  component="span"
                  fontWeight="medium"
                  sx={{
                    textDecoration: "underline !important",
                    fontSize: "14px",
                    color: "#FFF",
                  }}
                >
                  Login
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDTypography color="white" variant="h1" fontWeight="regular">
            Pricing
          </MDTypography>
          <MDBox display="flex" flexDirection="row" justifyContent="center">
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
              pt="24px"
              alignItems="center"
              sx={{ alignSelf: "center", marginLeft: isMobile ? 0 : "140px" }}
            >
              <MDTypography color="white" fontWeight="normal" fontSize="16px">
                Pay Monthly
              </MDTypography>
              <MDCustomSwitch
                onChange={(event) => setMonthly(!event.target.checked)}
                sx={{ border: "1px solid #D4AF37", borderRadius: "16px" }}
              />
              <MDTypography color="white" fontWeight="normal" fontSize="16px">
                Pay Yearly
              </MDTypography>
              <MDBox sx={{ background: "#D4AF37", padding: "0px 8px", borderRadius: "4px" }}>
                <MDTypography fontWeight="medium" fontSize="16px">
                  20% discount
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox
            sx={{
              fontSize: "16px",
              color: "#fff",
              textAlign: "center",
              marginTop: "24px",
              fontWeight: "500",
              fontStyle: "italic",
            }}
          >
            All prices include GST
          </MDBox>
          <MDBox>{children}</MDBox>
          <MDTypography
            color="white"
            fontWeight="regular"
            fontSize="18px"
            spacing="0.09px"
            marginTop="66px"
          >
            Brought to you by
          </MDTypography>
          <MDBox width="100%" display="flex" justifyContent="center" my="24px">
            <MDBox
              component="img"
              src={FooterImage}
              width="390px"
              height="80px"
              sx={({ breakpoints }) => ({
                [breakpoints.up("xs")]: {
                  width: "80%",
                },
              })}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Header;
