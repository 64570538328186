/**
 *
 * @type {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
 */
const state = {
  miniSidenav: false,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: "info",
  transparentNavbar: true,
  fixedNavbar: true,
  openConfigurator: false,
  direction: "ltr",
  layout: "dashboard",
  darkMode: false,
};

const actions = {
  /**
   * @param miniSidenav {boolean}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setMiniSidenav: (miniSidenav) => ({ miniSidenav }),
  /**
   * @param transparentSidenav {boolean}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setTransparentSidenav: (transparentSidenav) => ({ transparentSidenav }),
  /**
   * @param whiteSidenav {boolean}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setWhiteSidenav: (whiteSidenav) => ({ whiteSidenav }),
  /**
   * @param sidenavColor {string}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setSidenavColor: (sidenavColor) => ({ sidenavColor }),
  /**
   * @param transparentNavbar {boolean}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setTransparentNavbar: (transparentNavbar) => ({ transparentNavbar }),
  /**
   * @param fixedNavbar {boolean}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setFixedNavbar: (fixedNavbar) => ({ fixedNavbar }),
  /**
   * @param openConfigurator {boolean}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setOpenConfigurator: (openConfigurator) => ({ openConfigurator }),
  /**
   * @param direction {'rtl'|'ltr'}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setDirection: (direction) => ({ direction }),
  /**
   * @param layout {string}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setLayout: (layout) => ({ layout }),
  /**
   * @param darkMode {boolean}
   * @returns {{miniSidenav: boolean, layout: string, openConfigurator: boolean, whiteSidenav: boolean, fixedNavbar: boolean, transparentNavbar: boolean, darkMode: boolean, transparentSidenav: boolean, sidenavColor: string, direction: string}}
   */
  setDarkMode: (darkMode) => ({ darkMode }),
};

export default {
  state,
  actions,
};
