/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Settings page components
import useMediaQuery from "@mui/material/useMediaQuery";
import Footer from "examples/Footer";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import InvoiceList from "layouts/pages/account/settings/components/Invoices";
import PaymentMethod from "layouts/pages/account/settings/components/PaymentMethod";
import Payment from "layouts/pages/account/settings/components/Sessions";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useFlatInject } from "store";
import { Modal } from "utils/modal";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import BodySettingMobileLayout from "./components/LayoutMobile/BodySettingMobile";

function Settings() {
  const isMobile = useMediaQuery("(max-width:480px)");
  const [user] = useFlatInject("user");
  const navigate = useNavigate();

  async function logout() {
    await Modal.confirm({
      title: "Warning",
      content: "Are your sure to logout?",
    });
    await user.logout();
    window.localStorage.clear();
    navigate("/sign-in");
  }
  const data = React.useMemo(
    () => [
      {
        title: "Basic Info",
        id: "basic-info",
        component: <BasicInfo isMobile={isMobile} />,
      },
      {
        title: "Change password",
        id: "change-password",
        component: <ChangePassword isMobile={isMobile} />,
      },
      {
        title: "Subscription and Payment",
        id: "sessions",
        component: <Payment isMobile={isMobile} />,
      },
      {
        title: "Payment method",
        id: "payment",
        component: <PaymentMethod isMobile={isMobile} />,
      },
      {
        title: "Invoices",
        id: "invoices",
        component: <InvoiceList isMobile={isMobile} />,
      },
    ],
    []
  );

  return (
    <DashboardLayout isMobile={isMobile}>
      <DashboardNavbar absolute isMini />
      {isMobile ? (
        <BodySettingMobileLayout data={data} />
      ) : (
        <MDBox mt={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>
            <Grid item xs={12} lg={9}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  {/* <Grid item xs={12}>
                  <Header />
                </Grid> */}
                  <Grid item xs={12}>
                    <BasicInfo />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword />
                  </Grid>
                  <Grid item xs={12}>
                    <Payment />
                  </Grid>
                  <Grid item xs={12}>
                    <PaymentMethod />
                  </Grid>
                  <Grid item xs={12}>
                    <InvoiceList />
                  </Grid>
                  {/* <Grid item xs={12}> */}
                  {/*  <Authentication /> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={12}> */}
                  {/*  <Accounts /> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={12}> */}
                  {/*  <Notifications /> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={12}> */}
                  {/*  <Sessions /> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={12}> */}
                  {/*  <DeleteAccount /> */}
                  {/* </Grid> */}
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
