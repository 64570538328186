/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
// eslint-disable-next-line import/no-named-as-default-member
import MDInputRoot from "components/MDInput/MDInputRoot";
import SearchLogo from "assets/images/small-logos/search.svg";
import MDBox from "components/MDBox";

const MDInput = forwardRef(
  (
    {
      error,
      success,
      disabled,
      inputStyle,
      labelStyle,
      searchInput,
      isMobile,
      isSmallMobile,
      isValue,
      ...rest
    },
    ref
  ) => (
    <>
      {searchInput && (
        <MDBox
          sx={{ position: "absolute", top: "34px", left: isMobile ? "16px" : "40px" }}
          component="img"
          src={SearchLogo}
        />
      )}
      <MDInputRoot
        {...rest}
        ref={ref}
        ownerState={{
          error,
          success,
          disabled,
          inputStyle,
          labelStyle,
          isSmallMobile,
          searchInput,
          isValue,
        }}
      />
    </>
  )
);

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  searchInput: false,
  inputStyle: undefined,
  labelStyle: undefined,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  searchInput: PropTypes.bool,
  inputStyle: PropTypes.oneOfType([undefined, PropTypes.object]),
  labelStyle: PropTypes.oneOfType([undefined, PropTypes.object]),
};

export default MDInput;
