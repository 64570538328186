import ErrorIcon from "@mui/icons-material/Error";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import theme from "assets/theme";
import React, { useCallback, useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import { sleep } from "utils";

import { Elements } from "@stripe/react-stripe-js";
import MDButton from "components/MDButton";
import CardForm from "layouts/pages/account/settings/components/CardForm";
import styles from "./style.module.scss";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

function MyDialog({ title, content, cancelText, confirmText, cancel, confirm, stripePromise }) {
  const [open, setOpen] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const localCancel = useCallback(async () => {
    setOpen(false);
    setIsConfirm(false);
  }, []);

  const localConfirm = useCallback(async () => {
    setOpen(false);
    setIsConfirm(true);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={localCancel}
      classes={styles}
      TransitionComponent={Transition}
      TransitionProps={useMemo(
        () => ({
          onExited: async () => {
            await sleep();
            if (isConfirm) {
              confirm();
            } else {
              cancel();
            }
          },
        }),
        [cancel, confirm, isConfirm]
      )}
      disableScrollLock="true"
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog"
      sx={{
        maxWidth: "100vw",
        zIndex: 1000000,
        "& .MuiBackdrop-root.MuiModal-backdrop": {
          maxWidth: "100vw",
        },
      }}
    >
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          <CardForm setOpen={setOpen} />
        </Elements>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title" sx={{ display: "flex", alignItems: "center" }}>
            <ErrorIcon color="warning" sx={{ mr: 1 }} />
            {title}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">{content}</DialogContentText> */}
            {content}
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <MDButton
              variant="contained"
              color="green"
              sx={{
                padding: "5px 20px",
                fontSize: "14px",
                border: "1px solid transparent",
                height: "39px",
              }}
              onClick={localConfirm}
            >
              {confirmText}
            </MDButton>
            <MDButton
              variant="outlined"
              color="green"
              sx={{
                fontSize: "14px",
              }}
              onClick={localCancel}
            >
              {cancelText}
            </MDButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export function ConfirmDialog({
  title,
  content,
  cancelText = "Cancel",
  confirmText = "Confirm",
  stripePromise,
}) {
  return new Promise((resolve, reject) => {
    const ele = document.createElement("div");
    document.body.appendChild(ele);
    const root = ReactDOM.createRoot(ele);

    const cancel = () => {
      root.unmount();
      document.body.removeChild(ele);
      reject(new Error(false));
    };
    const confirm = () => {
      root.unmount();
      document.body.removeChild(ele);
      resolve(true);
    };
    root.render(
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MyDialog
          confirm={confirm}
          confirmText={confirmText}
          title={title}
          content={content}
          cancelText={cancelText}
          cancel={cancel}
          stripePromise={stripePromise}
        />
      </ThemeProvider>
    );
  });
}
