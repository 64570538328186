import { ConfirmDialog } from "components/Dialog";

export class Modal {
  /**
   *
   * @param {{
   * 	title: string;
   * 	content: string;
   * }} arg
   * @returns
   */
  static confirm(arg) {
    return ConfirmDialog(arg);
  }
}
