/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Stack,
  useMediaQuery,
} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { MoneyInput } from "components/MoneyInput";
import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components

// Data
import { FormatNumInput } from "components/FormatNumInput";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDRadio from "components/MDRadio";
import Table from "components/Table";
import { useLocation, useNavigate } from "react-router-dom";
import { useFlatInject } from "store";
import { submitAutoSave } from "utils/common";
import { useAutosave } from "utils/hooks";
import { Message } from "utils/message";
import { Form, FormItem, useForm } from "../../../../../components/Form";

const radioUI = <MDRadio />;

const fieldStyle = {
  flex: 1,
  marginRight: {
    lg: "8px",
    sm: "8px",
    md: "8px",
    xs: "0px",
  },
};
const labelSx = { display: "none" };

const inputInsteadOfMoney = [
  "reasonsForSelling",
  // "ipOwned",
  "capacity",
  "underManagement",
  // "turnoverAnnual",
  // "turnoverMonthlyAvg",
];
const notShowValue = ["reasonsForSelling", "underManagement"];

function YesOrNoTrigger({ isMobile, ...props }) {
  return (
    <MDBox mt={0.5} sx={{ borderBottom: "1px solid #d2d6da", height: "44px" }}>
      <FormControl
        sx={{
          ...(isMobile && {
            gap: "7px",
            marginTop: "-13px",
            marginBottom: "0px",
            "& .MuiFormLabel-root": {
              color: "#282828 !important",
            },
          }),
        }}
      >
        <FormLabel
          style={{
            fontSize: 12,
            color: "#282828 !important",
            opacity: isMobile ? 0 : 1,
            // fontSize: {
            //   lg: "16px",
            //   sm: "16px",
            //   md: "16px",
            //   xs: "12px",
            // },
          }}
        >
          Yes / No
        </FormLabel>
        {!isMobile && <MDBox py={0.8} />}
        <RadioGroup
          row
          {...props}
          sx={{
            ...(isMobile
              ? {
                  display: "flex",
                  flexWrap: "nowrap",
                }
              : {}),
          }}
        >
          <FormControlLabel
            value="Y"
            sx={{ ...(isMobile && { display: "flex" }) }}
            control={<MDRadio />}
            label={<MDTypography variant="subtitle2">Yes</MDTypography>}
          />
          <FormControlLabel
            value="N"
            sx={{ ...(isMobile && { display: "flex" }) }}
            control={<MDRadio />}
            label={<MDTypography variant="subtitle2">No</MDTypography>}
          />
        </RadioGroup>
      </FormControl>
    </MDBox>
  );
}

const BusinessOverviewForm = React.forwardRef((props, ref) => {
  const isLaptop = useMediaQuery("(max-width: 1500px)");
  const [dealPlanOperation] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: {
      businessOverview: dealPlanOperation.dealAnalysis.businessOverviewForm,
    },
  });
  const { isMobile } = props;

  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));

  const columns = useMemo(
    () => [
      {
        key: "businessOverview",
        title: (
          <MDTypography fontSize="20px" fontWeight="regular">
            Business Overview
          </MDTypography>
        ),
        dataIndex: "label",
        width: "30%",
        render: (rowData, index) => (
          <MDBox>
            <MDTypography variant="body2">{rowData.label}</MDTypography>
            {/* <MDBox color="#ccc">{rowData.description || "description"}</MDBox> */}
          </MDBox>
        ),
      },
      {
        key: "yesOrNo",
        title: <MDTypography fontSize="12px">Yes / No</MDTypography>,
        width: "15%",
        render: (rowData, index) => {
          const notShowList = [""];
          return (
            <FormItem as={RadioGroup} row name={`businessOverview[${index}].yesOrNo`}>
              <Stack direction="row">
                <FormControlLabel
                  value="Y"
                  control={radioUI}
                  label={<MDTypography variant="subtitle2">Yes</MDTypography>}
                />
                <FormControlLabel
                  value="N"
                  control={radioUI}
                  label={<MDTypography variant="subtitle2">No</MDTypography>}
                />
              </Stack>
            </FormItem>
          );
        },
      },
      {
        key: "value",
        title: <MDTypography fontSize="12px">Value</MDTypography>,
        width: isLaptop ? "14%" : "15%",
        render: (rowData, index) =>
          form.values.businessOverview[index].yesOrNo === "Y" && (
            // <FormItem as={MDInput} type="number" name={`businessOverview[${index}].value`} />
            <FormItem
              variant="standard"
              as={MoneyInput}
              type="number"
              name={`businessOverview[${index}].value`}
            />
          ),
      },
      {
        key: "notes",
        title: <MDTypography fontSize="12px">Notes</MDTypography>,
        render: (rowData, index) => (
          <FormItem
            variant="standard"
            sx={{ width: "100%" }}
            placeholder="enter note here"
            as={MDInput}
            name={`businessOverview[${index}].notes`}
          />
        ),
      },
    ],
    [form.values.businessOverview]
  );

  const columnsMobile = useMemo(
    () => [
      {
        key: "businessOverview",
        dataIndex: "label",
        render: (rowData, index) => (
          <MDBox
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <MDBox display="flex" sx={{ gap: "8px", flexDirection: "column" }} id={index}>
              <MDTypography
                sx={{
                  color: "#282828",
                  fontSize: "12px",
                  lineHeight: "16px",
                  ...(index !== 0 && { display: "none" }),
                }}
              >
                Business overview
              </MDTypography>
              <MDTypography sx={{ color: "#282828", fontSize: "16px", lineHeight: "24px" }}>
                {rowData.label}
              </MDTypography>
            </MDBox>
            <Grid container>
              <Grid item xs={6} display="flex">
                <FormItem
                  as={YesOrNoTrigger}
                  row
                  name={`businessOverview[${index}].yesOrNo`}
                  isMobile={isMobile}
                  type="text"
                  variant="standard"
                  fullWidth
                >
                  {/* <Stack direction="row">
                    <FormControlLabel
                      value="Y"
                      control={radioUI}
                      label={<MDTypography variant="subtitle2">Yes</MDTypography>}
                    />
                    <FormControlLabel
                      value="N"
                      control={radioUI}
                      label={<MDTypography variant="subtitle2">No</MDTypography>}
                    />
                  </Stack> */}
                </FormItem>
              </Grid>

              {form.values.businessOverview[index].yesOrNo === "Y" && (
                <Grid item xs={6}>
                  <FormItem
                    variant="standard"
                    as={MoneyInput}
                    type="number"
                    name={`businessOverview[${index}].value`}
                    label="Value"
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: "#282828 !important",
                        fontSize: {
                          lg: "16px",
                          sm: "16px",
                          md: "16px",
                          xs: "16px",
                        },
                      },
                    }}
                    hiddenLabel={isMobile}
                  />
                </Grid>
              )}
            </Grid>
            <FormItem
              variant="standard"
              sx={{
                width: "100%",
                "& .MuiFormLabel-root": {
                  color: "#282828 !important",
                },
              }}
              placeholder="enter note here"
              as={MDInput}
              label="Notes"
              name={`businessOverview[${index}].notes`}
              InputLabelProps={{ shrink: true }}
            />
          </MDBox>
        ),
      },
    ],
    [form.values.businessOverview]
  );

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <Table
        px={0}
        columns={isMobile ? columnsMobile : columns}
        data={dealPlanOperation.dealAnalysis.businessOverviewForm}
        sx={{
          "& .MuiTable-root": {
            "th:first-child": {
              borderBottom: {
                lg: "0.0625rem solid #f0f2f5",
                md: "0.0625rem solid #f0f2f5",
                sm: "0.0625rem solid #f0f2f5",
                xs: "none",
              },
              display: {
                lg: "table-cell",
                md: "table-cell",
                sm: "table-cell",
                xs: "none",
              },
            },
            "& .MuiTableBody-root": {
              "tr > td": {
                ...(isMobile && { paddingLeft: "2px" }),
                "& .MuiBox-root": {
                  ...(isMobile && { paddingLeft: "0px" }),
                },
              },
              "& :last-child > td": {
                borderBottom: "none",
                "& .MuiBox-root": {
                  ...(isMobile && { marginBottom: "16px" }),
                  "& .MuiBox-root": {
                    marginBottom: 0,
                  },
                },
              },
              "& :first-child > td": {
                "& .MuiBox-root": {
                  "& .MuiBox-root": {
                    marginTop: "4px",
                  },
                },
              },
            },
          },
        }}
      />
    </Form>
  );
});

const DealOverviewForm = React.forwardRef((props, ref) => {
  const isLaptop = useMediaQuery("(max-width: 1500px)");
  const [dealPlanOperation] = useFlatInject("dealPlanOperation");
  const [{ dealReview, saveDealReview, nextStep }] = useFlatInject("dealPlanOperation");

  const form = useForm({
    initialValues: {
      dealOverviewForm: dealPlanOperation.dealAnalysisDealOverviewTable,
    },
  });
  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));

  useEffect(() => {
    if (dealReview.AverageMonthlyProfit) {
      form.setFieldValue("dealOverviewForm[1].value", dealReview.AverageMonthlyProfit * 12);
      form.setFieldValue("dealOverviewForm[5].value", dealReview.AverageMonthlyProfit);
      form.setFieldValue("dealOverviewForm[5].yesOrNo", "Y");
    }
    if (dealReview.avgMonthlyTurnOver) {
      const avgMonthlyTurnOver = dealReview.avgMonthlyTurnOver * 12;
      // Set field value Turnover annual
      form.setFieldValue("dealOverviewForm[3].value", avgMonthlyTurnOver);
    }
  }, [dealReview.AverageMonthlyProfit, dealReview.avgMonthlyTurnOver]);

  // Set field value Turnover Monthly Average
  useEffect(() => {
    const turnoverAnnual = form.values.dealOverviewForm[3].value;
    form.setFieldValue("dealOverviewForm[4].value", turnoverAnnual / 12);
  }, [form.values.dealOverviewForm[3].value]);

  useEffect(() => {
    const askPrice = form.values.dealOverviewForm[0].value;
    const profit = form.values.dealOverviewForm[1].value;
    const askMultiple = (askPrice / profit).toFixed(2) || 0;
    form.setFieldValue("dealOverviewForm[2].value", askMultiple);

    // TODO: set Motivation to sell
    // form.setFieldValue("dealOverviewForm[15].notes", dealReview.motivationToSell);
  }, [form.values.dealOverviewForm[0].value, form.values.dealOverviewForm[1].value]);

  const { isMobile } = props;
  const columns2 = useMemo(
    () => [
      {
        key: "dealOverview",
        title: (
          <MDTypography fontSize="20px" fontWeight="regular">
            Deal Overview
          </MDTypography>
        ),
        width: "30%",
        render: (rowData, index) => (
          <MDBox>
            <MDTypography variant="body2">{rowData.label}</MDTypography>
            {/* <MDBox color="#ccc">{rowData.description || "description"}</MDBox> */}
          </MDBox>
        ),
      },
      {
        key: "yesOrNo",
        title: <MDTypography fontSize="12px">Yes / No</MDTypography>,
        width: "15%",
        render: (rowData, index) => {
          const notShowList = [
            "askPrice",
            "profit",
            "aksMultiple",
            "reasonsForSelling",
            "turnoverAnnual",
            "turnoverMonthlyAvg",
            "customerDbTotal",
          ];
          if (notShowList.includes(rowData?.key)) {
            return;
          }
          return (
            <FormItem as={RadioGroup} row name={`dealOverviewForm[${index}].yesOrNo`}>
              <Stack direction="row">
                <FormControlLabel
                  value="Y"
                  control={radioUI}
                  label={<MDTypography variant="subtitle2">Yes</MDTypography>}
                />
                <FormControlLabel
                  value="N"
                  control={radioUI}
                  label={<MDTypography variant="subtitle2">No</MDTypography>}
                />
              </Stack>
            </FormItem>
          );
        },
      },
      {
        key: "value",
        title: <MDTypography fontSize="12px">Value</MDTypography>,
        width: isLaptop ? "14%" : "15%",
        render: (rowData, index) => {
          if (notShowValue.includes(rowData?.key)) {
            return;
          }
          if (form.values.dealOverviewForm[index].yesOrNo === "Y") {
            // exclude form tables are not money
            if (inputInsteadOfMoney.includes(rowData?.key)) {
              return (
                <FormItem
                  variant="standard"
                  as={FormatNumInput}
                  type="number"
                  name={`dealOverviewForm[${index}].value`}
                />
              );
            }
            // caculate aksMultiple => askPrice / profit
            if (rowData?.key === "aksMultiple") {
              return (
                <FormItem
                  as={FormatNumInput}
                  type="number"
                  variant="standard"
                  name={`dealOverviewForm[${index}].value`}
                  // disabled
                  sx={{ backgroundColor: "white !important" }}
                />
              );
            }
            // unable to bind the value of the select
            return (
              <FormItem
                variant="standard"
                as={
                  ["socialMediaFollowing", "prospectDbTotal", "customerDbTotal"].includes(
                    rowData?.key
                  )
                    ? FormatNumInput
                    : MoneyInput
                }
                type="number"
                name={`dealOverviewForm[${index}].value`}
              />
            );
          }
        },
      },
      {
        key: "notes",
        title: <MDTypography fontSize="12px">Notes</MDTypography>,
        render: (rowData, index) => (
          <FormItem
            variant="standard"
            sx={{ width: "100%" }}
            as={MDInput}
            placeholder={`${
              rowData?.key === "reasonsForSelling"
                ? "Enter Reasons For Selling here"
                : "enter note here"
            }`}
            name={`dealOverviewForm[${index}].notes`}
          />
        ),
      },
    ],

    [form.values.dealOverviewForm]
  );
  const columns2Mobile = useMemo(
    () => [
      {
        key: "dealOverview",
        render: (rowData, index) => {
          const notShowList = [
            "askPrice",
            "profit",
            "aksMultiple",
            "reasonsForSelling",
            "turnoverAnnual",
            "turnoverMonthlyAvg",
            "customerDbTotal",
          ];

          return (
            <MDBox
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <MDBox display="flex" sx={{ flexDirection: "column", gap: "8px" }}>
                <MDTypography
                  sx={{
                    color: "#282828",
                    fontSize: "12px",
                    lineHeight: "16px",
                    ...(index !== 0 && { display: "none" }),
                  }}
                >
                  Deal overview
                </MDTypography>
                <MDTypography sx={{ color: "#282828", fontSize: "16px", lineHeight: "24px" }}>
                  {rowData.label}
                </MDTypography>
              </MDBox>
              <Grid container>
                {!notShowList.includes(rowData?.key) && (
                  <Grid item xs={6} display="flex">
                    <FormItem
                      as={YesOrNoTrigger}
                      row
                      name={`dealOverviewForm[${index}].yesOrNo`}
                      isMobile={isMobile}
                      type="text"
                      variant="standard"
                      fullWidth
                    />
                    {/* <FormItem as={RadioGroup} row name={`dealOverviewForm[${index}].yesOrNo`}>
                      <Stack direction="row">
                        <FormControlLabel
                          value="Y"
                          control={radioUI}
                          label={<MDTypography variant="subtitle2">Yes</MDTypography>}
                        />
                        <FormControlLabel
                          value="N"
                          control={radioUI}
                          label={<MDTypography variant="subtitle2">No</MDTypography>}
                        />
                      </Stack>
                    </FormItem> */}
                  </Grid>
                )}

                {form.values.dealOverviewForm[index].yesOrNo === "Y" ? (
                  !notShowValue.includes(rowData.key) ? (
                    inputInsteadOfMoney.includes(rowData?.key) ? (
                      <Grid item xs={6}>
                        <FormItem
                          variant="standard"
                          as={FormatNumInput}
                          type="number"
                          name={`dealOverviewForm[${index}].value`}
                          label="Value"
                          hiddenLabel={isMobile}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <FormItem
                          variant="standard"
                          as={
                            [
                              "socialMediaFollowing",
                              "prospectDbTotal",
                              "customerDbTotal",
                              "aksMultiple",
                            ].includes(rowData?.key)
                              ? FormatNumInput
                              : MoneyInput
                          }
                          type="number"
                          name={`dealOverviewForm[${index}].value`}
                          label="Value"
                          sx={{
                            backgroundColor:
                              rowData?.key === "aksMultiple" ? "white !important" : null,
                            "& .MuiFormLabel-root": {
                              color: "#282828 !important",
                              fontSize: {
                                lg: "16px",
                                sm: "16px",
                                md: "16px",
                                xs: "16px",
                              },
                            },
                          }}
                          hiddenLabel={isMobile}
                        />
                      </Grid>
                    )
                  ) : null
                ) : null}
              </Grid>

              <FormItem
                variant="standard"
                sx={{
                  width: "100%",
                  "& .MuiFormLabel-root": {
                    color: "#282828 !important",
                  },
                }}
                as={MDInput}
                placeholder={`${
                  rowData?.key === "reasonsForSelling"
                    ? "Enter Reasons For Selling here"
                    : "enter note here"
                }`}
                name={`dealOverviewForm[${index}].notes`}
                label="Notes"
                InputLabelProps={{ shrink: true }}
              />
            </MDBox>
          );
        },
      },
    ],

    [form.values.dealOverviewForm]
  );
  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <Table
        px={0}
        columns={isMobile ? columns2Mobile : columns2}
        data={dealPlanOperation.dealAnalysisDealOverviewTable}
        sx={{
          "& .MuiTable-root": {
            "tr > td": {
              ...(isMobile && { paddingLeft: "2px" }),
              "& .MuiBox-root": {
                ...(isMobile && { paddingLeft: "0px" }),
              },
            },
            "th:first-child": {
              // borderBottom: "none",
              // display: "none",
            },
            "& .MuiTableBody-root": {
              "& :last-child > td": {
                borderBottom: "none",
                "& .MuiBox-root": {
                  "& .MuiBox-root": {
                    marginBottom: 0,
                  },
                },
              },
              "& :first-child > td": {
                "& .MuiBox-root": {
                  "& .MuiBox-root": {
                    // marginTop: 0,
                  },
                },
              },
            },
          },
        }}
      />
    </Form>
  );
});

const DealNotesForm = forwardRef((props, ref) => {
  const [dealPlanOperation] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: {
      dealNotes: dealPlanOperation.dealAnalysis.dealNotes,
    },
    onSubmit: props.submit,
  });
  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const navigate = useNavigate();
  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox pt={3}>
        <MDTypography fontSize="20px" fontWeight="regular">
          Other deal notes?
        </MDTypography>
      </MDBox>
      <MDBox xs={12} mt={1}>
        <FormItem
          as={MDInput}
          multiline
          type="text"
          name="dealNotes"
          variant="standard"
          placeholder="Enter Other deal notes"
          fullWidth
        />
      </MDBox>
      <Grid mt={4} item xs={12} textAlign="right" display="flex" justifyContent="end">
        <MDButton
          variant="outlined"
          onClick={() => navigate("/deal-planner", { replace: true })}
          color="green"
          sx={{
            mr: 1,
            fontSize: "14px",
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          loading={form.isSubmitting}
          disabled={form.isSubmitting || !dealPlanOperation.dealReview.businessName}
          onClick={form.submitForm}
          color="green"
          sx={{
            border: "1px solid #004225",
            fontSize: "14px",
          }}
        >
          Save and next
        </MDButton>
      </Grid>
    </Form>
  );
});

function DealAnalysis({ isMobile, setTextAutoSave }) {
  const [{ saveDealAnalysis, setCardOpen, baseInfo }] = useFlatInject("dealPlanOperation");
  const location = useLocation();
  const bizOverviewRef = useRef();
  const dealOverviewRef = useRef();
  const dealNotesRef = useRef();
  const submit = useCallback(
    async (isAuto = false) => {
      const bizOverviewFormData = bizOverviewRef.current?.getFormData?.();
      const dealOverviewFormData = dealOverviewRef.current?.getFormData?.();
      const dealNotesRefFormData = dealNotesRef.current?.getFormData?.();
      const notShowList = ["askPrice", "profit", "aksMultiple", "reasonsForSelling"];
      // if (
      //   bizOverviewFormData.businessOverview.find((businessItem) => !businessItem.yesOrNo) ||
      //   dealOverviewFormData.dealOverviewForm.find(
      //     (dealItem) => !notShowList.includes(dealItem.key) && !dealItem.yesOrNo
      //   )
      // ) {
      //   Message.error("Please select Yes/No");
      //   return;
      // }
      try {
        await saveDealAnalysis({
          businessOverviewForm: bizOverviewFormData.businessOverview,
          dealOverviewForm: dealOverviewFormData.dealOverviewForm,
          dealNotes: dealNotesRefFormData.dealNotes,
        });
        if (!isAuto) {
          window.location.href = `${location.pathname + location.search}#deal-bundle-work-sheet`;
          // setCardOpen("deal-bundle-work-sheet");
          Message.success("Changes saved");
        }
      } catch (error) {
        Message.error(error.message);
      }
    },
    [saveDealAnalysis]
  );
  useAutosave(async () => {
    if (baseInfo?.id) {
      submitAutoSave(() => submit(true), setTextAutoSave);
    }
  });

  return (
    <Card id="deal-analysis" sx={{ overflow: "visible", boxShadow: isMobile ? "none" : null }}>
      {!isMobile && (
        <MDBox p={3}>
          <MDTypography variant="h4">Deal Analysis</MDTypography>
        </MDBox>
      )}
      <MDBox component="form" pb={3} px={isMobile ? 0 : 3}>
        <BusinessOverviewForm isMobile={isMobile} ref={bizOverviewRef} />
        <MDBox py={isMobile ? 0 : 2}>{}</MDBox>
        <DealOverviewForm isMobile={isMobile} ref={dealOverviewRef} />
        <DealNotesForm submit={() => submit(false)} ref={dealNotesRef} />
      </MDBox>
    </Card>
  );
}

export default DealAnalysis;
