import dayjs from "dayjs";
import { createAsyncController } from "great-async";
import { getToken, setIsVerify, setToken, setUserId, setUserType } from "utils/auth";

import { http } from "../http";

const fetchUserInfo = createAsyncController(() => http.get("/user"), { single: true });
const resendLinkApi = createAsyncController(() => http.get("/resend/verifyEmail"), {
  single: true,
});

const initUser = () => ({
  id: "",
  email: "",
  name: "",
  paymentStatus: "",
  vip: -1,
  endAt: 0,
  lastPrintAt: 0,
  invoices: [],
  isDowngrading: false,
  stripeErrorCode: "",
});

/**
 * 此模块已经配置了持久化，配置位于config/config.ts中的natur.persist
 */
export default {
  state: {
    rememberName: false,
    authList: ["aaa"],
    token: getToken(),
    user: initUser(),
    paymentStatus: "",
    loading: {
      fetchingPaymentInfo: false,
    },
    openPaymentRes: {
      checkoutUrl: "",
    },
    cardPayment: {
      expMonth: "",
      expYear: "",
      lastFourNumber: "",
      brand: "",
    },
  },
  maps: {
    isLogin: ["token", "user.id", (token, userId) => !!token && !!userId],
    canPrint: (state) => {
      const { vip, lastPrintAt } = state.user;
      if (![0, 1].includes(vip)) {
        return false;
      }
      if (vip === 0) {
        if (dayjs().diff(dayjs(lastPrintAt), "month") < 1) {
          return true;
        }
        return false;
      }
      if (vip === 1) {
        return true;
      }
      return false;
    },
    hasAuth: [
      "authList",
      (authList) => (auth) => {
        if (auth === undefined) {
          return true;
        }
        return authList.includes(auth);
      },
    ],
    user: [
      "user",
      (user) => ({
        ...user,
        paymentStatus: user.endAt < Date.now() / 1000 && user.status === 1 ? "visitor" : "paid",
      }),
    ],
  },
  actions: {
    initUser: () => ({ user: initUser() }),
    fetchUserInfo: async () => {
      try {
        const res = await fetchUserInfo();
        setIsVerify(res?.data?.user?.isVerified);
        setUserType(res?.data?.user?.type);
        return {
          user: res?.data?.user ?? initUser(),
        };
      } catch (er) {
        console.log(er);
      }
    },
    resendLink: async () => {
      const res = await resendLinkApi();
      return res.data;
    },
    resetPassword: async ({ email }) => {
      const res = await http.post("/reset/password", {
        email,
      });
      return res;
    },
    verifyEmail:
      (email, token) =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        const res = await http.post("/email/verify", {
          email,
          token,
        });
        setToken(res.data.token);
        setUserId(res.data.user.id);
        setIsVerify(res.data.user?.isVerified);
        setUserType(res.data.user?.type);
        setState({
          user: res.data.user,
          token: res.data.token,
        });
        localDispatch("fetchPaymentInfo");
        localDispatch("fetchUserInfo");
        return res;
      },
    openPayment:
      (type, vip) =>
      async ({ setState }) => {
        // subscriptionMode: 'monthly' | 'yearly';
        const res = await http.post("/payment", {
          mode: type,
          vip,
        });
        return setState((ns) => {
          Object.assign(ns.openPaymentRes, {
            ...res.data,
            checkoutUrl: res.data.stripeSession.url,
          });
        });
      },
    fetchPaymentInfo:
      () =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        const { paymentStatus } = getMaps().user;
        if (paymentStatus === "paid") {
          return;
        }
        // setState((s) => {
        //   s.loading.fetchingPaymentInfo = true;
        // });
        try {
          const res = await http.get("/payment");
          setState((ns) => {
            Object.assign(ns.user, res.data);
          });
          return res;
        } finally {
          setState((s) => {
            s.loading.fetchingPaymentInfo = false;
          });
        }
      },
    upgradePayment:
      (callback) =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        try {
          const res = await http.post("/payment/upgrade");
          if (res.code !== 0) throw new Error("error");
          callback();
          await localDispatch("fetchUserInfo");
          await localDispatch("fetchPaymentInfo");
          await localDispatch("listInvoices");
        } catch (error) {
          console.log(error);
        }
      },
    downgradePayment:
      () =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        try {
          const res = await http.post("/payment/downgrade");
          await localDispatch("fetchUserInfo");
          await localDispatch("fetchPaymentInfo");
          await localDispatch("listInvoices");
        } catch (error) {
          console.log(error);
        }
      },
    getDowngradeInfor: () => async () => {
      try {
        const res = await http.get("/payment/downgrade");
        return res?.data;
      } catch (error) {
        console.log(error);
      }
    },
    getProrationUpgrade: () => async () => {
      try {
        const res = await http.get("/payment/proration");
        return res?.data;
      } catch (error) {
        console.log(error);
      }
    },

    cancelPayment:
      () =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        try {
          const res = await http.post("/payment/cancel");
          await localDispatch("fetchUserInfo");
          await localDispatch("fetchPaymentInfo");
          // get user
          const user = getState();
          console.log(user);
        } catch (error) {
          console.log(error);
        }
      },
    addPaymentMethod:
      (token) =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        try {
          const res = await http.post("/payment/card", { source: token });
          const data = res?.data;
          setState((s) => {
            s.cardPayment.expMonth = data.exp_month;
            s.cardPayment.expYear = data.exp_year;
            s.cardPayment.lastFourNumber = data.last4;
            s.cardPayment.brand = data.brand;
          });
          await localDispatch("fetchUserInfo");
          await localDispatch("fetchPaymentInfo");

          return res;
        } catch (error) {
          console.log(error);
        }
      },
    getPaymentMethod:
      () =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        try {
          const res = await http.get("/payment/card");
          const data = res?.data[0]?.card;

          setState((s) => {
            s.cardPayment.expMonth = data.exp_month;
            s.cardPayment.expYear = data.exp_year;
            s.cardPayment.lastFourNumber = data.last4;
            s.cardPayment.brand = data.brand;
          });
        } catch (error) {
          console.log(error);
        }
      },
    resumeSubscription:
      () =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        try {
          const res = await http.post("/payment/resume");
          await localDispatch("fetchUserInfo");
          await localDispatch("fetchPaymentInfo");
        } catch (error) {
          console.log(error);
        }
      },
    listInvoices:
      () =>
      async ({ getState, getMaps, setState, localDispatch }) => {
        try {
          const res = await http.post("/payment/invoices");
          // await localDispatch("fetchUserInfo");
          // await localDispatch("fetchPaymentInfo");
          setState((ns) => {
            ns.user.invoices = res.data;
          });
        } catch (error) {
          console.log(error);
        }
      },
    updateRemember: (nv) => ({ rememberName: nv }),
    login: async (loginData) => {
      const res = await http.post("/login", loginData);
      setToken(res.data.token);
      setUserId(res.data.user.id);
      setIsVerify(res.data.user?.isVerified);
      setUserType(res.data.user?.type);
      return {
        user: res.data.user,
        token: res.data.token,
      };
    },

    contactUs: async (contactData) => http.post("/contactUs", contactData),
    logout: async () => {
      await http.post("/logout");
      return { user: initUser() };
    },
    changePassword: async ({ password }) => {
      const res = await http.post("/resetpassword", {
        password,
      });
      setToken(res.data.token);
      setUserId(res.data.user.id);
      return {
        user: res.data.user,
        token: res.data.token,
      };
    },
    logPrint:
      () =>
      async ({ localDispatch }) => {
        await http.post("/logPrint");
        await localDispatch("fetchUserInfo");
      },
    signUp:
      ({ email, password, username }) =>
      async ({ setState, localDispatch }) => {
        const res = await http.post("/logon", { username, email, password });
        setToken(res.data.token);
        setUserId(res.data.user.id);
        setIsVerify(res.data.user?.isVerified);
        setUserType(res.data.user?.type);
        setState({
          user: res.data.user,
          token: res.data.token,
        });
        return localDispatch("fetchPaymentInfo");
      },
    signInSocial:
      (id) =>
      async ({ setState, localDispatch }) => {
        const res = await http.post("/loginSocial", {
          idToken: id,
        });
        setToken(res.data.token);
        setUserId(res.data.user.id);
        setIsVerify(res.data.user?.isVerified);
        setUserType(res.data.user?.type);
        setState({
          user: res.data.user,
          token: res.data.token,
        });
        return localDispatch("fetchPaymentInfo");
      },
    signComingSoon:
      ({ fullName, email }, callbackSuccess, callbackError) =>
      async () => {
        try {
          await http.post("/users/create", {
            full_name: fullName,
            email,
          });
          callbackSuccess();
        } catch (err) {
          callbackError();
        }
      },
  },
  watch: {
    user: async (watchEvent, api) => {
      if (watchEvent.type === "init") {
        await api.localDispatch("fetchUserInfo");
        api.localDispatch("fetchPaymentInfo");
      }
    },
  },
};
