import { Grid } from "@mui/material";
import bgLandingImage from "assets/images/illustrations/new-bg-landing-page.png";
import brandingLogo from "assets/images/small-logos/branding-logo.svg";
import PlayIcon from "assets/images/small-logos/play.svg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useFlatInject } from "store";
import { useMaterialUIController } from "utils/hooks";
import Footer from "examples/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactPlayer from "react-player/lazy";
import React, { useState } from "react";
import video from "assets/videos/landing-video.mov";
import Pricing from "./Pricing";
import TransitionsModalVideo from "./Modal";

function LandingPage() {
  const isMobile = useMediaQuery("(max-width:480px)");
  const [isPlay, setIsPlay] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = React.useState(false);
  const [{ openPayment }] = useFlatInject("user");
  const navigate = useNavigate();
  const payment = async (type) => {
    const state = await openPayment("monthly", type);
    if (state.openPaymentRes.checkoutUrl) {
      window.location.href = state.openPaymentRes.checkoutUrl;
    }
  };
  const redirectSignup = () => {
    navigate("/sign-up");
  };
  const redirectSignin = () => {
    navigate("/sign-in");
  };
  return (
    <PageLayout>
      <Grid
        container
        sx={{
          backgroundColor: "#F6F6F6",
        }}
      >
        <Grid item xs={12}>
          {isPlay && !isMobile ? (
            <MDBox
              component={ReactPlayer}
              url={video}
              sx={{
                width: "calc(100% - 40px) !important",
                height: "calc(80vh - 40px) !important",
                iframe: {
                  borderRadius: "20px",
                },
              }}
              mx={20 / 8}
              mt={20 / 8}
              mr={5}
              playing
              borderRadius={isMobile ? "0px" : "lg !important"}
              controls
            />
          ) : (
            <MDBox
              display="flex"
              height={isMobile ? "100vh" : "calc(80vh - 40px) !important"}
              borderRadius={isMobile ? "0px" : "lg"}
              mx={isMobile ? 0 : 20 / 8}
              mt={isMobile ? 0 : 20 / 8}
              sx={{
                backgroundImage: `url(${bgLandingImage})`,
                backgroundSize: "cover",
                backgroundPosition: !isMobile ? "top" : "center",
                backgroundRepeat: "no-repeat",
                position: "relative",
              }}
            >
              <MDBox
                component="img"
                src={PlayIcon}
                onClick={() => (isMobile ? setOpen(true) : setIsPlay(true))}
                height={{ xs: "117px", md: "92px" }}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
              {isMobile ? (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                    padding: isMobile ? "80px 24px" : "48px 48px 75px 48px",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <MDBox
                      component="img"
                      src={brandingLogo}
                      height={isMobile ? 32 : 50}
                      sx={{ ...(isMobile ? { width: "120px" } : {}) }}
                    />
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" sx={{ gap: "32px" }}>
                    <MDTypography
                      fontWeight="medium"
                      fontSize={{ xs: isMobile ? 40 : 18, md: 48, xxl: 76 }}
                      color="white"
                      sx={{ maxWidth: isMobile ? "auto" : "50vw" }}
                      lineHeight="48px"
                    >
                      Create and store your contracts in the one place
                    </MDTypography>
                    <MDBox
                      sx={{ display: "flex", gap: "8px", height: "fit-content", width: "100%" }}
                    >
                      <MDButton
                        onClick={redirectSignup}
                        variant="contained"
                        color="green"
                        size="large"
                        sx={{ padding: "5px 20px", flexGrow: 1 }}
                      >
                        Sign up
                      </MDButton>
                      <MDButton
                        onClick={redirectSignin}
                        variant="outlined"
                        color="green"
                        size="large"
                        sx={{
                          padding: "5px 20px",
                          background: "#ffffff !important",
                          border: "1px solid #004225 !important",
                          flexGrow: 1,
                        }}
                      >
                        Login
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ) : (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: isMobile ? "80px 24px" : "48px 48px 75px 48px",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flexWrap: "wrap",
                      rowGap: "12px",
                    }}
                  >
                    <MDBox component="img" src={brandingLogo} height={isMobile ? 35 : 50} />
                    <MDBox sx={{ display: "flex", gap: "12px", height: "fit-content" }}>
                      <MDButton
                        onClick={redirectSignup}
                        variant="contained"
                        color="green"
                        size="large"
                        sx={{ padding: "5px 20px" }}
                      >
                        Sign up
                      </MDButton>
                      <MDButton
                        onClick={redirectSignin}
                        variant="outlined"
                        color="green"
                        size="large"
                        sx={{
                          padding: "5px 20px",
                          background: "#ffffff !important",
                          border: "1px solid #004225 !important",
                        }}
                      >
                        Login
                      </MDButton>
                    </MDBox>
                  </MDBox>

                  <MDTypography
                    fontWeight="medium"
                    fontSize={{ xs: isMobile ? 40 : 18, md: 48, xxl: 76 }}
                    color="white"
                    sx={{ maxWidth: isMobile ? "auto" : "50vw" }}
                  >
                    Create and store your contracts in the one place
                  </MDTypography>
                </MDBox>
              )}
              {/* <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                padding: isMobile ? "80px 24px" : "48px 48px 75px 48px",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                  rowGap: "12px",
                }}
              >
                <MDBox component="img" src={brandingLogo} height={isMobile ? 35 : 50} />
                <MDBox sx={{ display: "flex", gap: "12px", height: "fit-content" }}>
                  <MDButton
                    onClick={redirectSignup}
                    variant="contained"
                    color="green"
                    size="large"
                    sx={{ padding: "5px 20px" }}
                  >
                    Sign up
                  </MDButton>
                  <MDButton
                    onClick={redirectSignin}
                    variant="outlined"
                    color="green"
                    size="large"
                    sx={{
                      padding: "5px 20px",
                      background: "#ffffff !important",
                      border: "1px solid #004225 !important",
                    }}
                  >
                    Login
                  </MDButton>
                </MDBox>
              </MDBox>

              <MDTypography
                fontWeight="medium"
                fontSize={{ xs: isMobile ? 40 : 18, md: 48, lg: 60, xxl: 72 }}
                color="white"
                sx={{ maxWidth: isMobile ? "auto" : "50vw" }}
              >
                Create and store your contracts in the one place
              </MDTypography>
            </MDBox> */}
            </MDBox>
          )}
        </Grid>
        <Pricing isMobile={isMobile} />
      </Grid>
      <Footer />
      {isMobile && (
        <TransitionsModalVideo open={open} setOpen={setOpen}>
          <MDBox
            component={ReactPlayer}
            url={video}
            sx={{
              width: "100% !important",
              height: "100% !important",
              iframe: {
                borderRadius: "10px",
              },
            }}
            playing
            controls
          />
        </TransitionsModalVideo>
      )}
    </PageLayout>
  );
}

export default LandingPage;
