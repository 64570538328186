/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Form, { useForm, FormItem } from "components/Form";
import { Message } from "utils/message";
import * as Yup from "yup";
import user from "store/user";
import { useFlatInject } from "store";
import MDInputSignIn from "components/MDInputSignIn";

function ChangePassword({ isMobile }) {
  const passwordRequirements = [
    "One special character(example: !@#$%^&*()_-+={}[])",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography
          variant="button"
          sx={{ color: "#757575" }}
          fontSize={12}
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const [{ changePassword, listInvoices }] = useFlatInject("user");
  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (formData) => {
      // await new Promise((res) => setTimeout(res, 3000));
      await changePassword(formData);
      Message.success("Submit Success!");
      await listInvoices();
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, "Password min length is 8")
        .max(20, "Password max length is 20")
        .matches(
          /[!@#$%^&*()_\-+={}[\]]/,
          "Password must contains one special character(example: !@#$%^&*()_-+={}[])"
        )
        .required("Password is required"),
      confirmPassword: Yup.string()
        .label("confirm password")
        .required("Confirm password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
  });

  return (
    <Card id="change-password" sx={{ boxShadow: "none" }}>
      <MDBox p={3} sx={{ display: "flex", justifyContent: "space-between" }}>
        {!isMobile ? (
          <MDTypography fontWeight="normal" fontSize={24} sx={{ color: "#282828" }}>
            Change password
          </MDTypography>
        ) : null}

        <MDBox m="auto 0">
          <MDButton
            color="green"
            sx={{
              padding: "5px 20px",
              fontSize: "14px",
              height: "fit-content",
              border: "1px solid transparent",
            }}
            onClick={form.submitForm}
          >
            Update password
          </MDButton>
        </MDBox>
      </MDBox>
      <Form form={form} fieldStyle={{ flex: 1 }} labelSx={{ display: "none" }}>
        <MDBox component="div" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FormItem
                as={MDInputSignIn}
                type="password"
                name="password"
                fullWidth
                placeholder=" New password"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormItem
                as={MDInputSignIn}
                type="password"
                name="confirmPassword"
                fullWidth
                placeholder=" Confirm new password"
              />
            </Grid>
          </Grid>
          <MDBox mt={4} mb={1}>
            <MDTypography fontWeight="normal" fontSize={18} sx={{ color: "#282828" }}>
              Password requirements
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography fontWeight="regular" fontSize={14} sx={{ color: "#757575" }}>
              Please follow this guide for a strong password
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {renderPasswordRequirements}
            </MDBox>
          </MDBox>
        </MDBox>
      </Form>
    </Card>
  );
}

export default ChangePassword;
